import { FC, useCallback } from 'react';
import { makeShouldForwardProps } from 'src/components/utils';
import { useNavigate } from 'react-router-dom';
import { patientRoutes } from 'src/router/routes';
import { Avatar, AvatarProps } from './Avatar';
import { styled } from '@mui/system';

interface PatientAvatarProps extends AvatarProps {
  patientId: string;
}

const shouldForwardPropAvatar = makeShouldForwardProps(['patientId']);
const StyledAvatar = styled(Avatar, {
  shouldForwardProp: shouldForwardPropAvatar
})<AvatarProps>`
  cursor: pointer;
`;

const PatientAvatar: FC<PatientAvatarProps> = ({
  patientId,
  ...otherProps
}) => {
  const navigate = useNavigate();

  const navigateToUserProfile = useCallback(() => {
    navigate(patientRoutes.getByIdLink(patientId));
  }, [navigate, patientId]);

  return <StyledAvatar onClick={navigateToUserProfile} {...otherProps} />;
};

export default PatientAvatar;
