import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { GridCellSelectionModel, GridColDef } from '@mui/x-data-grid-premium';
import dayjs from 'dayjs';

import Flex from 'src/components/layout/Flex';
import { pageSizes, spacings } from 'src/components/styles/constants';
import Typography from 'src/components/display/Typography';
import { getFullName } from 'src/utils/general';
import { useGetDefaultAvatar } from 'src/utils/defaultImages';
import { UserTypes } from 'src/types/user';
import { PatientPersonalInfo } from 'src/types/patient';
import { EggSource, Gamete } from 'src/types/cycle';
import {
  EggAndEmbryoNotes,
  EmbryoGender,
  Fate,
  InseminationMethods
} from 'src/types/eggAndEmbryo';
import { patientRoutes } from 'src/router/routes';
import Avatar from 'src/components/display/Avatar';
import { AvatarSizes } from 'src/components/display/Avatar/Avatar';
import Table from 'src/components/display/Table';
import Box from 'src/components/layout/Box';
import { EggAndEmbryoServer } from 'src/types/patient-server';
import useEggsEmbryos from 'src/hooks/useEggsEmbryos';
import {
  dashSeparatedDateFormat,
  formatDateAndTime
} from 'src/utils/dateAndTIme';
import NotesWrapper from '../treatment/NotesWrapper';

export interface EmbryologyRecord
  extends Omit<
    EggAndEmbryoServer,
    | 'day1'
    | 'day2'
    | 'day3'
    | 'day4'
    | 'cycleId'
    | 'retrieved'
    | 'mature'
    | 'inseminated'
    | 'isViable'
    | 'notViableSince'
    | 'createdAt'
    | 'updatedAt'
    | 'cultureDishId'
    | 'groupCulture'
    | 'cultureDropWell'
    | 'pgtTissueType'
    | 'position'
    | 'importedDate'
  > {
  no: number;
  displayId: string;
  inseminationMethod: InseminationMethods;
  gender: EmbryoGender;
  shippedDate?: Date;
  locationCanister?: string;
  patient?: Partial<PatientPersonalInfo>;
  authId: string;
  pictureId?: string;
  creationCycleId: string;
  creationCycleDisplayId: string;
  currentCycleId: string;
  gameteSource?: EggSource;
  gameteType: Gamete;
  tank?: string;
  beacon?: string;
  position?: string;
  freezeDate?: Date;
  notes?: EggAndEmbryoNotes[];
}

const StyledFlexPointer = styled(Flex)`
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  gap: ${spacings.large};
  padding: ${spacings.medium} 0;
`;

const fateOptions = Object.values(Fate);

export const EmbryologyTable: FC<{ patientId: string }> = ({ patientId }) => {
  const [editingCells, setEditingCells] =
    useState<GridCellSelectionModel>(null);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getPatientEggsAndEmbryos, upsertNotes, updateEgg } = useEggsEmbryos();
  const { getDefaultAvatar } = useGetDefaultAvatar();

  const {
    data,
    isFetching: isFetching,
    isLoading: isLoading
  } = getPatientEggsAndEmbryos({
    patientId
  });
  const { mutateAsync: handleUpsertNotes } = upsertNotes();
  const { mutateAsync: handleUpdateEgg } = updateEgg();

  const rows = data?.map((row, index) => ({
    ...row,
    no: index + 1
  }));

  const columns: GridColDef<EmbryologyRecord>[] = [
    {
      field: 'no',
      headerName: t('INDEX_NUMBER'),
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'patient',
      headerName: t('PATIENT').toUpperCase(),
      align: 'left',
      filterable: false,
      sortComparator: (v1, v2) => v1.localeCompare(v2),
      valueGetter: (value) => getFullName(value),
      renderCell: ({
        row: { patient: { pictureId, id: patientId, ...rest } = {} } = {}
      }) => {
        const avatar = getDefaultAvatar({
          userId: patientId,
          userType: UserTypes.patients,
          pictureId: pictureId
        });

        return (
          <StyledFlexPointer
            onClick={() => navigate(patientRoutes.getByIdLink(patientId))}
          >
            <Avatar image={avatar} size={AvatarSizes.SMALL} />
            <Typography>{getFullName(rest)}</Typography>
          </StyledFlexPointer>
        );
      }
    },
    {
      field: 'creationCycleId',
      headerName: t('CYCLE_ID'),
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => {
        return (
          <StyledFlexPointer
            onClick={() =>
              navigate(
                patientRoutes.getCurrentTreatmentByCycleLink(
                  patientId,
                  row.creationCycleId
                )
              )
            }
          >
            {row.creationCycleDisplayId.toUpperCase()}
          </StyledFlexPointer>
        );
      }
    },
    {
      field: 'displayId',
      headerName: t('ID'),
      align: 'center',
      headerAlign: 'center',
      valueGetter: (value: string) => value,
      renderCell: ({ row }) => {
        return <>{row.displayId.toUpperCase()}</>;
      }
    },
    {
      field: 'gameteType',
      headerName: t('GAMETE_TYPE'),
      align: 'center',
      headerAlign: 'center',
      sortable: false
    },
    {
      field: 'inseminationMethod',
      headerName: t('INSEMINATION_METHOD'),
      align: 'center',
      headerAlign: 'center',
      sortable: false
    },
    {
      field: 'day5',
      headerName: t('DAY5'),
      align: 'center'
    },
    {
      field: 'day6',
      headerName: t('DAY6'),
      align: 'center'
    },
    {
      field: 'day7',
      headerName: t('DAY7'),
      align: 'center'
    },
    {
      field: 'fate',
      headerName: t('FATE'),
      editable: true,
      type: 'singleSelect',
      valueOptions: fateOptions
    },
    {
      field: 'freezeDate',
      headerName: t('FREEZE_DATE'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      editable: true,
      type: 'dateTime',
      valueGetter: (value) =>
        value ? new Date(formatDateAndTime(value)) : null
    },
    {
      field: 'tank',
      headerName: t('TANK'),
      align: 'center',
      headerAlign: 'center',
      sortable: false
    },
    {
      field: 'locationCanister',
      headerName: t('LOCATION_CANISTER'),
      align: 'center',
      headerAlign: 'center',
      sortable: false
    },
    {
      field: 'beacon',
      headerName: t('BEACON'),
      align: 'center',
      headerAlign: 'center',
      sortable: false
    },
    {
      field: 'position',
      headerName: t('POSITION'),
      align: 'center',
      headerAlign: 'center',
      sortable: false
    },
    {
      field: 'straw',
      headerName: t('STRAW'),
      align: 'center',
      headerAlign: 'center',
      sortable: false
    },
    {
      field: 'geneticStatus',
      headerName: t('GENETIC_STATUS'),
      align: 'center',
      headerAlign: 'center',
      sortable: false
    },
    {
      field: 'notes',
      headerName: t('NOTES'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Flex justifyContent="center" alignItems="center">
            <NotesWrapper
              date={dayjs().format(dashSeparatedDateFormat)}
              initialNotes={row.notes}
              onUpsertNotes={(note) =>
                handleUpsertNotes({
                  ...note,
                  embryoId: row.id
                })
              }
              cycleId={row.creationCycleId}
              patientId={row.patientId}
            />
          </Flex>
        );
      }
    },
    {
      field: 'gender',
      headerName: t('GENDER'),
      align: 'center',
      headerAlign: 'center',
      sortable: false
    },
    {
      field: 'shippedDate',
      headerName: t('SHIPPED_DATE'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      editable: true,
      type: 'dateTime',
      valueGetter: (value) =>
        value ? new Date(formatDateAndTime(value)) : null
    },
    {
      field: 'gameteSource',
      headerName: t('GAMETE_SOURCE'),
      align: 'center',
      headerAlign: 'center',
      sortable: false
    }
  ];

  const handleEditCell = async (
    cellName: string,
    newValue: string,
    eggId: string,
    cycleId: string
  ): Promise<void> => {
    await handleUpdateEgg({
      patientId,
      cycleId,
      eggId,
      egg: { [cellName]: newValue }
    });
  };

  return (
    <Box>
      <Table
        onCellSelectionModelChange={(newSelection) => {
          setEditingCells(newSelection);
        }}
        processRowUpdate={async (newRow, oldRow) => {
          const editingCellType = Object.keys(editingCells[newRow.id] || {})[0];

          if (newRow[editingCellType] === oldRow[editingCellType]) {
            return newRow;
          }

          await handleEditCell(
            editingCellType,
            newRow[editingCellType],
            newRow.id,
            newRow.currentCycleId
          );

          return newRow;
        }}
        editMode="cell"
        columns={columns}
        rows={rows || []}
        loading={isLoading || isFetching}
        disableColumnMenu
        paginationModel={{ page: 0, pageSize: pageSizes.MEDIUM }}
        cellSelection
        ignoreValueFormatterDuringExport
      />
    </Box>
  );
};
