import dayjs from 'dayjs';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Customized,
  Dot,
  DotProps,
  LabelList,
  Line,
  Rectangle,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from 'recharts';
import Typography from 'src/components/display/Typography/Typography';
import Box from 'src/components/layout/Box/Box';
import { Colors } from 'src/components/styles/colors';
import { fontWeights } from 'src/components/styles/fonts';
import { Cycle, CycleData } from 'src/types/cycle';
import { GraphPhasesTimeline } from './GraphPhasesTimeline';
import Center from 'src/components/layout/Center/Center';
import { getDateFormat } from 'src/utils/dateAndTIme';
import { useParams } from 'react-router-dom';
import Loader from 'src/components/display/Loader';
import { iconSizes, spacings } from 'src/components/styles/constants';
import usePatientsApi from 'src/hooks/usePatientsApi';
import { GraphSideMap } from './GraphSideMap';
import { EmbryosBarGraphMap } from './EmbryosBarGraphMap';
import {
  NameType,
  ValueType
} from 'recharts/types/component/DefaultTooltipContent';
import Flex from '../../../components/layout/Flex';
import { styled } from '@mui/system';
import Card from '../../../components/display/Card';

// 8px from the axis to the tick label
const AXIS_PADDING = 8;

interface CustomTickProps {
  x: number;
  y: number;
  columnWidth: number;
  cycleDayOneOffset: number;
  index: number;
  payload: {
    value: string;
  };
}

const CustomTick = (props: CustomTickProps) => {
  const {
    x,
    y,
    payload: { value } = {},
    columnWidth,
    index,
    cycleDayOneOffset
  } = props;

  let currentIndex = cycleDayOneOffset + index;
  currentIndex = currentIndex >= 0 ? currentIndex + 1 : currentIndex;

  return (
    <g transform={`translate(${x},${y + AXIS_PADDING})`} fill="#000">
      <text
        y={0}
        dy={16}
        fill={Colors.emperor}
        fontSize="12px"
        fontWeight={fontWeights.extraBold}
      >
        <tspan fontSize={20} textAnchor="middle" x={(columnWidth || 0) / 2}>
          {`${currentIndex}`.padStart(2, '0')}
        </tspan>
        <tspan textAnchor="middle" x={(columnWidth || 0) / 2} dy="15">
          {dayjs(value).format('ddd').toUpperCase()}
        </tspan>
        <tspan textAnchor="middle" x={(columnWidth || 0) / 2} dy="18">
          {dayjs(value).format(getDateFormat({ isShort: true }))}
        </tspan>
      </text>
    </g>
  );
};

interface CustomLabelProps {
  value?: {
    amount: number;
    percentage: number;
  };
  x: number;
  y: number;
  barWidth: number;
}

const CustomLabel = (props: CustomLabelProps) => {
  const { barWidth, x, y, value } = props;
  if (!value) return <></>;

  const labelContainerHeight = 60;
  const containerWidth = barWidth * 0.8;
  const containerX = x + (barWidth - containerWidth) / 2;
  const containerY = y + (barWidth - containerWidth) / 2;
  return (
    <g>
      <Rectangle
        height={labelContainerHeight}
        width={containerWidth}
        fill={Colors.white}
        style={{ margin: 'auto', display: 'block' }}
        x={containerX}
        y={containerY}
        radius={[50, 50, 50, 50]}
      />
      <text
        x={x + barWidth / 2}
        textAnchor="middle"
        y={containerY + 25}
        fill="#000"
        fontSize="20px"
      >
        {value.amount}
      </text>
      <text
        x={x + barWidth / 2}
        textAnchor="middle"
        y={containerY + labelContainerHeight - 18}
        fill="#000"
        fontSize="10px"
        fontWeight={600}
      >
        {value.percentage}%
      </text>
    </g>
  );
};

const getBarFill = (dayOneIndex: number, barIndex: number): string => {
  const diff = barIndex - dayOneIndex;

  if (diff < 4) {
    return Colors.viola;
  } else if (diff < 7) {
    return Colors.poloBlue;
  } else {
    return Colors.mustard;
  }
};

interface CustomBarProps {
  x: number;
  y: number;
  width: number;
  height: number;
  payload: CycleData;
  index: number;
  dayOneIndex: number;
  paddingBottom: number;
}

const CustomBar = (props: CustomBarProps) => {
  const { x, width, y, payload, dayOneIndex, index, height, paddingBottom } =
    props;

  const shouldDisplayBar = !!height;
  const yPosition = y - paddingBottom;
  return (
    <g>
      <Rectangle
        {...props}
        y={yPosition}
        height={shouldDisplayBar ? height + paddingBottom : 0}
        fill={getBarFill(dayOneIndex, index)}
        radius={[50, 50, 0, 0]}
      />
      <CustomLabel
        value={
          shouldDisplayBar
            ? {
                amount: payload.embryosCount,
                percentage: +payload.embryosPercentage?.toFixed()
              }
            : null
        }
        x={x}
        y={shouldDisplayBar ? yPosition : null}
        barWidth={width}
      />
    </g>
  );
};

interface CycleGraphProps {
  chartContainer: HTMLElement;
  chartLeftMargin: number;
  yAxisWidth: number;
  columnWidth: number;
  cycle: Cycle | null;
}

const yAxisTickCount = 30;
const scatterRadius = 5;
const scatterDotPadding = 1;
const xAxisHeight = 70;
const barPaddingBottom = 50;
export const CycleGraph: FC<CycleGraphProps> = ({
  chartContainer,
  chartLeftMargin,
  yAxisWidth,
  columnWidth,
  cycle
}) => {
  const { t } = useTranslation();
  const { getPatientTreatmentsData } = usePatientsApi();
  const { cycleId, patientId } = useParams();

  const { data: treatmentsData, isLoading: isLoadingPatientTreatmentData } =
    getPatientTreatmentsData(cycleId, patientId, {
      enabled: !!cycleId
    });

  const { data, cyclePhases } = treatmentsData || {};

  const chartWidth =
    columnWidth * (data?.length || 0) + yAxisWidth + chartLeftMargin;
  const scrollbarHeight =
    chartContainer?.offsetHeight - chartContainer?.clientHeight;
  const chartHeightWithoutScrollbar =
    chartContainer?.getBoundingClientRect().height - scrollbarHeight;

  const getCellYPosition = (cellNumber: number) => {
    let cellStartPosition: number = chartHeightWithoutScrollbar - xAxisHeight;
    if (cellNumber === 0) return cellStartPosition - scatterRadius * 2; // align the position 0 scatters right above the x-axis start

    const cellPosition = (cellStartPosition / yAxisTickCount) * cellNumber;
    if (cellNumber === 26) cellStartPosition += scatterRadius; // align the last position scatter right below the x-axis end

    return cellStartPosition - cellPosition;
  };

  interface RenderDotProps extends DotProps {
    folliclesList: number[];
    folliclesSide: 'right' | 'left';
  }

  const StyledFlex = styled(Flex)`
    &:first-of-type {
      padding-top: ${spacings.large};
    }
    &:last-of-type {
      padding-bottom: ${spacings.large};
    }
  `;

  const dataKeysToDisplay = [
    'Estradiol',
    'Progesterone',
    'LH',
    'FSH',
    'BetaHCG'
  ];
  const CustomTooltip = ({
    active,
    payload
  }: TooltipProps<ValueType, NameType>) => {
    if (!active || !payload?.length) {
      return null;
    }

    const tooltipData = useMemo(() => {
      return (
        dataKeysToDisplay.map((key) => {
          const itemToDisplay = payload?.find(({ dataKey }) => {
            if (typeof dataKey !== 'string') {
              return false;
            }

            return dataKey.includes(key);
          });

          if (!itemToDisplay) return null;

          return {
            ...itemToDisplay,
            key
          };
        }) || []
      );
    }, [payload]);

    return (
      <Card shadow borderRadius={0}>
        {tooltipData?.map((item) => {
          if (!item) return null;
          const { dataKey, stroke, payload: innerPayload, key } = item || {};

          return (
            <StyledFlex
              key={dataKey}
              marginBottom={spacings.medium}
              gap={spacings.medium}
              paddingX={spacings.large}
            >
              <Typography
                color={stroke}
                fontWeight={fontWeights.extraBold}
                variant="h4"
              >
                {key}:
              </Typography>
              <Typography
                color={stroke}
                fontWeight={fontWeights.extraBold}
                variant="h4"
              >
                {innerPayload[key].value}
              </Typography>
            </StyledFlex>
          );
        })}
      </Card>
    );
  };

  const RenderDot: FC<RenderDotProps> = ({
    cx,
    cy,
    r,
    fill,
    folliclesList,
    tabIndex: index,
    folliclesSide
  }) => {
    const dotWithLabelScale = 1.5;
    let label = '';
    const currFollicleSize = folliclesList[index];
    const folliclesBeforeCurr = folliclesList.slice(0, index);
    const sameBeforeFollicleSizeCount = folliclesBeforeCurr.filter(
      (prevFollicle) => prevFollicle === currFollicleSize
    ).length;

    if (sameBeforeFollicleSizeCount > 1) {
      return null;
    } else if (sameBeforeFollicleSizeCount === 1) {
      const folliclesAfterCurr = folliclesList.slice(index + 1);

      const sameAfterFollicleSizeCount = folliclesAfterCurr.filter(
        (prevFollicle) => prevFollicle === currFollicleSize
      ).length;

      if (sameAfterFollicleSizeCount > 0) {
        label = `${
          sameAfterFollicleSizeCount + sameBeforeFollicleSizeCount + 1
        }`;
      }
    }

    const follicleOffset =
      scatterRadius *
        2 *
        sameBeforeFollicleSizeCount *
        (label ? dotWithLabelScale : 1) +
      scatterDotPadding;

    const currFollicleRadius = label ? r * dotWithLabelScale : r;

    return (
      <g>
        <Dot
          cx={
            cx + (folliclesSide === 'left' ? -follicleOffset : follicleOffset)
          }
          cy={cy}
          fill={fill}
          r={currFollicleRadius}
        />
        {label && (
          <text
            x={
              cx + (folliclesSide === 'left' ? -follicleOffset : follicleOffset)
            }
            y={cy + currFollicleRadius / 2}
            textAnchor="middle"
            fill={Colors.white}
            fontSize="11px"
            fontWeight={800}
          >
            {label}
          </text>
        )}
      </g>
    );
  };

  if (!data?.length) {
    return (
      <Center height="100%">
        {isLoadingPatientTreatmentData ? (
          <Loader size={iconSizes.xlarge} />
        ) : (
          <Typography fontWeight={fontWeights.extraBold} variant="h2">
            {t('NO_DATA')}
          </Typography>
        )}
      </Center>
    );
  }

  const cycleDayOneOffset = data.length? dayjs(data[0].date
  ).diff(
    dayjs(cycle.cycleDayOne).startOf('day'),
    'day'
  ): 0;

  const dayOneIndex = data.findIndex(
    ({ embryosCount }) => !isNaN(embryosCount)
  );

  return (
    <Box minWidth={chartWidth} height="100%">
      <ResponsiveContainer width="100%">
        <ComposedChart
          data={data}
          margin={{
            top: 5,
            left: chartLeftMargin
          }}
          barGap={0}
          barCategoryGap={0}
        >
          <Customized component={<GraphSideMap />} />
          {dayOneIndex !== -1 && (
            <Customized
              component={
                <EmbryosBarGraphMap
                  chartLeftMargin={chartLeftMargin}
                  columnWidth={columnWidth}
                  dayOneIndex={dayOneIndex}
                  yAxisWidth={yAxisWidth}
                />
              }
            />
          )}

          <rect
            fill={Colors.dawnPink}
            width="110%"
            height={xAxisHeight}
            x={0}
            y={
              chartContainer?.getBoundingClientRect().height -
              xAxisHeight -
              scrollbarHeight
            }
            rx={40}
          />
          <CartesianGrid syncWithTicks />
          <XAxis
            dataKey="date"
            scale="band"
            height={xAxisHeight}
            allowDataOverflow
            interval={0}
            type="category"
            tick={(props: CustomTickProps) => (
              <CustomTick
                {...props}
                columnWidth={columnWidth}
                cycleDayOneOffset={cycleDayOneOffset}
              />
            )}
            alignmentBaseline="central"
            axisLine={false}
            tickLine={false}
            tickSize={0}
          />
          <YAxis
            domain={[0, yAxisTickCount]}
            tickCount={yAxisTickCount}
            interval={0}
            allowDecimals={false}
            width={yAxisWidth}
            axisLine={false}
            tickLine={false}
            tickSize={0}
            tick={(props) => {
              const { x, y, payload } = props;
              if (payload.value === 0) return null;
              return (
                <g transform={`translate(${x - AXIS_PADDING},${y})`}>
                  <text
                    dy={9}
                    dx={-10}
                    fill={Colors.emperor}
                    fontSize="18px"
                    fontWeight={fontWeights.extraBold}
                  >
                    <tspan textAnchor="middle">
                      {payload.value <= 26 ? payload.value : ''}
                    </tspan>
                  </text>
                </g>
              );
            }}
          />
          <ReferenceLine y={18} stroke={Colors.mineShaft} />
          <Line
            style={{ strokeWidth: 3 }}
            type="monotone"
            dataKey="Progesterone.formattedValue"
            stroke={Colors.poloBlue}
            connectNulls
          />
          <Line
            style={{ strokeWidth: 3 }}
            type="monotone"
            dataKey="LH.formattedValue"
            stroke={Colors.mustard}
            connectNulls
          />
          <Line
            style={{ strokeWidth: 3 }}
            type="monotone"
            dataKey="Estradiol.formattedValue"
            stroke={Colors.cupid}
            connectNulls
          />
          <Line
            style={{ strokeWidth: 3 }}
            type="monotone"
            dataKey="FSH.formattedValue"
            stroke={Colors.gray}
            connectNulls
          />
          <Line
            style={{ strokeWidth: 3 }}
            type="monotone"
            dataKey="BetaHCG.formattedValue"
            stroke={Colors.riptide}
            connectNulls
          />
          <Tooltip
            wrapperStyle={{ outline: 'none' }}
            content={<CustomTooltip />}
          />
          <Bar
            dataKey="embryosCount"
            barSize={columnWidth}
            shape={(props: CustomBarProps) => (
              <CustomBar
                {...props}
                dayOneIndex={dayOneIndex}
                paddingBottom={barPaddingBottom}
              />
            )}
          >
            <LabelList
              dataKey="embryosPhase"
              position="top"
              fill="black"
              dy={-barPaddingBottom}
            />
          </Bar>
          {data?.map(({ folliclesSizeLeft }, index) => {
            if (!folliclesSizeLeft?.length) return null;
            return folliclesSizeLeft?.map((y, follicleIndex) => {
              return (
                <Scatter
                  key={`scatter-chart-left-${index}-follicle-${follicleIndex}`}
                  shape={
                    <RenderDot
                      folliclesSide="left"
                      folliclesList={folliclesSizeLeft}
                      r={scatterRadius}
                    />
                  }
                >
                  <Cell
                    cx={
                      index * columnWidth +
                      chartLeftMargin +
                      yAxisWidth +
                      columnWidth / 2 -
                      scatterRadius +
                      scatterDotPadding * 2
                    }
                    cy={getCellYPosition(y)}
                    fill={Colors.viola}
                    key={`cell-left-${follicleIndex}-chart-${index}`}
                    tabIndex={follicleIndex}
                  />
                </Scatter>
              );
            });
          })}

          {data?.map(({ folliclesSizeRight }, index) => {
            if (!folliclesSizeRight?.length) return null;
            return folliclesSizeRight?.map((y, follicleIndex) => {
              return (
                <Scatter
                  key={`scatter-chart-right-${index}-follicle-${follicleIndex}`}
                  shape={
                    <RenderDot
                      folliclesList={folliclesSizeRight}
                      folliclesSide="right"
                      r={scatterRadius}
                    />
                  }
                >
                  <Cell
                    cx={
                      index * columnWidth +
                      chartLeftMargin +
                      yAxisWidth +
                      columnWidth / 2 +
                      scatterRadius +
                      scatterDotPadding * 2
                    }
                    cy={getCellYPosition(y)}
                    key={`cell-right-${follicleIndex}-chart-${index}`}
                    fill={Colors.poloBlue}
                    tabIndex={follicleIndex}
                  />
                </Scatter>
              );
            });
          })}
          <Customized
            component={
              <GraphPhasesTimeline
                chartDayColumnWidth={columnWidth}
                chartStartingXPosition={chartLeftMargin}
                yAxisWidth={yAxisWidth}
                phases={cyclePhases}
                maxDayIndex={data.length - 1}
              />
            }
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};
