import { FC } from 'react';
import { useRoutes } from 'react-router-dom';

import router from 'src/router';
import useScrollTop from 'src/hooks/useScrollTop';
import './utils/registerCountries';
import { useAuth } from './contexts/AppContexts/AppContexts';
import {
  usePopover,
  useDrawer,
  useDialog,
  useToast,
  useLoadingOverlay
} from './contexts/UIContexts';

import AppInit from './components/layout/AppInit';
import Popover from './components/display/Popover';
import Dialog from './components/display/Dialog';
import Toast from './components/display/Toast/Toast';
import Drawer from './components/display/Drawer/Drawer';
import LoadingOverlay from './components/display/LoadingOverlay/LoadingOverlay';

const App: FC = () => {
  const content = useRoutes(router);
  const auth = useAuth();
  useScrollTop();

  const { popovers, closePopover } = usePopover();
  const { drawers, closeDrawer } = useDrawer();
  const { dialogs, closeDialog } = useDialog();
  const { toast, toastOpen, closeToast } = useToast();
  const { loadingOverlay } = useLoadingOverlay();

  return (
    <>
      {auth.isInitialized ? content : <AppInit />}
      {/* 
        Popovers, Drawers, Dialogs and Toasts are rendered here
        so they can use the context from both the UIContexts and AppContexts
      */}
      {popovers.map((p, i) => (
        <Popover key={i} {...p} onClose={closePopover} />
      ))}
      {drawers.map((d, i) => (
        <Drawer key={i} {...d} onClose={closeDrawer} />
      ))}
      {dialogs.map((d, i) => (
        <Dialog key={i} {...d} onClose={closeDialog} />
      ))}
      <Toast
        open={toastOpen}
        onClose={closeToast}
        title={toast.title}
        type={toast.type}
      >
        {toast.children}
      </Toast>
      <LoadingOverlay
        open={loadingOverlay.open}
        message={loadingOverlay.message}
      />
    </>
  );
};
export default App;
