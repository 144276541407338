import { styled } from '@mui/system';
import { FC } from 'react';
import Dialog from 'src/components/display/Dialog';
import { DocumentEditor } from './DocumentEditor';
import { DocumentEditorParams } from 'src/types/documents';
import { useQueryClient } from 'react-query';
import { zIndices } from 'src/components/styles/constants';

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    max-width: 100%;
  }
  z-index: ${zIndices.highest};
`;

export const DocumentEditorDialog: FC<{
  isDocumentsModalOpen: boolean;
  onDocumentsModalOpen: (isOpen: boolean) => void;
  documentEditorInfo: DocumentEditorParams;
  onDocumentEditorInfoChange: (info: DocumentEditorParams) => void;
  onClose?: () => void;
}> = ({
  isDocumentsModalOpen,
  onDocumentsModalOpen,
  onClose,
  onDocumentEditorInfoChange,
  documentEditorInfo
}) => {
  const queryClient = useQueryClient();
  const { isDocumentClosable } = documentEditorInfo;
  return (
    <StyledDialog
      closable={isDocumentClosable}
      open={true}
      sx={{ visibility: isDocumentsModalOpen ? 'visible' : 'hidden' }}
      onClose={() => {
        onDocumentsModalOpen(false);
        onDocumentEditorInfoChange({});
        queryClient.invalidateQueries([
          'patients',
          documentEditorInfo?.patientId,
          'documents'
        ]);
        onClose?.();
      }}
      fullWidth
    >
      <DocumentEditor />
    </StyledDialog>
  );
};
