import { FC, useCallback } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import { styled } from '@mui/system';
import VideocamIcon from '@mui/icons-material/Videocam';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { UserTypes } from 'src/types/user';
import { Appointment } from 'src/types/appointment';
import { getFullName } from 'src/utils/general';
import { getDateFormat, getTimeFormat } from 'src/utils/dateAndTIme';
import { fontWeights } from 'src/components/styles/fonts';
import Typography from 'src/components/display/Typography/Typography';
import Flex from 'src/components/layout/Flex/Flex';
import IconButton from 'src/components/display/IconButton/IconButton';
import { spacings } from 'src/components/styles/constants';
import Box from 'src/components/layout/Box/Box';
import { useGetDefaultAvatar } from 'src/utils/defaultImages';
import PatientAvatar from 'src/components/display/Avatar/PatientAvatar';
import usePatientsApi from 'src/hooks/usePatientsApi';
import Loader from 'src/components/display/Loader';

const StyledAppointmentTypeTypography = styled(Typography)`
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  width: 100%;
`;

const AvatarCell: FC<{ patientId: string }> = ({ patientId }) => {
  const { getDefaultAvatar } = useGetDefaultAvatar();
  const { getPatientById } = usePatientsApi();
  const { data: patient, isLoading } = getPatientById(patientId);

  const avatar = getDefaultAvatar({
    userId: patientId,
    userType: UserTypes.patients,
    pictureId: patient?.personalInfo?.pictureId
  });

  return (
    <Box alignSelf="center">
      {isLoading ? (
        <Loader />
      ) : (
        <PatientAvatar image={avatar} patientId={patientId} />
      )}
    </Box>
  );
};

const DetailsCell: FC<{ patientId: string; appointment: Appointment }> = ({
  patientId,
  appointment
}) => {
  const { getPatientById } = usePatientsApi();
  const { data: patient, isLoading } = getPatientById(patientId);

  const isPartnerAppointment = patient?.partnerInfo?.id === patientId;
  const fullName = getFullName(
    isPartnerAppointment ? patient?.partnerInfo : patient?.personalInfo
  );

  return (
    <Flex gap={spacings.small} width={1} justifyContent="space-between">
      <Box flexGrow={4}>
        <Typography fontWeight={fontWeights.extraBold}>
          {isLoading ? <Loader /> : fullName}
        </Typography>
      </Box>
      <Box flexGrow={2}>
        <StyledAppointmentTypeTypography>
          {appointment?.appointmentType || '--'}
        </StyledAppointmentTypeTypography>
      </Box>
      <Box flexGrow={1}>
        <Typography>{appointment?.location || '--'}</Typography>
      </Box>
    </Flex>
  );
};

export const useGetScheduleColumns = () => {
  const { t } = useTranslation();

  const getColumns = useCallback(
    (params?: {
      hideLaunchMeetingButton?: boolean;
      hideDate?: boolean;
    }): GridColDef<Appointment>[] => {
      const { hideLaunchMeetingButton = false, hideDate = false } =
        params || {};

      const columns: GridColDef<Appointment>[] = [
        {
          field: 'avatar',
          headerName: t('AVATAR'),
          renderHeader: () => null,
          width: 40,
          align: 'center',
          filterable: false,
          renderCell: ({ row: patient }) => {
            const { patientId } = patient;
            return <AvatarCell patientId={patientId} />;
          }
        },
        {
          field: 'details',
          headerName: t('DETAILS'),
          renderHeader: () => null,
          flex: 4,
          align: 'left',
          renderCell: ({ row: { appointmentType, location, patientId } }) => (
            <DetailsCell
              patientId={patientId}
              appointment={{ appointmentType, location }}
            />
          )
        },
        {
          field: 'date',
          headerName: t('DATE'),
          renderHeader: () => null,
          width: 100,
          type: 'date' as const,
          valueGetter: (value) => (value ? new Date(value) : null),
          renderCell: ({ row: { date } }) => (
            <Box alignSelf="center">
              {date ? (
                <Flex>
                  <Typography fontWeight={fontWeights.extraBold}>
                    {!hideDate
                      ? `${dayjs(date).format(getDateFormat())} ${dayjs(
                          date
                        ).format(getTimeFormat({ isShort: true }))}`
                      : `${dayjs(date).format(
                          getTimeFormat({ isShort: true })
                        )}`}
                  </Typography>
                </Flex>
              ) : (
                '--'
              )}
            </Box>
          )
        },
        ...(!hideLaunchMeetingButton
          ? [
              {
                field: 'launchMeeting',
                headerName: t('LAUNCH_MEETING'),
                renderHeader: () => null,
                flex: 1,
                align: 'center',
                filterable: false,
                renderCell: ({ row: { isVirtual } }) =>
                  isVirtual && (
                    <Box id="launch-meeting-button-container">
                      <IconButton icon={<VideocamIcon />} bgColor="poloBlue" />
                    </Box>
                  )
              } as GridColDef<Appointment>
            ]
          : [])
      ];

      return columns;
    },
    [t]
  );

  return { getColumns };
};
