import React from 'react';
import { ReactComponent as Oops } from '../../assets/icons/oops.svg';
import { Colors } from '../styles/colors';
import { containerSizes, radii, shadows, spacings } from '../styles/constants';
import { fontFamilies, fontWeights } from '../styles/fonts';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Flex from '../layout/Flex';
import { Link } from '@mui/material';
import { supportEmailAddress } from 'src/utils/general';
import { useTranslation } from 'react-i18next';

export const StyledErrorPageContainer = styled(Box)`
  background-color: ${Colors.vistaWhite};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: -8px;
`;

export const StyledErrorPageInnerContainer = styled(Box)`
  display: flex;
  align-items: center;
  max-width: ${containerSizes.boxed};
  background-color: ${Colors.white};
  box-shadow: ${shadows.default};
  border-radius: ${radii.xlarge};
  padding: 50px;
  text-align: center;
`;

const StyledImageContainer = styled(Box)`
  flex: 1;
  margin-right: ${spacings.xlarge};
`;

const StyledImage = styled(Oops)`
  width: 361px;
  height: 361px;
`;

export const StyledErrorPageTextContainer = styled(Box)`
  font-family: ${fontFamilies.primary};
  font-weight: ${fontWeights.bold};
  font-size: 70px;
  color: ${Colors.darkGray};
`;

export const StyledErrorPageSubText = styled(Box)`
  font-size: 24px;
  color: ${Colors.emperor};
  display: block;
`;

export function ErrorFallback() {
  const { t } = useTranslation();

  return (
    <StyledErrorPageContainer>
      <StyledErrorPageInnerContainer>
        <StyledImageContainer>
          <StyledImage />
        </StyledImageContainer>
        <Flex>
          <StyledErrorPageTextContainer>
            {t('OOPS_SOMETHING_WENT_WRONG')}
            <StyledErrorPageSubText>
              {t('PLEASE_CONTACT_SUPPORT') + ' '}
              <Link
                href={`mailto:${supportEmailAddress}`}
                color={Colors.emperor}
                fontSize={24}
              >
                {supportEmailAddress}
              </Link>
            </StyledErrorPageSubText>
          </StyledErrorPageTextContainer>
        </Flex>
      </StyledErrorPageInnerContainer>
    </StyledErrorPageContainer>
  );
}
