import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePicker from 'src/components/data-entry/DatePicker/DatePicker';
import InputField from 'src/components/data-entry/InputField/InputField';
import Button from 'src/components/display/Button/Button';
import Box from 'src/components/layout/Box/Box';
import Flex from 'src/components/layout/Flex/Flex';
import { spacings } from 'src/components/styles/constants';
import Loader from 'src/components/display/Loader/Loader';
import dayjs from 'dayjs';
import { getFullName } from 'src/utils/general';
import usePatientsApi from '../../../hooks/usePatientsApi';
import useEggsEmbryos from 'src/hooks/useEggsEmbryos';
import { EmbryologyReport } from 'src/types/eggAndEmbryo';

export const AddEditEmbryologyReportForm: FC<{
  patientId?: string;
  cycleId?: string;
}> = ({ cycleId, patientId }) => {
  const { getPatientById } = usePatientsApi();
  const { createEmbryologyReport } = useEggsEmbryos();
  const { t } = useTranslation();

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);

  const { mutate: createReport, isLoading: isCreatingReport } =
    createEmbryologyReport();

  const defaultValues: EmbryologyReport = {
    patientId,
    cycleId,
    patientName: '',
    retrievalDate: dayjs().toDate(),
    eggsInseminated: null,
    eggsMature: null,
    eggsRetrieved: null
  };

  const { control, formState, handleSubmit, setValue, getValues } =
    useForm<EmbryologyReport>({
      mode: 'onChange',
      defaultValues
    });

  useEffect(() => {
    if (!patient) return;
    setValue('patientName', getFullName(patient.personalInfo));
  }, [patient]);

  const { errors } = formState;

  const onSubmit = async (report: EmbryologyReport) => {
    await createReport(report);
  };

  if (isLoadingPatient) return <Loader />;

  return (
    <Box marginTop={spacings.large}>
      <form noValidate>
        <Box marginBottom={spacings.medium}>
          <Controller
            name="patientName"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                disabled
                label={t('PATIENT_NAME')}
                placeholder={t('PATIENT_NAME')}
                error={!!errors.patientName}
                helperText={errors?.patientName?.message}
                required
                fullWidth
              />
            )}
          />
        </Box>
        <Box marginBottom={spacings.large}>
          <Controller
            name="retrievalDate"
            control={control}
            rules={{
              required: t('DATE_REQUIRED'),
              validate: (value) => {
                const date = dayjs(value);
                if (date.isAfter(dayjs())) {
                  return t('DATE_CANT_BE_IN_THE_FUTURE');
                }
                return true;
              }
            }}
            render={({ field: { ref, ...field } }) => (
              <DatePicker
                {...field}
                label={t('DATE_OF_RETRIEVAL')}
                inputRef={ref}
                error={!!errors?.retrievalDate}
                helperText={errors.retrievalDate?.message}
                fullWidth
              />
            )}
          />
        </Box>
        <Flex gap={spacings.large}>
          <Controller
            name="eggsRetrieved"
            control={control}
            rules={{
              required: t('PLEASE_ENTER_EGGS_RETRIEVED')
            }}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={t('EGGS_RETRIEVED')}
                placeholder={t('EGGS_RETRIEVED')}
                error={!!errors?.eggsRetrieved}
                helperText={errors?.eggsRetrieved?.message}
                required
                fullWidth
                type="number"
              />
            )}
          />
          <Controller
            name="eggsMature"
            rules={{
              validate: (value) => {
                const eggsRetrieved = getValues('eggsRetrieved');

                if (eggsRetrieved && +value > +eggsRetrieved) {
                  return t('MUST_BE_LESS_THAN_EGGS_RETRIEVED');
                }
              }
            }}
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={t('EGGS_MATURE')}
                placeholder={t('EGGS_MATURE')}
                error={!!errors?.eggsMature}
                helperText={errors?.eggsMature?.message}
                fullWidth
                type="number"
              />
            )}
          />
        </Flex>

        <Flex
          justifyContent="center"
          paddingX={spacings.x2large}
          marginTop={spacings.large}
          width="50%"
          marginX="auto"
        >
          <Button
            fullWidth
            type="button"
            disabled={isCreatingReport}
            onClick={handleSubmit(onSubmit)}
          >
            {t('SUBMIT')}
          </Button>
        </Flex>
      </form>
    </Box>
  );
};
