import { FC, ReactNode } from 'react';
import {
  Drawer as MuiDrawer,
  DrawerProps as MuiDrawerProps
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';

import { makeShouldForwardProps } from 'src/components/utils';
import { spacings } from 'src/components/styles/constants';
import Box from 'src/components/layout/Box/Box';
import Flex from 'src/components/layout/Flex/Flex';

import MiniIconButton from '../MiniIconButton';

export interface DrawerProps extends MuiDrawerProps {
  header?: ReactNode;
  width?: string;
  backgroundColor?: string;
}

const shouldForwardProp = makeShouldForwardProps([
  'isScreenLarge',
  'backgroundColor'
]);
const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp
})<DrawerProps>`
  .MuiDrawer-paper {
    overflow-y: auto;
    overflow-x: hidden;
    width: ${({ width }) => width};
    max-width: 100%;
    background-color: ${({ backgroundColor }) => backgroundColor};
  }
`;

const HeaderContainer = styled(Box)`
  padding: ${spacings.medium} ${spacings.medium} 0 ${spacings.medium};
`;

const ContentContainer = styled(Box)`
  padding: ${spacings.medium};
  height: 100%;
`;

const Drawer: FC<DrawerProps> = ({
  children,
  header,
  onClose,
  width = '400px',
  ...otherProps
}) => {
  return (
    <StyledDrawer
      onClose={onClose}
      anchor="right"
      open={otherProps.open}
      width={width}
      {...otherProps}
    >
      <HeaderContainer>
        <Flex alignItems="center" justifyContent="space-between">
          {header || <Box />}
          {onClose && (
            <MiniIconButton
              icon={<CloseIcon />}
              onClick={(event) => onClose(event, 'escapeKeyDown')}
            />
          )}
        </Flex>
      </HeaderContainer>
      <ContentContainer>{children}</ContentContainer>
    </StyledDrawer>
  );
};

export default Drawer;
