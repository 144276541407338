import { AxiosResponse } from 'axios';

import {
  convertEmbryologyReportClientToServer,
  convertThawReportFormClientToServer,
  convertUpdateEggClientToServer
} from 'src/modules/patients/utils/conversions';
import { EmbryologyRecord } from 'src/modules/patients/gameteAndCryo/EmbryologyTable';
import { GameteThawReport, ThawReportForm } from 'src/types/cycle';
import {
  EggAndEmbryo,
  EggAndEmbryoNotesToCreate,
  EggsAndEmbryosReport,
  EmbryologyReport,
  UpdateEgg,
  UpdateGameteThawed
} from 'src/types/eggAndEmbryo';
import {
  EggAndEmbryoServer,
  EggsAndEmbryosReportServer,
  GameteThawReportServer,
  ThawReportFormServer
} from 'src/types/patient-server';
import axiosApiInstance from 'src/utils/axios';

export async function updateGameteThawedRequest(
  patientId: string,
  gameteId: string,
  gamete: UpdateGameteThawed
): Promise<ThawReportFormServer> {
  const response = await axiosApiInstance.patch<ThawReportFormServer>(
    `/gamete-thaw/${gameteId}/patient/${patientId}/`,
    gamete
  );
  return response.data;
}

export async function createEmbryologyReportRequest(
  report: EmbryologyReport
): Promise<EggsAndEmbryosReportServer> {
  const response = await axiosApiInstance.post<EggsAndEmbryosReportServer>(
    `/patients/${report.patientId}/egg-and-embryo`,
    convertEmbryologyReportClientToServer(report)
  );
  return response.data;
}

export async function upsertNotesRequest(
  notesToCreate: EggAndEmbryoNotesToCreate
): Promise<EggAndEmbryoNotesToCreate> {
  const response = await axiosApiInstance.post<EggAndEmbryoNotesToCreate>(
    `/egg-and-embryo-notes`,
    notesToCreate
  );

  return response.data;
}

export const getEggsAndEmbryosReportRequest = async (
  patientId: string,
  cycleId: string
): Promise<EggsAndEmbryosReport> => {
  const response = await axiosApiInstance.get<EggsAndEmbryosReportServer>(
    `/patients/${patientId}/cycles/${cycleId}/egg-and-embryo`
  );
  return response.data;
};

export async function updateEggRequest({
  patientId,
  cycleId,
  eggId,
  egg
}: {
  patientId: string;
  cycleId: string;
  eggId: string;
  egg: UpdateEgg;
}): Promise<EggsAndEmbryosReportServer> {
  const response = await axiosApiInstance.patch<EggsAndEmbryosReportServer>(
    `/patients/${patientId}/cycles/${cycleId}/egg-and-embryo/${eggId}`,
    convertUpdateEggClientToServer(egg)
  );
  return response.data;
}

export async function createThawReportRequest(
  thawReport: ThawReportForm
): Promise<ThawReportFormServer> {
  const response = await axiosApiInstance.post<ThawReportFormServer>(
    `/gamete-thaw`,
    convertThawReportFormClientToServer(thawReport)
  );

  return response.data;
}

export const getThawReportRequest = async (
  patientId: string,
  cycleId: string
): Promise<GameteThawReport> => {
  const response = await axiosApiInstance.get<GameteThawReportServer>(
    `/gamete-thaw/patients/${patientId}/cycles/${cycleId}`
  );
  return response.data;
};

export const getEggAndEmbryoByPatientId = async (
  patientId: string
): Promise<EmbryologyRecord[]> => {
  const response: AxiosResponse<EmbryologyRecord[]> =
    await axiosApiInstance.get<EmbryologyRecord[]>(
      `/patients/${patientId}/egg-and-embryo`
    );

  return response.data;
};

export async function getViableEggsListRequest(
  patientId: string
): Promise<EggAndEmbryo[]> {
  const response = await axiosApiInstance.get<EggAndEmbryoServer[]>(
    `/patients/${patientId}/egg-and-embryo/viable`
  );
  return response.data;
}
