import axiosApiInstance from 'src/utils/axios';

export const verifyReCaptchaRequest = async (
  token: string
): Promise<boolean> => {
  const response = await axiosApiInstance.post<boolean>(
    `/auth/verify-recaptcha`,
    {
      token
    }
  );
  return response.data;
};
