import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';

import { useDialog } from 'src/contexts/UIContexts';
import useAuth from 'src/hooks/useAuth';
import Box from '../../../components/layout/Box';
import Typography from '../../../components/display/Typography';
import Button from '../../../components/display/Button';
import { fontWeights } from '../../../components/styles/fonts';
import { spacings } from '../../../components/styles/constants';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { PatientsSearchBar } from '../../../layouts/CollapsedSidebarLayout/Header/PatientsSearchBar';

interface ResendEmailFormType {
  patientId: string;
}

const googleRecaptchaSiteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;
const googleEnterpriseRecaptchaUrl = `https://www.google.com/recaptcha/enterprise.js?render=${googleRecaptchaSiteKey}`;

export const ResendEmailForm: FC = () => {
  const [isVerifying, setIsVerifying] = useState(false);

  const { t } = useTranslation();
  const { resendPatientConfirmationEmail } = usePatientsApi();
  const { closeDialog } = useDialog();
  const { verifyReCaptcha } = useAuth();

  const { control, handleSubmit } = useForm<ResendEmailFormType>({
    mode: 'onChange',
    defaultValues: {
      patientId: ''
    }
  });

  const { mutate: handleSendEmail, isLoading: isSendingEmail } =
    resendPatientConfirmationEmail();

  const verifyMutation = verifyReCaptcha();

  useEffect(() => {
    const loadRecaptcha = () => {
      if (document.querySelector(`script[src*="recaptcha/enterprise.js"]`)) {
        return; // Avoid loading multiple times
      }

      const script = document.createElement('script');
      script.src = googleEnterpriseRecaptchaUrl;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    };

    loadRecaptcha();
  }, []);

  const handleGoogleTokenVerify = async (): Promise<boolean> => {
    if (!window.grecaptcha) {
      return;
    }

    if (!window.grecaptcha || !window.grecaptcha.enterprise) {
      console.error('reCAPTCHA not loaded');
    }

    const token = await window.grecaptcha.enterprise.execute(
      googleRecaptchaSiteKey,
      {
        action: 'submit'
      }
    );

    try {
      setIsVerifying(true);

      const result = await verifyMutation.mutateAsync({ token });
      return result;
    } catch (error) {
      return false;
    } finally {
      setIsVerifying(false);
    }
  };

  const onSubmit = async (data: ResendEmailFormType) => {
    const isTokenValid = await handleGoogleTokenVerify();

    if (isTokenValid) {
      await handleSendEmail(data.patientId, {
        onSuccess: () => {
          closeDialog();
        }
      });
    }
  };

  return (
    <Box paddingTop={spacings.large}>
      <Box marginBottom={spacings.medium}>
        <Typography variant="h3" fontWeight={fontWeights.bold}>
          {t('RESEND_WELCOME_EMAIL_REQUEST').toUpperCase()}
        </Typography>
      </Box>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Box marginBottom={spacings.medium}>
          <Controller
            name="patientId"
            control={control}
            rules={{
              required: t('PLEAD_CHOOSE_PATIENT')
            }}
            render={({ field: { onChange } }) => (
              <PatientsSearchBar
                onSelectPatient={(patient) => onChange(patient.id)}
              />
            )}
          />
        </Box>
        <Box width="30%">
          <Button
            disabled={isSendingEmail || isVerifying}
            fullWidth
            type="submit"
          >
            {t('SEND')}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
