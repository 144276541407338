import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { GridFilterModel, GridLogicOperator } from '@mui/x-data-grid-premium';

import { AddEditAppointmentTabs, Appointment } from 'src/types/appointment';
import { UserTypes } from 'src/types/user';
import usePatientsApi from 'src/hooks/usePatientsApi';

import { ReactComponent as BillingIcon } from 'src/assets/icons/billing.svg';
import { ReactComponent as CalendarIcon } from 'src/assets/icons/calendar2.svg';
import { ReactComponent as NotepadIcon } from 'src/assets/icons/notepad.svg';
import { ReactComponent as CycleIcon } from 'src/assets/icons/cycle.svg';

import { getFullName, getUserAge } from 'src/utils/general';
import { useGetDefaultAvatar } from 'src/utils/defaultImages';
import { longDateFormat } from 'src/utils/dateAndTIme';
import { patientRoutes } from 'src/router/routes';
import useEncounters from 'src/hooks/useEncounters';
import useTasks from 'src/hooks/useTasks';
import useDebounce from 'src/hooks/useDebounce';
import { PatientActionsMenu } from 'src/modules/patients/actionMenu/PatientActionsMenu';
import EncounterNotesTab from 'src/modules/calendar/appointmentTabs/EncounterNotesTab';
import ConsentDialogTemplate from 'src/modules/ConsentDialogTemplate';
import { PatientFlags } from 'src/modules/patients/medicalHistory/PatientFlags';
import { TasksTable } from 'src/modules/tasksTable/TasksTable';
import { SelectPatient } from 'src/modules/tasksTable/SelectPatient';
import { AddChecklistMenu } from 'src/modules/patients/overview/AddChecklistMenu';
import PatientNotesTable from 'src/modules/patients/overview/PatientNotesTable/PatientNotesTable';
import { fonts, fontWeights } from 'src/components/styles/fonts';
import { radii, spacings } from 'src/components/styles/constants';
import { Colors } from 'src/components/styles/colors';
import { TooltipWrapper } from 'src/components/display/Tooltip';
import AddEditAppointment from 'src/modules/calendar/AddEditAppointment';
import Flex from 'src/components/layout/Flex';
import Box from 'src/components/layout/Box';
import Typography from 'src/components/display/Typography';
import IconButton from 'src/components/display/IconButton';
import Loader from 'src/components/display/Loader';
import { AvatarSizes } from 'src/components/display/Avatar/Avatar';
import PatientAvatar from 'src/components/display/Avatar/PatientAvatar';
import MiniIconButton from 'src/components/display/MiniIconButton';
import Tabs, { TabContentsProps } from 'src/components/display/Tabs/Tabs';

import { DrawerContainerCard } from './DailyWorkListDrawer';
import { HistoryTab } from './tabs/HistoryTab';
import { paginationDefaultParams } from 'src/constants';
import { useDialog, usePopover } from 'src/contexts/UIContexts';
import { useDailyWorkListDrawer } from 'src/contexts/AppContexts/AppContexts';
import { AppointmentStatusSelect } from 'src/modules/patients/dashboard/AppointmentStatusSelect';

export enum PatientQuickViewTabs {
  HISTORY = 'history',
  ENCOUNTER_NOTES = 'encounterNotes',
  HISTORICAL_NOTES = 'historicalNotes',
  TASKS = 'tasks'
}

const ICON_SIZE = '19px';

const StyledArrowBack = styled(ArrowBack)`
  color: ${Colors.emperor};
`;

const StyledSpecsKeyTypography = styled(Typography)`
  font: ${fonts.pill};
  flex: 1;
`;

const StyledSpecsValueTypography = styled(Typography)`
  font: ${fonts.pill};
  font-weight: ${fontWeights.extraBold};
  flex: 2;
`;

const StyledIconButton = styled(IconButton)`
  color: black;
  background-color: ${Colors.transparent};

  &.MuiButtonBase-root {
    padding: ${spacings.none} 3px;
  }

  svg {
    width: ${ICON_SIZE};
  }

  :hover {
    background-color: ${Colors.transparent};
  }
`;

const BlackIconButton = styled(StyledIconButton)`
  color: ${Colors.white};
  background-color: ${Colors.black};

  :hover {
    background-color: ${Colors.black};
  }
`;

const ColoredBox = styled(Flex)`
  align-items: center;
  padding: ${spacings.small} ${spacings.large};
  background-color: ${Colors.vistaWhite};
  border-radius: ${radii.full} ${radii.none} ${radii.none} ${radii.full};
  margin-right: -${spacings.xlarge};
  max-height: 40px;
`;

const StyledAppointmentStatusSelectContainer = styled(Flex)`
  .MuiBox-root {
    width: 200px;
    border-radius: ${radii.full} ${radii.none} ${radii.none} ${radii.full};
    margin-right: -${spacings.xlarge};
    max-height: 20px;
  }
`;

export const PatientQuickView: FC<{
  patientId: string;
  appointment?: Appointment | null;
  onBackClick: () => void;
}> = ({ patientId, appointment, onBackClick }) => {
  const [selectedTab, setSelectedTab] = useState<PatientQuickViewTabs>(
    PatientQuickViewTabs.HISTORY
  );
  const [isCurrentFormDirty, setIsCurrentFormDirty] = useState(false);
  const [paginationModel, setPaginationModel] = useState(
    paginationDefaultParams
  );
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openPopover } = usePopover();
  const { onDailyWorklistDrawerOpenChange: onDrawerOpenChange } =
    useDailyWorkListDrawer();
  const { openDialog, closeDialog } = useDialog();
  const debouncedFilterModel = useDebounce(filterModel);
  const { getDefaultAvatar } = useGetDefaultAvatar();
  const { getEncounterByAppointmentId } = useEncounters();
  const { getPatientById, getPatientOverview } = usePatientsApi();

  const {
    data: patient,
    isLoading: isPatientLoading,
    isFetching: isPatientFetching
  } = getPatientById(patientId, {
    enabled: !!patientId
  });

  const {
    data: patientOverview,
    isLoading: isPatientOverviewLoading,
    isFetching: isPatientOverviewFetching
  } = getPatientOverview(patientId);

  const { getPatientTasks } = useTasks();

  const {
    data: tasks,
    isLoading: isTasksLoading,
    isFetching: isTasksFetching
  } = getPatientTasks({
    patientId,
    params: {
      pagination: paginationModel
    },
    filters: debouncedFilterModel
  });

  const {
    data: encounter,
    isLoading: isEncounterLoading,
    isFetching: isEncounterFetching
  } = getEncounterByAppointmentId(appointment?.id);

  const isPageLoading =
    isPatientLoading ||
    isPatientOverviewLoading ||
    isEncounterLoading ||
    isPatientFetching ||
    isPatientOverviewFetching ||
    isEncounterFetching;
  const rows = tasks?.rows ?? [];
  const totalRowCount = tasks?.count ?? 0;
  const isTasksTableLoading = isTasksLoading || isTasksFetching;

  const { appointmentType } = appointment || {};
  const { maritalStatus, personalInfo, partnerInfo } = patient || {};
  const { patientBasicInfo } = patientOverview || {};
  const { age, displayId } = patientBasicInfo || {};
  const { years, months } = age || {};
  const { dateOfBirth, phoneNumber, sexAtBirth } = personalInfo || {};

  const patientName = getFullName(personalInfo);
  const partnerName = getFullName(partnerInfo);
  const avatar = getDefaultAvatar({
    userId: patientId,
    userType: UserTypes.patients,
    pictureId: personalInfo?.pictureId
  });
  const partnerAvatar = getDefaultAvatar({
    userId: partnerInfo?.id,
    userType: UserTypes.patients,
    pictureId: partnerInfo?.pictureId
  });

  const handleTabChange = (value: PatientQuickViewTabs) => {
    if (isCurrentFormDirty) {
      openDialog({
        header: t('UNSAVED_CHANGES_HEADER'),
        children: (
          <ConsentDialogTemplate
            declineContent={t('CANCEL')}
            acceptContent={t('DISCARD_CHANGES')}
            content={t('UNSAVED_CHANGES_CONTENT')}
            onAccept={() => {
              setIsCurrentFormDirty(false);
              setSelectedTab(value);
              closeDialog();
            }}
            onDecline={closeDialog}
          />
        )
      });
    } else {
      setSelectedTab(value);
    }
  };

  const goToEditAppointment = (defaultTab?: AddEditAppointmentTabs) => {
    openDialog({
      header: t('EDIT_APPOINTMENT'),
      children: (
        <AddEditAppointment
          appointmentId={appointment.id}
          patientId={appointment.patientId}
          defaultTab={defaultTab}
        />
      ),
      fullWidth: true,
      fullHeight: true,
      maxWidth: 'lg'
    });
  };

  const handleFilterChange = useCallback(
    (newFilterModel: GridFilterModel) => {
      setFilterModel(newFilterModel);
    },
    [setFilterModel]
  );

  const tabContents: TabContentsProps<PatientQuickViewTabs>[] = [
    {
      value: PatientQuickViewTabs.HISTORY,
      label: t('HISTORY'),
      children: <HistoryTab patientId={patientId} />
    },
    {
      value: PatientQuickViewTabs.ENCOUNTER_NOTES,
      label: t('ENCOUNTER_NOTES'),
      children: (
        <Flex flexDirection="column" gap={spacings.large}>
          <Flex justifyContent="space-between">
            <Flex
              flexGrow={1}
              alignItems="center"
              gap={spacings.medium}
              position="relative"
            >
              <Typography variant="h4" fontWeight={fontWeights.extraBold}>
                {t('ENCOUNTER_ID').toLocaleUpperCase()}
              </Typography>
              <Typography>{encounter?.displayId}</Typography>
            </Flex>
            <StyledAppointmentStatusSelectContainer>
              <AppointmentStatusSelect appointment={appointment} />
            </StyledAppointmentStatusSelectContainer>
          </Flex>
          <EncounterNotesTab
            onDirtyFormChange={setIsCurrentFormDirty}
            appointmentId={appointment.id}
            patientId={patientId}
            hideEncounterDetails
          />
        </Flex>
      )
    },
    {
      value: PatientQuickViewTabs.HISTORICAL_NOTES,
      label: t('HISTORICAL_NOTES'),
      children: (
        <Flex flexDirection="column">
          <PatientNotesTable standalone patientId={patientId} toolbar={null} />
        </Flex>
      )
    },
    {
      value: PatientQuickViewTabs.TASKS,
      label: (
        <Flex gap={spacings.medium} alignItems="center">
          {t('TASKS')}
          <MiniIconButton
            id="add-tasks-button"
            icon={<AddIcon />}
            onClick={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
              openPopover({
                children: (
                  <AddChecklistMenu
                    patientId={patientId}
                    anchorEl={ev.currentTarget}
                  />
                ),
                anchorEl: ev.currentTarget,
                hideToolbar: true
              });
            }}
          />
        </Flex>
      ),
      children: (
        <Flex flexDirection="column">
          <Box alignSelf="end">
            <SelectPatient
              patientId={patientId}
              filterModel={filterModel}
              onChange={handleFilterChange}
            />
          </Box>
          <TasksTable
            standalone
            pageSize={10}
            tasks={rows || []}
            loading={isTasksTableLoading}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            filterModel={filterModel}
            filterMode="server"
            onFilterModelChange={handleFilterChange}
            rowCount={totalRowCount}
          />
        </Flex>
      )
    }
  ];

  return (
    <DrawerContainerCard backgroundColor={Colors.white}>
      <Flex justifyContent="space-between">
        <Flex gap={spacings.small} alignItems="center">
          <IconButton
            icon={<StyledArrowBack />}
            onClick={onBackClick}
            iconSize="medium"
            bgColor="transparent"
          />
          <PatientAvatar
            size={AvatarSizes.MEDIUM}
            image={avatar}
            patientId={patientId}
            title={patientName}
            subtitle={appointmentType}
            alignAvatar="left"
          />
          <Box marginLeft={spacings.medium}>
            <BlackIconButton
              icon={<AddRoundedIcon />}
              onClick={(ev) => {
                openPopover({
                  children: <PatientActionsMenu patientId={patientId} />,
                  anchorEl: ev.currentTarget,
                  hideToolbar: true,
                  anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'bottom'
                  },
                  transformOrigin: {
                    horizontal: 'right',
                    vertical: 'bottom'
                  },
                  anchorReference: 'anchorEl'
                });
              }}
            />
          </Box>
        </Flex>
        <ColoredBox>
          <TooltipWrapper arrow placement="top" title={t('CODING')}>
            <span>
              <StyledIconButton
                icon={<BillingIcon />}
                onClick={() => {
                  goToEditAppointment(AddEditAppointmentTabs.CODING);
                }}
              />
            </span>
          </TooltipWrapper>

          <TooltipWrapper arrow placement="top" title={t('ENCOUNTER_NOTE')}>
            <span>
              <StyledIconButton
                icon={<NotepadIcon />}
                onClick={() => {
                  goToEditAppointment(AddEditAppointmentTabs.ENCOUNTER_NOTE);
                }}
              />
            </span>
          </TooltipWrapper>

          <TooltipWrapper arrow placement="top" title={t('SCHEDULE_OR_ORDERS')}>
            <span>
              <StyledIconButton
                icon={<CalendarIcon />}
                onClick={() => {
                  goToEditAppointment(
                    AddEditAppointmentTabs.SCHEDULE_OR_ORDERS
                  );
                }}
              />
            </span>
          </TooltipWrapper>

          <TooltipWrapper
            arrow
            placement="top"
            title={t('NAVIGATION_CURRENT_CYCLE')}
          >
            <span>
              <StyledIconButton
                icon={<CycleIcon />}
                onClick={() => {
                  navigate(patientRoutes.getCurrentTreatmentLink(patientId));
                  onDrawerOpenChange(false);
                }}
              />
            </span>
          </TooltipWrapper>
        </ColoredBox>
      </Flex>
      {isPageLoading ? (
        <Loader />
      ) : (
        <Flex flexDirection="column" gap={spacings.xlarge} width="100%">
          <PatientFlags patientId={patientId} />
          <Flex gap={spacings.large}>
            <Flex flex={1} gap={spacings.small} flexDirection="column">
              <Typography variant="h4" marginBottom={spacings.medium}>
                {t('PATIENT_DETAILS').toLocaleUpperCase()}
              </Typography>
              <Flex gap={spacings.small}>
                <StyledSpecsKeyTypography>{t('AGE')}</StyledSpecsKeyTypography>
                <StyledSpecsValueTypography>
                  {getUserAge({ years, months })}
                </StyledSpecsValueTypography>
              </Flex>
              <Flex gap={spacings.small}>
                <StyledSpecsKeyTypography>{t('DOB')}</StyledSpecsKeyTypography>
                <StyledSpecsValueTypography>
                  {dayjs(dateOfBirth).format(longDateFormat)}
                </StyledSpecsValueTypography>
              </Flex>
              <Flex gap={spacings.small}>
                <StyledSpecsKeyTypography>
                  {t('STATUS')}
                </StyledSpecsKeyTypography>
                <StyledSpecsValueTypography>
                  {t(maritalStatus)}
                </StyledSpecsValueTypography>
              </Flex>
              <Flex gap={spacings.small}>
                <StyledSpecsKeyTypography>{t('SEX')}</StyledSpecsKeyTypography>
                <StyledSpecsValueTypography>
                  {t(sexAtBirth)}
                </StyledSpecsValueTypography>
              </Flex>
              <Flex gap={spacings.small}>
                <StyledSpecsKeyTypography>
                  {t('PHONE')}
                </StyledSpecsKeyTypography>
                <StyledSpecsValueTypography>
                  {phoneNumber}
                </StyledSpecsValueTypography>
              </Flex>
              <Flex gap={spacings.small}>
                <StyledSpecsKeyTypography>
                  {t('PATIENT_ID')}
                </StyledSpecsKeyTypography>
                <StyledSpecsValueTypography>
                  {displayId?.toUpperCase()}
                </StyledSpecsValueTypography>
              </Flex>
            </Flex>
            <Flex flex={1} gap={spacings.large} flexDirection="column">
              {partnerInfo && (
                <>
                  <Typography variant="h4">
                    {t('PARTNER').toLocaleUpperCase()}
                  </Typography>
                  <Flex>
                    <PatientAvatar
                      size={AvatarSizes.MEDIUM}
                      image={partnerAvatar}
                      patientId={partnerInfo.id}
                      title={partnerName}
                      alignAvatar="left"
                    />
                  </Flex>
                </>
              )}
            </Flex>
          </Flex>
          <Flex gap={spacings.large}>
            <Tabs<PatientQuickViewTabs>
              onTabChange={handleTabChange}
              selectedTab={selectedTab}
              tabContents={tabContents}
            />
          </Flex>
        </Flex>
      )}
    </DrawerContainerCard>
  );
};
