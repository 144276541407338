import {
  convertAppointmentClientToServer,
  convertCycleClientToServer,
  convertCycleFormClientToServer,
  convertCycleGraphDataServerToClient,
  convertCycleServerToClient,
  convertSemenVialServerToClient,
  formatDateToString
} from 'src/modules/patients/utils/conversions';
import {
  AppointmentWithOrderDocumentPayload,
  Cycle,
  CycleGameteCommentsUpdate,
  CycleGraphData,
  ReleasedEggsData,
  CycleTemplates,
  NewCycleFormValues
} from 'src/types/cycle';
import { CreatePatientDocumentPayload } from 'src/types/documents';
import { CycleNotesToCreate } from 'src/types/cycle';
import {
  AvailableGameteServer,
  CycleGraphDataServer,
  CycleServer
} from 'src/types/patient-server';
import axiosApiInstance from 'src/utils/axios';
import { convertNumberIdToString } from 'src/utils/general';
import { AvailableGamete } from 'src/types/eggAndEmbryo';

export async function createCycleRequest({
  cycle,
  ordersDocumentPayload,
  prescriptionsDocumentPayload
}: {
  cycle: NewCycleFormValues;
  ordersDocumentPayload?: CreatePatientDocumentPayload;
  prescriptionsDocumentPayload?: CreatePatientDocumentPayload;
}): Promise<{
  id: string;
  ordersDocumentId?: string;
  prescriptionsDocumentId?: string;
}> {
  const formData = new FormData();

  const convertedCycle = convertCycleFormClientToServer(cycle);

  if (ordersDocumentPayload) {
    const { file, patientId, templateId, isCompleted, metadata } =
      ordersDocumentPayload;

    formData.append('ordersDocumentFile', file);
    formData.append(
      'ordersDocumentData',
      JSON.stringify({
        patientId,
        templateId,
        metadata,
        isCompleted
      })
    );
  }

  if (prescriptionsDocumentPayload) {
    const { file, patientId, templateId, isCompleted, metadata } =
      prescriptionsDocumentPayload;

    formData.append('prescriptionsDocumentFile', file);
    formData.append(
      'prescriptionsDocumentData',
      JSON.stringify({
        patientId,
        templateId,
        metadata,
        isCompleted
      })
    );
  }

  for (const key in convertedCycle) {
    const value = convertedCycle[key];
    if (typeof value !== 'string') {
      formData.append(key, JSON.stringify(value));
    } else {
      formData.append(key, value);
    }
  }

  const response = await axiosApiInstance.post<{
    cycle: CycleServer;
    ordersDocumentId?: number;
    prescriptionsDocumentId?: number;
  }>(`/cycles/patients/${cycle.patientId}`, formData);

  const {
    cycle: newCycle,
    ordersDocumentId,
    prescriptionsDocumentId
  } = response.data;

  return {
    id: newCycle.id,
    ordersDocumentId: convertNumberIdToString(ordersDocumentId),
    prescriptionsDocumentId: convertNumberIdToString(prescriptionsDocumentId)
  };
}
export async function getCycleTemplatesRequest(): Promise<CycleTemplates[]> {
  const response = await axiosApiInstance.get<CycleTemplates[]>(
    'cycles/cycle-templates'
  );

  return response.data;
}
export const getPatientTreatmentsDataRequest = async (
  cycleId: string,
  patientId: string
): Promise<CycleGraphData> => {
  const response = await axiosApiInstance.get<CycleGraphDataServer>(
    `cycles/${cycleId}/patients/${patientId}/graph-data`
  );

  return convertCycleGraphDataServerToClient(response.data);
};

export async function removePrescriptionsFromCycle(cycleId: string) {
  axiosApiInstance.post(`prescriptions/remove/cycle/${cycleId}`, {
    endDate: formatDateToString(new Date())
  });
}

export const getReleasedData = async (cycleId: string, patientId: string) => {
  const response = await axiosApiInstance.get<ReleasedEggsData[]>(
    `cycles/${cycleId}/patients/${patientId}/released-data`
  );
  return response.data;
};
export const updateReleasedDate = async (
  cycleId: string,
  patientId: string,
  releasedData: ReleasedEggsData[]
): Promise<ReleasedEggsData[]> => {
  const response = await axiosApiInstance.put<ReleasedEggsData[]>(
    `cycles/${cycleId}/patients/${patientId}/released-data`,
    releasedData
  );
  return response.data;
};
export async function updateCycleRequest({
  cycle,
  appointmentsWithOrderDocumentPayload,
  prescriptionsDocumentPayload
}: {
  cycle: Partial<Cycle>;
  appointmentsWithOrderDocumentPayload?: AppointmentWithOrderDocumentPayload[];
  prescriptionsDocumentPayload?: CreatePatientDocumentPayload;
}): Promise<{
  id: string;
  orderDocumentIds?: string[];
  prescriptionsDocumentId?: string;
}> {
  const formData = new FormData();
  const convertedCycle = convertCycleClientToServer(cycle);

  if (appointmentsWithOrderDocumentPayload) {
    const convertedAppointments = appointmentsWithOrderDocumentPayload.map(
      (appointment) => {
        return {
          ...convertAppointmentClientToServer(appointment),
          labOrderInfo: appointment?.labOrderInfo,
          metadata: appointment?.metadata,
          isCompleted: false,
          templateKey: appointment.templateKey,
          patientId: cycle.patientId
        };
      }
    );

    formData.append(
      'appointmentsWithOrderData',
      JSON.stringify(convertedAppointments)
    );
  }

  if (prescriptionsDocumentPayload) {
    const { file, patientId, templateId, isCompleted, metadata } =
      prescriptionsDocumentPayload;

    formData.append('prescriptionsDocumentFile', file);
    formData.append(
      'prescriptionsDocumentData',
      JSON.stringify({
        patientId,
        templateId,
        metadata,
        isCompleted
      })
    );
  }

  for (const key in convertedCycle) {
    const value = convertedCycle[key];
    if (typeof value !== 'string') {
      formData.append(key, JSON.stringify(value));
    } else {
      formData.append(key, value);
    }
  }
  const response = await axiosApiInstance.patch<{
    cycle: CycleServer;
    orderDocumentIds?: number[];
    prescriptionsDocumentId?: number;
  }>(`/cycles/${cycle.id}/patients/${cycle.patientId}`, formData);

  const {
    cycle: newCycle,
    orderDocumentIds,
    prescriptionsDocumentId
  } = response.data;

  return {
    id: newCycle.id,
    orderDocumentIds: orderDocumentIds?.map((orderDocumentId) =>
      convertNumberIdToString(orderDocumentId)
    ),
    prescriptionsDocumentId: convertNumberIdToString(prescriptionsDocumentId)
  };
}

export const upsertCycleNotesRequest = async (
  notesToCreate: CycleNotesToCreate
) => {
  const response = await axiosApiInstance.post('cycle-notes/', notesToCreate);

  return response.data;
};

export async function getPatientCyclesRequest(
  patientId: string
): Promise<Cycle[]> {
  const response = await axiosApiInstance.get<CycleServer[]>(
    `/cycles/by-patient/${patientId}`
  );

  const cycles = response.data.map((serverCycle) =>
    convertCycleServerToClient(serverCycle)
  );

  return cycles;
}

export async function getCycleById(cycleId: string): Promise<Cycle> {
  const response = await axiosApiInstance.get<CycleServer>(
    `/cycles/${cycleId}`
  );
  const cycle = convertCycleServerToClient(response.data);
  return cycle;
}

export async function checkCycleHasEmbryologyReportRequest(
  cycleId: string,
  patientId: string
): Promise<boolean> {
  const response = await axiosApiInstance.get<any>(
    `cycles/${cycleId}/patients/${patientId}/has-embryology-report`
  );
  return response.data;
}

export const getPatientCurrentCycle = async (
  patientId: string
): Promise<Cycle> => {
  const response = await axiosApiInstance.get<CycleServer | null>(
    `/cycles/current-cycle/patients/${patientId}`
  );

  return convertCycleServerToClient(response.data);
};

export async function getCycleAvailableGameteRequest(
  patientId: string
): Promise<AvailableGamete> {
  const response = await axiosApiInstance.get<AvailableGameteServer>(
    `patients/${patientId}/cryo-gamete`
  );

  const { semenVials, ...rest } = response.data;
  return {
    semenVials: semenVials.map((vial) => convertSemenVialServerToClient(vial)),
    ...rest
  };
}

export const updateCycleGameteCommentsRequest = async ({
  cycleId,
  ...rest
}: CycleGameteCommentsUpdate) => {
  const response = await axiosApiInstance.patch<CycleGameteCommentsUpdate>(
    `cycles/${cycleId}/gamete-comments`,
    rest
  );

  return response.data;
};
