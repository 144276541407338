import { Colors } from './colors';

export const iconSizes = {
  xsmall: '12px',
  small: '16px',
  medium: '24px',
  large: '32px',
  xlarge: '48px'
};

export const spacings = {
  none: '0px',
  xsmall: '1px',
  small: '5px',
  medium: '10px',
  large: '15px',
  xlarge: '20px',
  x2large: '30px',
  x3large: '60px'
};

export const spacingsPercentage = {
  none: '0%',
  xsmall: '5%',
  small: '10%',
  medium: '20%',
  large: '30%',
  xlarge: '40%',
  x2large: '50%',
  x3large: '60%',
  x4large: '70%',
  x5large: '80%',
  x6large: '90%'
};

export const radii = {
  none: '0px',
  small: '5px',
  medium: '10px',
  large: '15px',
  xlarge: '30px',
  full: '9999px'
};

export const zIndices = {
  lowest: 0,
  lower: 1,
  low: 2,
  medium: 5,
  high: 10,
  higher: 999,
  highest: 9999
};

export const pageSizes = {
  XSMALL: 5,
  SMALL: 10,
  MEDIUM: 20,
  LARGE: 50
};

export const containerSizes = {
  boxed: '1100px',
  stretched: '100%'
};
export const shadows = {
  default: '0px 1px 2px rgba(0,0,0,0.2)',
  input: '0px 0px 5px rgba(112, 112, 112, 0.5)'
};

export const blackGradient = `linear-gradient(90deg, ${Colors.black} 0%, rgba(0, 0, 0, 0) 100%)`;

export const navbarWidth = 95;
export const buttonsMenuWidth = navbarWidth + 80;

export const messengerPopoverHeight = 'calc(100dvh - 420px)';
export const PREVIEW_FONT_SIZE_LENGTH_BREAKPOINT = 3;
