import { PatientInsurance } from '../types/patientInsurance';

export type PatientInsuranceFieldKeys = {
  [K in keyof PatientInsurance]: K;
};

export enum TimeUnits {
  ms = 'ms',
  s = 's',
  m = 'm',
  h = 'h',
  d = 'd',
  mm = 'mm',
  y = 'y'
}
