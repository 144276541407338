import { OrderBundle } from 'src/types/appointment';
import axiosApiInstance from '../utils/axios';
import { Clinic } from 'src/types/clinic';

export async function getClinicInfoById(): Promise<Clinic> {
  const response = await axiosApiInstance.get<Clinic>(`clinic/info`);
  return response.data;
}

export async function getClinicOrderBundles(): Promise<OrderBundle[]> {
  const response =
    await axiosApiInstance.get<OrderBundle[]>(`clinic/order-bundles`);
  return response.data;
}
