import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/system';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import Chips, { ChipsVariants } from 'src/components/data-entry/Chips/Chips';
import Flex from 'src/components/layout/Flex/Flex';
import { spacings, zIndices } from 'src/components/styles/constants';
import {
  MedicalQnACategory,
  MedicalQuestion,
  MedicalQuestionTypes,
  RecursiveMedicalAnswer
} from 'src/types/qna';
import useQnaApi from '../../../hooks/useQnaApi';
import { convertNumberIdToString } from 'src/utils/general';
import Loader from 'src/components/display/Loader';
import Typography from 'src/components/display/Typography';
import Box from 'src/components/layout/Box';
import { useTranslation } from 'react-i18next';
import { ServerStatus } from 'src/types/global';
import Switch from 'src/components/data-entry/Switch';
import DatePicker from 'src/components/data-entry/DatePicker';
import InputField from 'src/components/data-entry/InputField';
import InputLabel from 'src/components/data-entry/InputLabel';
import { transformQuestionSerializedIdToLabel } from '../utils/conversions';
import Select from 'src/components/data-entry/Select';
import { ToastType } from 'src/components/display/Toast/Toast';
import Button from 'src/components/display/Button';
import { dashSeparatedDateFormat } from 'src/utils/dateAndTIme';
import { useDialog, useToast } from 'src/contexts/UIContexts';

type QnAForm = {
  [key in any]: RecursiveMedicalAnswer;
};

const StyledLoadingContainer = styled(Flex)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${zIndices.high};
`;

// TODO: Remove this function once the backend is updated to return the correct type
// for single choice questions
const resolveSingleChoiceAnswerOptions = (
  type?:
    | MedicalQuestionTypes
    | Record<MedicalQuestionTypes.SINGLE_CHOICE, string[]>,
  answerOptions?: string[]
): { type: MedicalQuestionTypes; answerOptions?: string[] } => {
  if (type?.[MedicalQuestionTypes.SINGLE_CHOICE]) {
    return {
      type: MedicalQuestionTypes.SINGLE_CHOICE,
      answerOptions: answerOptions || type[MedicalQuestionTypes.SINGLE_CHOICE]
    };
  }

  return {
    type: type as MedicalQuestionTypes,
    answerOptions
  };
};

export const AddEditQnAForm: FC<{
  patientId: string;
  primaryPatientId?: string;
  category: MedicalQnACategory;
  question: MedicalQuestion;
  answerKey?: string;
}> = ({
  patientId,
  primaryPatientId,
  category: { questions },
  question,
  answerKey: answerKeyProp
}) => {
  const { t } = useTranslation();
  const { closeDialog } = useDialog();
  const { openToast } = useToast();
  const { postAnswerForQuestion } = useQnaApi();

  const [keyName, setKeyName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutate: onPostAnswerForQuestion } = postAnswerForQuestion();

  const {
    id: questionId,
    type: questionType,
    text,
    details,
    answerKey,
    answerOptions
  } = question || {};

  const answer = useMemo(
    () => questions?.find(({ question }) => question.id === questionId)?.answer,
    [questions, questionId]
  );

  const defaultAnswer =
    questionType === MedicalQuestionTypes.YES_NO ? 'No' : undefined;

  const { control, formState, handleSubmit, watch, reset, setValue } =
    useForm<QnAForm>({
      mode: 'onChange',
      defaultValues: {
        answer: !answerKey
          ? answer || defaultAnswer
          : {
              [answerKey]: answer?.[answerKey] || defaultAnswer
            }
      }
    });

  const { errors } = formState;

  const watchKeyName = watch('keyName') as string;
  const keyNameReplacement = text.replace(/\{\{\}\}/g, keyName);

  useEffect(() => {
    if (keyName === watchKeyName) return;
    setKeyName(watchKeyName);
  }, [watchKeyName]);

  useEffect(() => {
    const targetKeyName = keyName || answerKeyProp;
    if (!targetKeyName) return;

    reset({
      answer: {
        [targetKeyName]: answer?.[targetKeyName] || defaultAnswer
      },
      keyName: targetKeyName
    });
  }, [keyName, answerKeyProp]);

  const onSubmit = async ({ answer }: QnAForm) => {
    if (!answer) return;

    setIsLoading(true);

    await onPostAnswerForQuestion(
      {
        patientId,
        primaryPatientId,
        questionId,
        answer
      },
      {
        onSuccess: (data) => {
          if (
            data?.status === ServerStatus.OK ||
            data?.status === ServerStatus.SUCCESS
          ) {
            openToast({
              title: t('POST_ANSWER_FOR_QUESTION_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            closeDialog();
          } else {
            setIsLoading(false);
          }
        }
      }
    );
  };

  const isChipsType = [MedicalQuestionTypes.MULTI_CHOICE].includes(
    questionType
  );

  const isRepeatType = [
    MedicalQuestionTypes.REPEAT_TEMPLATE,
    MedicalQuestionTypes.REPEAT_NUMBER
  ].includes(questionType);

  const renderAnswerOptions = ({
    type,
    answerKey,
    fieldKey,
    answerOptions
  }: {
    type: MedicalQuestionTypes;
    answerKey?: string;
    fieldKey?: string;
    answerOptions?: string[];
  }): ReactNode => {
    const controllerName = `answer${
      answerKey && fieldKey ? `.${answerKey}.${fieldKey}` : ''
    }`;

    if (
      typeof type === 'object' &&
      type &&
      MedicalQuestionTypes.MULTI_CHOICE in type
    ) {
      if (Array.isArray(type[MedicalQuestionTypes.MULTI_CHOICE])) {
        answerOptions = type[MedicalQuestionTypes.MULTI_CHOICE];
        type = MedicalQuestionTypes.MULTI_CHOICE;
      }
    }

    switch (type) {
      case MedicalQuestionTypes.MULTI_CHOICE:
      case MedicalQuestionTypes.MULTI_FREE_TEXT:
        return (
          <Controller
            name={controllerName}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Chips
                variant={ChipsVariants.EXPANDED}
                error={!!errors?.answer?.message}
                helperText={errors?.answer?.message}
                value={
                  typeof value === 'string'
                    ? [value]
                    : Array.isArray(value)
                      ? value
                      : []
                }
                allowAddingNewOptions={
                  type === MedicalQuestionTypes.MULTI_FREE_TEXT
                }
                options={
                  answerOptions?.map((answerOption, idx) => ({
                    id: convertNumberIdToString(idx),
                    value: answerOption
                  })) || []
                }
                onAddChip={(newChip) =>
                  onChange(
                    Array.isArray(value)
                      ? [...value, newChip.trim()]
                      : [newChip.trim()]
                  )
                }
                onRemoveChip={(_, index) =>
                  onChange(
                    Array.isArray(value)
                      ? value
                          .filter((_, currIndex) => currIndex !== index)
                          .map((item) => item.trim())
                      : []
                  )
                }
              />
            )}
          />
        );
      case MedicalQuestionTypes.SINGLE_CHOICE:
        return (
          <Controller
            name={controllerName}
            control={control}
            rules={{ required: t('ANSWER_IS_REQUIRED') }}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                onChange={onChange}
                error={!!errors?.answer?.message}
                helperText={errors?.answer?.message}
                options={
                  answerOptions?.map((answerOption) => ({
                    value: answerOption,
                    label: answerOption
                  })) || []
                }
              />
            )}
          />
        );
      case MedicalQuestionTypes.FREE_TEXT:
      case MedicalQuestionTypes.NUMBER:
        return (
          <Controller
            name={controllerName}
            control={control}
            render={({ field: { value, onChange } }) => (
              <InputField
                value={value}
                type={type === MedicalQuestionTypes.NUMBER ? 'number' : 'text'}
                onChange={onChange}
                onBlur={(ev) => {
                  ev.currentTarget.value = ev.currentTarget.value.trim();

                  onChange(ev);
                }}
                error={!!errors?.answer?.message}
                helperText={errors?.answer?.message}
                fullWidth
              />
            )}
          />
        );
      case MedicalQuestionTypes.YES_NO:
        return (
          <Controller
            name={controllerName}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Switch
                switchOnText={t('YES')}
                switchOffText={t('NO')}
                checked={value === 'Yes'}
                onChange={(_, checked) => onChange(checked ? 'Yes' : 'No')}
              />
            )}
          />
        );
      case MedicalQuestionTypes.DATE:
        return (
          <Controller
            name={controllerName}
            control={control}
            render={({ field: { value, onChange } }) => (
              <DatePicker
                value={dayjs(value as string).toDate()}
                onChange={(date) =>
                  onChange(dayjs(date).format(dashSeparatedDateFormat))
                }
                error={!!errors?.answer?.message}
                helperText={errors?.answer?.message}
              />
            )}
          />
        );
      default:
        return null;
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Flex
        width="550px"
        minHeight="320px"
        flexDirection="column"
        gap={spacings.large}
      >
        <Flex flexDirection="column" flexGrow={1} position="relative">
          {isLoading && (
            <StyledLoadingContainer>
              <Loader />
            </StyledLoadingContainer>
          )}
          <Flex
            marginY={spacings.xlarge}
            width="100%"
            flexDirection="column"
            gap={spacings.large}
            sx={{
              opacity: isLoading ? 0.5 : 1,
              pointerEvents: isLoading ? 'none' : 'auto'
            }}
          >
            <Typography variant="h3">
              {keyName ? keyNameReplacement : text}
            </Typography>
            <Flex flexDirection="row" flexWrap="wrap" gap={spacings.medium}>
              <Flex width="100%" flexDirection="column" gap={spacings.xlarge}>
                {isRepeatType && (
                  <Box>
                    <Controller
                      name="keyName"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <InputField
                          value={value}
                          onChange={onChange}
                          onBlur={(ev) => {
                            ev.currentTarget.value =
                              ev.currentTarget.value.trim();

                            onChange(ev);
                          }}
                          error={!!errors?.answer?.message}
                          helperText={errors?.answer?.message}
                          fullWidth
                        />
                      )}
                    />
                  </Box>
                )}
                {isRepeatType
                  ? keyName &&
                    Object.entries(details || {}).map(([key, value], idx) => (
                      <Box key={`answer-${keyName}-${key}-${idx}`}>
                        <InputLabel
                          label={transformQuestionSerializedIdToLabel(key)}
                        />

                        {renderAnswerOptions({
                          answerKey: keyName,
                          fieldKey: key,
                          ...resolveSingleChoiceAnswerOptions(
                            value,
                            answerOptions
                          )
                        })}
                      </Box>
                    ))
                  : renderAnswerOptions(
                      resolveSingleChoiceAnswerOptions(
                        questionType,
                        answerOptions
                      )
                    )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex justifyContent="space-between">
          <Box>
            {isChipsType && (
              <Button onClick={() => setValue('answer', [])}>
                {t('DESELECT_ALL')}
              </Button>
            )}
          </Box>
          <Button
            onClick={handleSubmit(onSubmit)}
            disabled={!formState.isValid || isLoading}
          >
            {t('SAVE')}
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};
