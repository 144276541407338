import { FC, useEffect } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  useFieldArray,
  useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { CopyAllOutlined } from '@mui/icons-material';
import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import Flex from 'src/components/layout/Flex/Flex';
import Box from 'src/components/layout/Box/Box';
import Button from 'src/components/display/Button/Button';
import MiniIconButton from 'src/components/display/MiniIconButton/MiniIconButton';
import { spacings } from 'src/components/styles/constants';
import Typography from 'src/components/display/Typography/Typography';
import InputField from 'src/components/data-entry/InputField/InputField';
import DatePicker from 'src/components/data-entry/DatePicker/DatePicker';
import Select from 'src/components/data-entry/Select/Select';
import Loader from 'src/components/display/Loader/Loader';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { getFullName } from 'src/utils/general';
import {
  AppointmentWithOrderDocumentPayload,
  CycleWizardVariant,
  NewCycleFormValues,
  ProtocolPhases
} from 'src/types/cycle';
import {
  MedicalProtocolForm,
  Prescription,
  MedicationUnits,
  MedicationIntakeMethods,
  OtherInstructions,
  FrequencyOptions
} from 'src/types/prescription';
import DateRangePicker from 'src/components/data-entry/DateRangePicker';
import { Range } from 'react-date-range';
import TimePicker from '../../../components/data-entry/TimePicker';
import { getPrescriptionTimes } from '../utils/prescription-times';
import useCycleAppointmentsOrders from 'src/hooks/useCycleAppointmentsOrders';
import Autocomplete from 'src/components/data-entry/Autocomplete';
import { DoctorChips } from '../common/DoctorChips';
import useStaffMembers from 'src/hooks/useStaffMembers';
import { FlexGridItem } from './ConfirmPrescriptionForm';

const getEmptyMedicationProtocol = (startDate?: Date): Prescription => ({
  medicationId: null,
  protocolPhase: '',
  startDate: dayjs(startDate).toDate(),
  endDate: dayjs(startDate).add(1, 'day').toDate(),
  dosage: null,
  timesPerDay: null,
  unit: MedicationUnits.AMPUL,
  frequency: FrequencyOptions.TAKE_DAILY,
  route: MedicationIntakeMethods.A_SMALL_AMOUNT,
  otherInstructions: null,
  timeRange: {
    startDate: dayjs(startDate).toDate(),
    endDate: dayjs(startDate).add(1, 'day').toDate(),
    key: 'selection'
  },
  time: []
});

export const StyledForm = styled('form')`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

interface NestedTimeFieldsProps {
  index: number;
  control: Control<MedicalProtocolForm>;
  errors: FieldErrors<MedicalProtocolForm>;
}

const NestedTimeFields: FC<NestedTimeFieldsProps> = ({
  control,
  index,
  errors
}) => {
  const { t } = useTranslation();
  const { fields } = useFieldArray({
    control,
    name: `medicationProtocols.${index}.time` as 'medicationProtocols'
  });

  return (
    <>
      {fields.map((_, timeIndex) => (
        <FlexGridItem
          span={3}
          columns={11}
          key={`medication-${index}-time-${timeIndex}`}
        >
          <Controller
            name={`medicationProtocols.${index}.time.${timeIndex}`}
            control={control}
            rules={{
              required: t('TIME_REQUIRED')
            }}
            render={({ field: { ref, ...field } }) => {
              return (
                <TimePicker
                  {...field}
                  label={t('TAKE_AT').toUpperCase()}
                  inputRef={ref}
                  ampm={false}
                  error={
                    !!errors.medicationProtocols?.[index]?.time?.[timeIndex]
                  }
                  helperText={
                    errors.medicationProtocols?.[index]?.time?.[timeIndex]
                      .message
                  }
                  fullWidth
                  required
                />
              );
            }}
          />
        </FlexGridItem>
      ))}
    </>
  );
};

export interface SubmitMedicationProtocolFormArgs {
  medicationsProtocolForm: MedicalProtocolForm;
  appointmentsWithOrderDocumentPayload: AppointmentWithOrderDocumentPayload[];
}

interface MedicationProtocolFormProps {
  patientId: string;
  hideCycleDayOne?: boolean;
  handleSubmitForm: ({
    medicationsProtocolForm,
    appointmentsWithOrderDocumentPayload
  }: SubmitMedicationProtocolFormArgs) => void;
  cycleData: NewCycleFormValues;
  variant?: CycleWizardVariant;
  handleClickBack?: () => void;
}

const MedicationProtocolForm: FC<MedicationProtocolFormProps> = ({
  patientId,
  cycleData,
  handleSubmitForm,
  hideCycleDayOne,
  variant,
  handleClickBack
}) => {
  const { t } = useTranslation();
  const { getPatientById, getMedications } = usePatientsApi();
  const { getStaffMemberById } = useStaffMembers();

  const isActivateCycle = variant === CycleWizardVariant.ACTIVATE_CYCLE;

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);

  const { data: medicationList, isLoading: isLoadingMedications } =
    getMedications();

  const primaryPhysicianId = patient?.primaryPhysician;

  const getDefaultMedicationProtocols = (): Prescription[] => {
    if (!cycleData?.medicationProtocols?.length && !isActivateCycle)
      return [getEmptyMedicationProtocol(cycleData.startDate)];

    return (
      cycleData?.medicationProtocols?.map(
        ({ startDate, endDate, ...rest }) => ({
          ...rest,
          startDate,
          endDate,
          timeRange: {
            startDate: dayjs(startDate).toDate(),
            endDate: dayjs(endDate).toDate(),
            key: 'selection'
          }
        })
      ) || []
    );
  };

  const {
    data: patientPrimaryStaffMember,
    isLoading: isLoadingPatientPrimaryStaffMember
  } = getStaffMemberById(primaryPhysicianId, {
    enabled: !!primaryPhysicianId
  });

  const defaultValues: MedicalProtocolForm = {
    patientName: '',
    prescribingProvider: cycleData.prescribingProvider,
    cycleDayOne: cycleData?.cycleDayOne || dayjs().toDate(),
    medicationProtocols: getDefaultMedicationProtocols(),
    medicationsToDelete: []
  };

  const { control, formState, handleSubmit, watch, setValue, getValues } =
    useForm<MedicalProtocolForm>({
      mode: 'onChange',
      defaultValues
    });
  const {
    fields: defaultMedicationProtocols,
    append,
    remove,
    insert
  } = useFieldArray({
    control,
    name: 'medicationProtocols'
  });

  const hasOrders = cycleData?.appointments?.some(
    (appointment) => appointment?.panelIds?.length
  );

  const {
    appointmentsWithOrderDocumentPayload,
    isLoading: isLoadingAppointmentsWithOrderDocumentPayload
  } =
    useCycleAppointmentsOrders({
      appointments: cycleData?.appointments,
      patientId,
      isEnabled: hasOrders
    }) || {};

  const { medicationProtocols } = watch();
  const { errors } = formState;

  const onSubmit = async (details: MedicalProtocolForm) => {
    handleSubmitForm({
      medicationsProtocolForm: details,
      appointmentsWithOrderDocumentPayload
    });
  };

  const handleDateRangePicker = ({
    ranges,
    index,
    onChange
  }: {
    ranges: Range;
    index: number;
    onChange: (...event: any[]) => void;
  }) => {
    const { endDate, startDate } = ranges;

    const medication = getValues(`medicationProtocols.${index}`);

    setValue(`medicationProtocols.${index}`, {
      ...medication,
      startDate,
      endDate,
      timeRange: {
        ...medication.timeRange,
        startDate,
        endDate
      }
    });

    onChange(ranges);
  };

  useEffect(() => {
    if (!cycleData.prescribingProvider) {
      if (!patientPrimaryStaffMember) return;

      setValue('prescribingProvider', patientPrimaryStaffMember.id);
    }
  }, [patientPrimaryStaffMember]);

  useEffect(() => {
    if (!patient) return;
    setValue('patientName', getFullName(patient.personalInfo));
  }, [patient]);

  if (
    isLoadingPatient ||
    isLoadingMedications ||
    isLoadingPatientPrimaryStaffMember
  ) {
    return <Loader />;
  }

  return (
    <Box marginTop={spacings.xlarge} height="100%">
      <StyledForm noValidate onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Flex gap={spacings.x2large} marginBottom={spacings.x3large}>
            <FlexGridItem span={4} columns={11}>
              <Controller
                name="patientName"
                control={control}
                rules={{
                  required: t('PATIENT_NAME_REQUIRED')
                }}
                render={({ field: { ref, ...field } }) => (
                  <InputField
                    {...field}
                    inputRef={ref}
                    label={t('PATIENT_NAME_LABEL')}
                    placeholder={t('PATIENT_NAME_PLACEHOLDER')}
                    error={!!errors.patientName}
                    helperText={errors?.patientName?.message}
                    required
                    disabled
                    fullWidth
                  />
                )}
              />
            </FlexGridItem>
            <Box>
              <Controller
                name="prescribingProvider"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DoctorChips
                    id="edit-prescribing-provider"
                    showSelectedValue
                    label={t('PRESCRIBING_PROVIDER').toUpperCase()}
                    value={value ? [value] : [cycleData.prescribingProvider]}
                    onAddChip={(newSelectedDoctorId) =>
                      onChange(newSelectedDoctorId)
                    }
                  />
                )}
              />
            </Box>
            <Box>
              {!hideCycleDayOne && (
                <Controller
                  name="cycleDayOne"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <DatePicker
                      {...field}
                      disabled
                      inputRef={ref}
                      label={t('CYCLE_DAY_1_LABEL').toUpperCase()}
                      error={!!errors.cycleDayOne}
                      helperText={errors?.cycleDayOne?.message}
                      fullWidth
                    />
                  )}
                />
              )}
            </Box>
          </Flex>
          {defaultMedicationProtocols.map((medication, index) => (
            <Flex key={medication.id} marginBottom={spacings.x3large}>
              <Flex flexDirection="column" alignSelf="flex-start">
                <Flex
                  gap={spacings.small}
                  marginRight={spacings.medium}
                  flex={1}
                  marginY={spacings.medium}
                  paddingTop={spacings.x2large}
                >
                  {!isActivateCycle && (
                    <MiniIconButton
                      icon={<CopyAllOutlined />}
                      onClick={() => {
                        insert(index + 1, medicationProtocols[index]);
                      }}
                    />
                  )}
                  <MiniIconButton
                    icon={<RemoveIcon />}
                    onClick={() => {
                      const medicationToRemove = medicationProtocols[index];
                      if (medicationToRemove.id) {
                        setValue('medicationsToDelete', [
                          ...getValues('medicationsToDelete'),
                          medicationToRemove.id
                        ]);
                      }
                      remove(index);
                    }}
                  />
                </Flex>
              </Flex>
              <Flex flexDirection="column" gap={spacings.x2large}>
                <Flex gap={spacings.large}>
                  <FlexGridItem span={3} columns={11}>
                    <Controller
                      name={`medicationProtocols.${index}.medicationId`}
                      control={control}
                      rules={{
                        required: t('MEDICATION_TYPE_REQUIRED')
                      }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disabled={isActivateCycle}
                          options={medicationList?.map(({ id }) => `${id}`)}
                          shouldSortOptions
                          getOptionLabel={(option) => {
                            const medication = medicationList?.find(
                              ({ id }) => id === option
                            );
                            return medication?.name;
                          }}
                          onChange={(_, value: string) => {
                            setValue(
                              `medicationProtocols.${index}.medicationId`,
                              value
                            );
                            setValue(
                              `medicationProtocols.${index}.medicationName`,
                              medicationList?.find(({ id }) => id === value)
                                ?.name
                            );
                          }}
                          renderInput={(params) => (
                            <InputField
                              {...params}
                              label={t('MEDICATION_NAME_LABEL')}
                              error={
                                !!errors.medicationProtocols?.[index]
                                  ?.medicationId
                              }
                              helperText={
                                errors.medicationProtocols?.[index]
                                  ?.medicationId?.message
                              }
                              fullWidth
                              required
                            />
                          )}
                        />
                      )}
                    />
                  </FlexGridItem>
                  <FlexGridItem span={2} columns={11}>
                    <Controller
                      name={`medicationProtocols.${index}.protocolPhase`}
                      control={control}
                      rules={{
                        required: t('PROTOCOL_PHASE_REQUIRED')
                      }}
                      render={({ field: { ref, ...field } }) => (
                        <Select
                          {...field}
                          inputRef={ref}
                          label={t('PROTOCOL_PHASE')}
                          error={
                            !!errors.medicationProtocols?.[index]?.protocolPhase
                          }
                          helperText={
                            errors.medicationProtocols?.[index]?.protocolPhase
                              ?.message
                          }
                          initialDisabled
                          defaultOption={t('PROTOCOL_PHASE_DEFAULT')}
                          options={Object.entries(ProtocolPhases).map(
                            ([label, value]) => ({
                              label: t(label),
                              value
                            })
                          )}
                          fullWidth
                          required
                        />
                      )}
                    />
                  </FlexGridItem>
                  <FlexGridItem span={1} columns={11}>
                    <Controller
                      name={`medicationProtocols.${index}.dosage`}
                      control={control}
                      rules={{
                        required: t('DOSAGE_REQUIRED'),
                        min: { value: 0, message: t('DOSAGE_VALIDATION_MSG') }
                      }}
                      render={({ field: { ref, ...field } }) => (
                        <InputField
                          {...field}
                          inputRef={ref}
                          InputProps={{ inputProps: { min: 0 } }}
                          label={t('DOSAGE_LABEL')}
                          type="number"
                          placeholder="#"
                          error={!!errors.medicationProtocols?.[index]?.dosage}
                          helperText={
                            errors?.medicationProtocols?.[index]?.dosage
                              ?.message
                          }
                          onChange={(ev) => {
                            const { value } = ev.currentTarget;
                            if (parseFloat(value) < 0) return;
                            setValue(
                              `medicationProtocols.${index}.dosage`,
                              parseFloat(value)
                            );
                          }}
                          required
                        />
                      )}
                    />
                  </FlexGridItem>
                  <FlexGridItem span={1} columns={11}>
                    <Controller
                      name={`medicationProtocols.${index}.unit`}
                      control={control}
                      rules={{
                        required: 'Please choose a unit',
                        min: { value: 0, message: t('UNIT_VALIDATION_MSG') }
                      }}
                      render={({ field: { ref, ...field } }) => (
                        <Select
                          {...field}
                          inputRef={ref}
                          label={t('UNIT_LABEL')}
                          error={!!errors.medicationProtocols?.[index]?.unit}
                          helperText={
                            errors.medicationProtocols?.[index]?.unit?.message
                          }
                          options={Object.entries(MedicationUnits).map(
                            ([label, value]) => ({
                              label: t(`MEDICATION_UNIT_${label}`),
                              value
                            })
                          )}
                          required
                        />
                      )}
                    />
                  </FlexGridItem>
                  <FlexGridItem columns={11}>
                    <Controller
                      name={`medicationProtocols.${index}.route`}
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <Select
                          {...field}
                          inputRef={ref}
                          label={t('ROUTE').toUpperCase()}
                          error={!!errors.medicationProtocols?.[index]?.route}
                          helperText={
                            errors.medicationProtocols?.[index]?.route?.message
                          }
                          options={Object.entries(MedicationIntakeMethods).map(
                            ([label, value]) => ({
                              label: t(`MEDICATION_INTAKE_METHOD_${label}`),
                              value
                            })
                          )}
                          required
                        />
                      )}
                    />
                  </FlexGridItem>
                  <FlexGridItem columns={11}>
                    <Controller
                      name={`medicationProtocols.${index}.otherInstructions`}
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <Select
                          {...field}
                          inputRef={ref}
                          label={t('OTHER_INSTRUCTIONS').toUpperCase()}
                          error={
                            !!errors.medicationProtocols?.[index]
                              ?.otherInstructions
                          }
                          helperText={
                            errors.medicationProtocols?.[index]
                              ?.otherInstructions?.message
                          }
                          options={Object.entries(OtherInstructions).map(
                            ([label, value]) => ({
                              label: t(`OTHER_INSTRUCTIONS_${label}`),
                              value
                            })
                          )}
                          initialDisabled
                          defaultOption={t('OTHER_INSTRUCTIONS')}
                        />
                      )}
                    />
                  </FlexGridItem>
                </Flex>
                <Flex gap={spacings.large}>
                  <FlexGridItem span={3} columns={11}>
                    <Controller
                      name={`medicationProtocols.${index}.timeRange`}
                      control={control}
                      rules={{
                        required: t('DATES_REQUIRED')
                      }}
                      render={({
                        field: { ref: _ref, value, onChange, name }
                      }) => (
                        <DateRangePicker
                          label={t('DATES')}
                          key={name}
                          ranges={value}
                          onChange={(ranges) =>
                            handleDateRangePicker({ ranges, index, onChange })
                          }
                          required
                        />
                      )}
                    />
                  </FlexGridItem>
                  <FlexGridItem columns={11}>
                    <Controller
                      name={`medicationProtocols.${index}.frequency`}
                      control={control}
                      rules={{
                        required: t('FREQUENCY_REQUIRED')
                      }}
                      render={({ field: { ref, ...field } }) => (
                        <Select
                          {...field}
                          inputRef={ref}
                          label={t('FREQUENCY').toUpperCase()}
                          error={
                            !!errors.medicationProtocols?.[index]?.frequency
                          }
                          helperText={
                            errors.medicationProtocols?.[index]?.frequency
                              ?.message
                          }
                          initialDisabled
                          options={Object.entries(FrequencyOptions).map(
                            ([label, value]) => ({
                              label: t(`FREQUENCY_OPTION_${label}`),
                              value
                            })
                          )}
                          fullWidth
                          required
                        />
                      )}
                    />
                  </FlexGridItem>
                  {!cycleData.isHistorical && (
                    <Flex
                      alignItems="flex-start"
                      gap={spacings.large}
                      width="100%"
                    >
                      <FlexGridItem columns={11}>
                        <Controller
                          name={`medicationProtocols.${index}.timesPerDay`}
                          control={control}
                          rules={{
                            required: t('TIMES_PER_DAY_REQUIRED')
                          }}
                          render={({ field: { ref, ...field } }) => (
                            <InputField
                              {...field}
                              inputRef={ref}
                              InputProps={{ inputProps: { min: 0 } }}
                              type="number"
                              label={t('TIMES_PER_DAY_LABEL')}
                              placeholder={t('TIMES_PER_DAY_PLACEHOLDER')}
                              error={
                                !!errors.medicationProtocols?.[index]
                                  ?.timesPerDay
                              }
                              helperText={
                                errors.medicationProtocols?.[index]?.timesPerDay
                                  ?.message
                              }
                              onChange={async (ev) => {
                                const { value } = ev.currentTarget;
                                const timesPerDayValue = parseFloat(value);
                                setValue(
                                  `medicationProtocols.${index}.timesPerDay`,
                                  timesPerDayValue
                                );
                                await setValue(
                                  `medicationProtocols.${index}.time`,
                                  []
                                );
                                setValue(
                                  `medicationProtocols.${index}.time`,
                                  getPrescriptionTimes(timesPerDayValue)
                                );
                              }}
                              fullWidth
                              required
                            />
                          )}
                        />
                      </FlexGridItem>
                      <NestedTimeFields
                        index={index}
                        control={control}
                        errors={errors}
                      />
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Flex>
          ))}
          {variant !== CycleWizardVariant.ACTIVATE_CYCLE && (
            <Flex
              alignItems="center"
              marginTop={spacings.xlarge}
              gap={spacings.x2large}
            >
              <MiniIconButton
                icon={<AddIcon />}
                onClick={() =>
                  append(getEmptyMedicationProtocol(cycleData.startDate))
                }
              />
              <Typography variant="body1">{t('ADD_MEDICATION')}</Typography>
            </Flex>
          )}
        </Box>
        <Flex mt="20px" justifyContent="space-between">
          <Box width="20%">
            {handleClickBack && (
              <Button
                type="button"
                bgColor="alto"
                textColor="darkGray"
                fullWidth
                onClick={handleClickBack}
              >
                {t('BACK')}
              </Button>
            )}
          </Box>
          <Box width="20%">
            <Button
              fullWidth
              type="submit"
              disabled={isLoadingAppointmentsWithOrderDocumentPayload}
            >
              {t('CONFIRM_PROTOCOL')}
            </Button>
          </Box>
        </Flex>
      </StyledForm>
    </Box>
  );
};

export default MedicationProtocolForm;
