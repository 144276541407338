export const ACCOUNT = 'account';
export const APP = 'app';
export const ADMIN = 'admin';
export const DASHBOARD = 'dashboard';
export const CALENDAR = 'calendar';
export const INBOX = 'inbox';
export const KPIS = 'kpis';
export const PATIENTS = 'patients';
export const ADD = 'add';
export const PATIENT_ID = ':patientId';
export const EDIT = 'edit';
export const CONFIRM_EMAIL_RECEIVED = 'confirm-email-received';
export const CURRENT_TREATMENT = 'current-treatment';
export const CYCLE_ID = ':cycleId';
export const CHECK_LISTS = 'checklists';
export const MEDICAL_HISTORY = 'medical-history';
export const MESSAGING = 'messaging';
export const DETAILS = 'details';
export const DOCUMENTS = 'documents';
export const FORMS = 'forms';
export const FEED = 'feed';
export const STATISTICS = 'statistics';
export const TEMPLATES_EDITOR = 'templates-editor';
export const BILLING_SERVICES = 'billing-services';
export const BILLING = 'billing';
export const INVOICE_ID = ':invoiceId';
export const PERMISSIONS = 'permissions';
export const CLINIC_FEED = 'clinic-feed';
export const CLINIC_SETTINGS = 'clinic-settings';
export const TAB = ':tab';
export const DATA_EXPORT = 'data-export';
export const STAFF = 'staff';
export const STAFF_ID = ':staffId';
export const CRYO = 'cryo';

export const patientRoutes = {
  getPatientsLink: () => `/${APP}/${PATIENTS}`,
  getAddLink: () => `/${APP}/${PATIENTS}/${ADD}`,
  getByIdLink: (patientId: string) => `/${APP}/${PATIENTS}/${patientId}`,
  getEditLink: (patientId: string) =>
    `/${APP}/${PATIENTS}/${patientId}/${EDIT}`,
  getConfirmationEmailLink: (patientId: string) =>
    `/${APP}/${PATIENTS}/${patientId}/${CONFIRM_EMAIL_RECEIVED}`,
  getCurrentTreatmentLink: (patientId: string) =>
    `/${APP}/${PATIENTS}/${patientId}/${CURRENT_TREATMENT}`,
  getCurrentTreatmentByCycleLink: (patientId: string, cycleId: string) =>
    `/${APP}/${PATIENTS}/${patientId}/${CURRENT_TREATMENT}/${cycleId}`,
  getChecklistsLink: (patientId: string) =>
    `/${APP}/${PATIENTS}/${patientId}/${CHECK_LISTS}`,
  getMedicalHistoryLink: (patientId: string) =>
    `/${APP}/${PATIENTS}/${patientId}/${MEDICAL_HISTORY}`,
  getMessagingLink: (patientId: string) =>
    `/${APP}/${PATIENTS}/${patientId}/${MESSAGING}`,
  getDetailsLink: (patientId: string) =>
    `/${APP}/${PATIENTS}/${patientId}/${DETAILS}`,
  getBillingLink: (patientId: string) =>
    `/${APP}/${PATIENTS}/${patientId}/${BILLING}`,
  getEditInvoiceLink: (patientId: string, invoiceId: string) =>
    `/${APP}/${PATIENTS}/${patientId}/${BILLING}/${invoiceId}`,
  getAddInvoiceLink: (patientId: string) =>
    `/${APP}/${PATIENTS}/${patientId}/${BILLING}/${ADD}`,
  getDocumentsLink: (patientId: string, searchParams: string) =>
    `/${APP}/${PATIENTS}/${patientId}/${DOCUMENTS}?${searchParams}`,
  getFormsLink: (patientId: string) =>
    `/${APP}/${PATIENTS}/${patientId}/${FORMS}`,
  getFeedLink: (patientId: string) =>
    `/${APP}/${PATIENTS}/${patientId}/${FEED}`,
  getGameteAndCryoLink: (patientId: string) =>
    `/${APP}/${PATIENTS}/${patientId}/${CRYO}`
};

export const getDashboardLink = () => `/${APP}/${DASHBOARD}`;
export const getClinicFeedLink = () => `/${APP}/${CLINIC_FEED}`;
export const getCalendarLink = () => `/${APP}/${CALENDAR}`;
export const getInboxLink = () => `/${APP}/${INBOX}`;
export const getKpisLink = () => `/${APP}/${KPIS}`;
export const getBillingLink = () => `/${APP}/${BILLING}`;
