import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import Box from 'src/components/layout/Box/Box';
import { spacings } from 'src/components/styles/constants';
import Flex from 'src/components/layout/Flex/Flex';
import Typography from 'src/components/display/Typography/Typography';
import { fontWeights } from 'src/components/styles/fonts';
import { Colors } from 'src/components/styles/colors';
import MiniIconButton from 'src/components/display/MiniIconButton/MiniIconButton';
import { EditNextOfKinInfo } from './EditNextOfKinInfo';
import { styled } from '@mui/system';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { usePopover } from 'src/contexts/UIContexts';

const StyledEditIcon = styled(EditIcon)`
  fill: ${Colors.black};
`;

const RowContainer: FC<{ title: string; value: string | boolean }> = ({
  title,
  value
}) => {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Typography>{title}</Typography>
      <Typography>{value}</Typography>
    </Flex>
  );
};

export const PatientNextOfKinCard: FC<{
  patientId: string;
}> = ({ patientId }) => {
  const { t } = useTranslation();
  const { openPopover } = usePopover();
  const { getPatientById } = usePatientsApi();

  const { data: patient } = getPatientById(patientId);

  const {
    nextOfKinAddress,
    nextOfKinEmail,
    nextOfKinName,
    nextOfKinPhoneNumber,
    nextOfKinRelationship
  } = patient?.nextOfKin || {};

  return (
    <Flex flexDirection="column">
      <Flex flex={1} justifyContent="space-between" alignItems="center">
        <Typography fontWeight={fontWeights.extraBold}>
          {t('NEXT_OF_KIN').toUpperCase()}
        </Typography>
        <MiniIconButton
          icon={
            <StyledEditIcon
              onClick={(ev) =>
                openPopover({
                  anchorEl: ev.currentTarget,
                  header: t('EDIT_NEXT_OF_KIN_INFO'),
                  children: (
                    <EditNextOfKinInfo
                      patientId={patient?.personalInfo?.id}
                      nextOfKinInfo={patient?.nextOfKin}
                    />
                  ),
                  paperPadding: spacings.large,
                  transformOrigin: {
                    horizontal: 'center',
                    vertical: 'top'
                  },
                  anchorOrigin: {
                    horizontal: 'center',
                    vertical: 'bottom'
                  }
                })
              }
            />
          }
        />
      </Flex>
      <Box
        borderBottom={`1px solid ${Colors.alto2}`}
        width="100%"
        marginY={spacings.medium}
      />
      <Flex flexDirection="column" gap={spacings.small}>
        <RowContainer title={t('NAME')} value={nextOfKinName} />
        <RowContainer title={t('RELATION')} value={nextOfKinRelationship} />
        <RowContainer title={t('PHONE')} value={nextOfKinPhoneNumber} />
        <RowContainer title={t('EMAIL')} value={nextOfKinEmail} />
        <RowContainer title={t('ADDRESS')} value={nextOfKinAddress} />
      </Flex>
    </Flex>
  );
};
