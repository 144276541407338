import { OptionExtended } from 'src/types/option';
import { MultiSelectOption } from 'src/components/data-entry/MultiSelect/MultiSelect';
import i18n from 'src/i18n/i18n';
import {
  billToEntries,
  OrderBundle,
  orderPurposeEntries,
  urgencyEntries
} from 'src/types/appointment';
import { BillingServiceCode } from 'src/types/codes';
import { yesOrNoEntries } from 'src/types/global';
import { OrderVendor } from 'src/types/hl7messages';

export const billToOptions = billToEntries.map(([key, value]) => ({
  label: i18n.t(key),
  value: value
}));

export const urgencyOptions = urgencyEntries.map(([key, value]) => ({
  label: i18n.t(key),
  value: value
}));

export const yesNoOptions = yesOrNoEntries.map(([label, value]) => ({
  label: i18n.t(label),
  value
}));

export const orderPurposeOptions = orderPurposeEntries.map(([key, value]) => ({
  label: i18n.t(key),
  value: value
}));

export const procedureItemsOptions = (
  procedureItems: BillingServiceCode[]
): OptionExtended[] =>
  procedureItems?.map(({ id, serviceDescription }) => ({
    label: serviceDescription,
    value: id,
    labelText: serviceDescription
  })) || [];

export const vendorsOptions = (vendors: OrderVendor[]): OptionExtended[] =>
  vendors?.map(({ name, id }) => ({
    label: name,
    value: id,
    labelText: name
  })) || [];

export const labItemsOptions = (
  labs: BillingServiceCode[]
): OptionExtended[] => {
  return (
    labs?.map(({ id, serviceDescription }) => ({
      label: serviceDescription,
      value: id,
      labelText: serviceDescription
    })) || []
  );
};

export const bundleOptions = (
  bundlesWithIds: OrderBundle[]
): MultiSelectOption[] => {
  return bundlesWithIds?.map((bundle) => ({
    label: bundle.bundleName,
    value: bundle.bundleName
  }));
};
