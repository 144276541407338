import { FC } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SvgIcon, styled, SxProps } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { t } from 'i18next';

import ButtonsSideBar from 'src/components/display/ButtonsSideBar/ButtonsSideBar';
import IconButton, {
  IconButtonProps
} from 'src/components/display/IconButton/IconButton';
import { radii, spacings } from 'src/components/styles/constants';
import { Colors } from 'src/components/styles/colors';
import Box from 'src/components/layout/Box/Box';
import { TooltipWrapper } from 'src/components/display/Tooltip';
import { patientRoutes } from 'src/router/routes';
import { usePopover } from 'src/contexts/UIContexts';
import {
  useMessenger,
  useTelehealth
} from 'src/contexts/AppContexts/AppContexts';
import { PatientActionsMenu } from '../actionMenu/PatientActionsMenu';
import { ContactInfo } from './ContactInfo';
import { ReactComponent as ProfileIcon } from '../../../assets/icons/profile.svg';
import { ReactComponent as ChatIcon } from '../../../assets/icons/chat-message.svg';
import { ReactComponent as VideoIcon } from '../../../assets/icons/video.svg';
import { ReactComponent as MedicalHistoryIcon } from '../../../assets/icons/medical-history.svg';
import { ReactComponent as DocumentIcon } from '../../../assets/icons/document.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check 1.svg';
import { ReactComponent as ContactIcon } from '../../../assets/icons/contact-info.svg';
import { ReactComponent as HeartRateIcon } from '../../../assets/icons/heart-rate.svg';
import { ReactComponent as CycleIcon } from '../../../assets/icons/cycle.svg';
import { ReactComponent as EmbryoIcon } from '../../../assets/icons/embryo_icon.svg';

const ButtonContainer = styled(Box)`
  &&.active {
    background-color: ${Colors.white};
  }

  height: 100%;
  padding-block: ${spacings.small};
  padding-inline-end: ${spacings.medium};
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-radius: ${radii.full};
`;

interface SidebarButton extends IconButtonProps {
  path?: string;
  title?: string;
  pathMatchType?: 'exact' | 'includes';
  hide?: boolean;
}

interface PatientButtonsSideBarProps {
  sx?: SxProps;
}

export const PatientButtonsSideBar: FC<PatientButtonsSideBarProps> = ({
  sx
}) => {
  const navigate = useNavigate();
  const { patientId } = useParams();
  const { openPopover } = usePopover();
  const { pathname } = useLocation();
  const { isMessengerOpen, setIsMessengerOpen } = useMessenger();
  const { setIsTelehealthOpen } = useTelehealth();

  const buttons: SidebarButton[] = [
    {
      icon: <AddIcon />,
      title: t('NAVIGATION_QUICK_ADD'),
      onClick: (ev) => {
        openPopover({
          children: <PatientActionsMenu patientId={patientId} />,
          anchorEl: ev.currentTarget,
          hideToolbar: true
        });
      }
    },
    {
      title: t('NAVIGATION_OVERVIEW'),
      icon: (
        <SvgIcon>
          <ProfileIcon />
        </SvgIcon>
      ),
      bgColor: 'whiteSand',
      path: patientRoutes.getByIdLink(patientId),
      pathMatchType: 'exact'
    },
    {
      title: t('ACTIVITY_FEED'),
      icon: (
        <SvgIcon>
          <HeartRateIcon color={Colors.black} />
        </SvgIcon>
      ),
      bgColor: 'blushPink',
      path: patientRoutes.getFeedLink(patientId),
      pathMatchType: 'exact'
    },
    {
      title: t('NAVIGATION_MESSAGES'),
      icon: (
        <SvgIcon>
          <ChatIcon />
        </SvgIcon>
      ),
      bgColor: 'cupid',
      onClick: async () => setIsMessengerOpen(!isMessengerOpen)
    },
    {
      title: t('NAVIGATION_TELEHEALTH'),
      icon: (
        <SvgIcon>
          <VideoIcon />
        </SvgIcon>
      ),
      bgColor: 'poloBlue',
      onClick: async () => {
        setIsTelehealthOpen(true);
      }
    },
    {
      title: t('NAVIGATION_CURRENT_CYCLE'),
      icon: (
        <SvgIcon>
          <CycleIcon color={Colors.mineShaft} viewBox="0 0 28 28" />
        </SvgIcon>
      ),
      bgColor: 'jungleMist',
      path: patientRoutes.getCurrentTreatmentLink(patientId),
      pathMatchType: 'includes'
    },
    {
      title: t('EMBRYOLOGY'),
      icon: (
        <SvgIcon>
          <EmbryoIcon color={Colors.poloBlue} />
        </SvgIcon>
      ),
      bgColor: 'jungleMist',
      path: patientRoutes.getGameteAndCryoLink(patientId),
      pathMatchType: 'includes'
    },
    {
      title: t('NAVIGATION_HISTORY'),
      icon: (
        <SvgIcon>
          <MedicalHistoryIcon />
        </SvgIcon>
      ),
      bgColor: 'viola',
      path: patientRoutes.getMedicalHistoryLink(patientId),
      pathMatchType: 'exact'
    },
    {
      title: t('NAVIGATION_DOCUMENTS'),
      icon: (
        <SvgIcon>
          <DocumentIcon />
        </SvgIcon>
      ),
      bgColor: 'mustard',
      path: patientRoutes.getFormsLink(patientId),
      pathMatchType: 'exact'
    },
    {
      title: t('NAVIGATION_TASKS'),
      icon: (
        <SvgIcon>
          <CheckIcon />
        </SvgIcon>
      ),
      id: 'patient-buttons-checklists-page',
      bgColor: 'red',
      path: patientRoutes.getChecklistsLink(patientId),
      pathMatchType: 'exact'
    },
    {
      title: t('NAVIGATION_CONTACT'),
      onClick: (ev) =>
        openPopover({
          children: <ContactInfo patientId={patientId} />,
          anchorEl: ev.currentTarget,
          header: 'Contact',
          paperPadding: spacings.large,
          onEditClick: () => {
            navigate(patientRoutes.getEditLink(patientId));
          },
          closeOnEdit: true,
          id: 'contact-info'
        }),
      icon: (
        <SvgIcon>
          <ContactIcon />
        </SvgIcon>
      ),
      bgColor: 'gray',
      id: 'patient-contact-button'
    }
  ];

  return (
    <ButtonsSideBar sx={sx}>
      {buttons.map(
        (
          {
            title,
            path,
            pathMatchType,
            icon,
            bgColor,
            onClick,
            hide,
            ...otherProps
          },
          index
        ) => {
          const isActive =
            pathMatchType === 'exact'
              ? pathname === path
              : pathname.includes(path);

          if (hide) {
            return null;
          }

          return (
            <ButtonContainer key={index} className={isActive ? 'active' : ''}>
              <TooltipWrapper title={title} arrow placement="right">
                <Box>
                  <IconButton
                    icon={icon}
                    iconSize="large"
                    bgColor={bgColor}
                    onClick={(ev) => {
                      return path ? navigate(path) : onClick?.(ev);
                    }}
                    {...otherProps}
                  />
                </Box>
              </TooltipWrapper>
            </ButtonContainer>
          );
        }
      )}
    </ButtonsSideBar>
  );
};
