import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Tabs, { TabContentsProps } from 'src/components/display/Tabs/Tabs';
import usePatientsApi from 'src/hooks/usePatientsApi';
import Box from 'src/components/layout/Box';
import Flex from 'src/components/layout/Flex';
import { spacings } from 'src/components/styles/constants';
import { PatientMiniCard } from '../common/PatientMiniCard';
import { EmbryologyTable } from './EmbryologyTable';
import Card from 'src/components/display/Card';
import { Helmet } from 'react-helmet-async';
import { PatientFlags } from '../medicalHistory/PatientFlags';
import Typography from 'src/components/display/Typography';
import Loader from 'src/components/display/Loader';

export enum GameteCryoTabs {
  EMBRYOLOGY = 'Embryology'
}

export const GameteAndCryo: FC = () => {
  const [selectedTab, setSelectedTab] = useState<GameteCryoTabs>(
    GameteCryoTabs.EMBRYOLOGY
  );

  const { t } = useTranslation();
  const { patientId } = useParams();
  const { getPatientOverview } = usePatientsApi();

  const {
    data: patientOverview,
    isLoading: isLoadingPatientOverview,
    isFetching: isFetchingPatientOverview
  } = getPatientOverview(patientId);

  const { partnerInfo } = patientOverview || {};

  const tabContents: TabContentsProps<GameteCryoTabs>[] = [
    {
      value: GameteCryoTabs.EMBRYOLOGY,
      label: t('EMBRYOLOGY'),
      children: <EmbryologyTable patientId={patientId} />
    }
  ];

  const handleTabChange = (value: GameteCryoTabs) => {
    setSelectedTab(value);
  };

  if (isLoadingPatientOverview || isFetchingPatientOverview) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>{t('PATIENT_GAMETE_AND_CRYO_PAGE_TITLE')}</title>
      </Helmet>
      <Box width="70%" marginBottom={spacings.large}>
        <PatientFlags patientId={patientId} />
      </Box>
      <Flex gap={spacings.large} width="100%">
        <Box flex={1}>
          <PatientMiniCard
            marginBottom={spacings.large}
            basicInfo={patientOverview?.patientBasicInfo}
            properties={patientOverview?.patientProperties}
            patientId={patientId}
            patientDisplayId={patientOverview?.patientBasicInfo?.displayId}
            header=""
          />
        </Box>
        {partnerInfo?.id && (
          <Box flex={1}>
            <PatientMiniCard
              isPartner
              marginBottom={spacings.large}
              basicInfo={patientOverview?.partnerInfo?.basicInfo}
              properties={patientOverview?.partnerInfo?.properties}
              patientDisplayId={
                patientOverview?.partnerInfo?.basicInfo?.displayId
              }
              patientId={partnerInfo.id}
              header=""
            />
          </Box>
        )}
      </Flex>
      <Card
        shadow
        padding={spacings.xlarge}
        display="flex"
        flexDirection="column"
        gap={spacings.large}
      >
        <Typography variant="h1">{t('GAMETE_AND_CRYO')}</Typography>
        <Tabs<GameteCryoTabs>
          onTabChange={handleTabChange}
          selectedTab={selectedTab}
          tabContents={tabContents}
        />
      </Card>
    </>
  );
};
