import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import PatientsSearchBar from 'src/layouts/CollapsedSidebarLayout/Header/PatientsSearchBar';
import { SearchFieldVariants } from 'src/components/data-entry/SearchField/SearchField';
import { PatientPersonalInfo } from 'src/types/patient';
import usePatientsApi from 'src/hooks/usePatientsApi';
import Box from '../../components/layout/Box';
import { spacings } from '../../components/styles/constants';
import InputField from '../../components/data-entry/InputField';
import TextArea from '../../components/data-entry/TextArea';
import Button from '../../components/display/Button';
import Loader from '../../components/display/Loader';
import { AddProgressNoteForm } from '../../types/feed';
import useFeed from '../../hooks/useFeed';
import PatientPartnerSelect from '../calendar/appointmentTabs/PatientPartnerSelect';

interface AddProgressNoteProps {
  patientId: string;
  onClose: () => void;
}

export const AddProgressNote: FC<AddProgressNoteProps> = ({
  patientId,
  onClose
}) => {
  const { t } = useTranslation();
  const { createProgressNote } = useFeed();
  const { getPatientById } = usePatientsApi();

  const { data: patientFullData, isLoading: isLoadingPatient } =
    getPatientById(patientId);

  const { control, formState, handleSubmit, getValues, setValue } =
    useForm<AddProgressNoteForm>({
      mode: 'onChange',
      defaultValues: {
        patient: patientFullData?.personalInfo,
        patientId,
        name: '',
        summary: ''
      }
    });

  const {
    mutateAsync: handleCreateProgressNote,
    isLoading: isCreatingProgressNote
  } = createProgressNote();

  const { errors } = formState;

  const onSubmit = async (data: AddProgressNoteForm) => {
    await handleCreateProgressNote(data, {
      onSuccess: () => onClose()
    });
  };

  const handleSelectPatient = (patient: PatientPersonalInfo) => {
    setValue('patientId', patient.id);
    setValue('patient', patient);
  };

  useEffect(() => {
    if (!patientFullData) return;
    setValue('patient', patientFullData.personalInfo);
  }, [patientFullData]);

  if (isLoadingPatient) return <Loader />;

  return (
    <Box>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Box marginBottom={spacings.medium} width={300}>
          <Box flex={1} id="edit-appointment-patient-select-container">
            <Controller
              name="patient"
              control={control}
              rules={{
                required: t('PATIENT_REQUIRED')
              }}
              render={() => {
                if (patientId) {
                  return (
                    <PatientPartnerSelect
                      patientId={patientId}
                      errors={errors}
                      onChange={handleSelectPatient}
                      value={getValues('patientId')}
                    />
                  );
                } else {
                  return (
                    <PatientsSearchBar
                      placeholder={t('SEARCH_PATIENT_PLACEHOLDER')}
                      variant={SearchFieldVariants.DEFAULT}
                      onSelectPatient={handleSelectPatient}
                      onSearchTermClear={() => {
                        setValue('patientId', '');
                        setValue('patient', null);
                      }}
                      errors={errors}
                      label={t('PATIENT_LABEL')}
                    />
                  );
                }
              }}
            />
          </Box>
          <Box marginTop={spacings.medium}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: t('NOTE_SUBJECT_REQUIRED')
              }}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={t('NOTE_SUBJECT')}
                  placeholder={t('ADD_NOTE_SUBJECT')}
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  required
                  fullWidth
                />
              )}
            />
          </Box>
        </Box>
        <Box>
          <Controller
            name="summary"
            control={control}
            rules={{
              required: t('NOTE_SUMMARY_REQUIRED')
            }}
            render={({ field: { ref, ...field } }) => (
              <TextArea
                {...field}
                ref={ref}
                placeholder={t('TYPE_NOTE')}
                error={!!errors.summary}
                helperText={errors?.summary?.message}
                minRows={15}
                maxRows={15}
                fullWidth
              />
            )}
          />
        </Box>
        <Box margin="auto" width="50%" mt={spacings.xlarge}>
          <Button
            id="patient-diagnosis-submit-button"
            fullWidth
            type="submit"
            disabled={isCreatingProgressNote}
          >
            {isCreatingProgressNote ? <Loader /> : t('SAVE')}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
