import { FC } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { styled } from '@mui/system';
import Card, { CardProps } from 'src/components/display/Card/Card';

import Flex from 'src/components/layout/Flex';
import { iconSizes, spacings } from 'src/components/styles/constants';
import InputField from 'src/components/data-entry/InputField';
import { Colors } from 'src/components/styles/colors';
import { useTranslation } from 'react-i18next';
import { formatDateAndTime } from 'src/utils/dateAndTIme';
import Loader from 'src/components/display/Loader';
import { fonts } from 'src/components/styles/fonts';

const StyledEditableTitleContainer = styled(Card)<CardProps>`
  padding: ${spacings.small};
  padding-inline-end: ${spacings.xlarge};
`;

const StyledEditableText = styled(InputField)`
  .MuiInputBase-root {
    height: 30px;
    color: ${Colors.emperor};
    border: ${Colors.transparent};

    .MuiSvgIcon-root {
      width: 25px;
    }

    .MuiInputBase-input.Mui-disabled {
      color: ${Colors.emperor};
      font: ${fonts.label};
      -webkit-text-fill-color: ${Colors.emperor};
    }
  }
`;

export const PatientLastLogin: FC<{ patientId: string }> = ({ patientId }) => {
  const { t } = useTranslation();
  const { getPatientOverview } = usePatientsApi();
  const { data: patientOverview, isFetching: isLoadingOverview } =
    getPatientOverview(patientId);

  let value;

  if (isLoadingOverview) {
    value = '...';
  } else if (!!patientOverview?.lastLogin) {
    value = formatDateAndTime(patientOverview?.lastLogin);
  } else {
    value = t('UNKNOWN');
  }

  return (
    <StyledEditableTitleContainer shadow>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={spacings.x2large}
      >
        <StyledEditableText
          disabled
          startIcon={
            isLoadingOverview ? (
              <Loader size={iconSizes.small} />
            ) : (
              <AccessTimeIcon sx={{ color: Colors.black, fontSize: '3rem' }} />
            )
          }
          value={t('PATIENT_LAST_LOGIN') + ': ' + value}
        />
      </Flex>
    </StyledEditableTitleContainer>
  );
};
