import { FC, useState } from 'react';
import Box from '../../components/layout/Box';
import { BillingStatCards } from './BillingStatCards';
import { InvoicesTable } from './InvoicesTable';
import useBilling from '../../hooks/useBilling';
import Card from '../../components/display/Card';
import Typography from '../../components/display/Typography';
import { useTranslation } from 'react-i18next';
import { spacings } from '../../components/styles/constants';
import {
  AllStatusOptions,
  InvoiceStatus,
  StatusesOptions
} from '../../types/billing';
import Flex from '../../components/layout/Flex';
import Select from '../../components/data-entry/Select';
import { fontWeights } from '../../components/styles/fonts';
import { PatientsSearchBar } from 'src/layouts/CollapsedSidebarLayout/Header/PatientsSearchBar';
import { SearchFieldVariants } from '../../components/data-entry/SearchField/SearchField';
import i18next from 'i18next';

const statusFilterOptions = [
  {
    label: i18next.t(AllStatusOptions.ALL_STATUS),
    value: AllStatusOptions.ALL_STATUS
  },
  ...Object.entries(InvoiceStatus).map(([key, value]) => ({
    label: i18next.t(key),
    value
  }))
];

export const BillingDashboard: FC = () => {
  const { t } = useTranslation();
  const [patientId, setPatientId] = useState<string>(null);
  const [status, setStatus] = useState<StatusesOptions>(
    AllStatusOptions.ALL_STATUS
  );
  const { getInvoices } = useBilling();

  const {
    data: invoices,
    isLoading: isLoadingInvoices,
    isFetching: isFetchingInvoices
  } = getInvoices(status, patientId);

  const isLoading = isLoadingInvoices || isFetchingInvoices;

  return (
    <Box>
      <Box marginBottom={spacings.large}>
        <BillingStatCards />
      </Box>

      <Card>
        <Flex
          paddingY={spacings.large}
          paddingX={spacings.xlarge}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h2" fontWeight={fontWeights.extraBold}>
            {t('RECENT_INVOICES')}
          </Typography>
          <Flex gap={spacings.large} alignItems="stretch" width="40%">
            <Box flex={1}>
              <PatientsSearchBar
                variant={SearchFieldVariants.DEFAULT}
                onSelectPatient={(newPatient) => setPatientId(newPatient.id)}
                startIcon={null}
                onSearchTermClear={() => setPatientId(null)}
              />
            </Box>
            <Box flex={1}>
              <Select
                value={status}
                options={statusFilterOptions}
                onChange={(ev) => setStatus(ev.target.value as InvoiceStatus)}
              />
            </Box>
          </Flex>
        </Flex>
        <InvoicesTable invoices={invoices} loading={isLoading} />
      </Card>
    </Box>
  );
};
