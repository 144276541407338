import { FC, useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import Loader from 'src/components/display/Loader/Loader';
import {
  Appointment,
  AppointmentPurposes,
  ConsultationAppointmentTypes,
  LabOrderBillTo,
  NO_ROOM_OPTION,
  OrderFormParams,
  Urgency,
  VIRTUAL_LOCATION
} from 'src/types/appointment';
import { YesOrNo } from 'src/types/global';
import { OrderFormServer } from 'src/types/patient-server';
import { roundToNearest15Min } from 'src/utils/dateAndTIme';
import { TimeUnits } from 'src/utils/types';
import { useDialog, useLoadingOverlay } from 'src/contexts/UIContexts';
import {
  OrderForm,
  OrderFormProps,
  OrderType
} from 'src/modules/patients/common/OrderForm';
import { queryKeys, querySubKeys } from 'src/hooks/queryKeys';
import useCodes from 'src/hooks/useCodes';
import useAppointments from 'src/hooks/useAppointments';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { AppointmentDetails } from '../../patients/overview/AppointmentDetails';
import { ScheduleAppointment } from './ScheduleAppointment';

const LAB_TEST_ID_PREFIX = 'lab-test';
const DEFAULT_HOUR = 7;
const DEFAULT_START_MINUTE = 30;
const DEFAULT_SECOND = 0;
const DEFAULT_DURATION: Record<TimeUnits, number> = {
  [TimeUnits.h]: 0,
  [TimeUnits.m]: DEFAULT_START_MINUTE,
  [TimeUnits.ms]: 0,
  [TimeUnits.s]: 0,
  [TimeUnits.d]: 0,
  [TimeUnits.mm]: 0,
  [TimeUnits.y]: 0
};

const ScheduleAndOrdersTab: FC<{
  onDirtyFormChange: (dirty: boolean) => void | Promise<void>;
  appointmentId?: string;
  patientId?: string;
  cycleId?: string;
  defaultDate?: string;
}> = ({
  appointmentId,
  patientId,
  cycleId,
  onDirtyFormChange,
  defaultDate
}) => {
  const [orderFormData, setOrderFormData] = useState<OrderFormParams>(null);
  const [appointmentFormData, setAppointmentFormData] =
    useState<Appointment>(null);

  const queryClient = useQueryClient();
  const { closeAllDialogs, closeDialog, openDialog } = useDialog();
  const { getAppointmentById } = useAppointments();
  const { getPatientById } = usePatientsApi();
  const { createAppointment, updateAppointment } = useAppointments();
  const { getIcd10Codes } = useCodes();
  const { openLoadingOverlay, closeLoadingOverlay } = useLoadingOverlay();
  const { t } = useTranslation();

  const initialDate = dayjs(defaultDate);
  const defaultHour = initialDate.hour(DEFAULT_HOUR);
  const defaultStartTime = defaultHour
    .minute(DEFAULT_START_MINUTE)
    .second(DEFAULT_SECOND)
    .toDate();

  const {
    data: appointmentToEdit,
    isLoading: isLoadingAppointment,
    isFetching: isFetchingAppointment,
    refetch: refetchAppointment
  } = getAppointmentById(appointmentId, patientId, {
    enabled: !!appointmentId && !!patientId
  });

  const { mutate: createAppointmentMutate, isLoading: isCreatingAppointment } =
    createAppointment();

  const { data: patientFullData } = getPatientById(patientId);

  const { mutate: updateAppointmentMutate, isLoading: isUpdatingAppointment } =
    updateAppointment();

  const startTime = defaultDate
    ? defaultStartTime
    : appointmentToEdit
    ? dayjs(appointmentToEdit?.date).toDate()
    : roundToNearest15Min(dayjs()).toDate();

  const date = defaultDate
    ? dayjs(defaultDate).toDate()
    : dayjs(appointmentToEdit?.date).toDate();

  const appointmentPurposeValue =
    appointmentToEdit?.appointmentPurpose ||
    (cycleId ? AppointmentPurposes.IN_CYCLE : AppointmentPurposes.CONSULTATION);

  const appointmentDefaultValues: Appointment = {
    patientId: patientId || '',
    cycleId: cycleId || '',
    patient: patientFullData?.personalInfo || null,
    date: date || null,
    duration: appointmentToEdit?.duration || DEFAULT_DURATION,
    startTime,
    location: !appointmentToEdit?.isVirtual
      ? appointmentToEdit?.location
      : VIRTUAL_LOCATION,
    room: appointmentToEdit?.room || NO_ROOM_OPTION,
    staffIds: appointmentToEdit?.staffIds || [],
    panelIds:
      appointmentToEdit?.labOrders?.map(
        ({ panelId, labTest }) =>
          panelId || (labTest && `${LAB_TEST_ID_PREFIX}-${labTest?.id}`)
      ) || [],
    appointmentPurpose: appointmentPurposeValue,
    appointmentType:
      appointmentToEdit?.appointmentType ||
      ConsultationAppointmentTypes.RN_HEALTHCARE_PROFESSIONAL_CONSULT,
    patientNotes: appointmentToEdit?.patientNotes || '',
    internalNotes: appointmentToEdit?.internalNotes || '',
    isVirtual: appointmentToEdit ? appointmentToEdit?.isVirtual : false,
    labOrderInfo: {
      isUrgent: appointmentToEdit?.labOrderInfo?.isUrgent || false,
      billTo: appointmentToEdit?.labOrderInfo?.billTo || LabOrderBillTo.CLINIC,
      performingLabEmail:
        appointmentToEdit?.labOrderInfo?.performingLabEmail || '',
      isFasting: appointmentToEdit?.labOrderInfo?.isFasting || false,
      externalId: appointmentToEdit?.labOrderInfo?.externalId || null
    },
    labOrder: appointmentToEdit?.labOrder || null,
    procedureOrder: appointmentToEdit?.procedureOrder || null
  };

  const {
    data: procedureOrderIcd10Codes,
    isLoading: isLoadingProcedureOrderICD10Codes,
    isFetching: isFetchingProcedureOrderICD10Codes
  } = getIcd10Codes({
    icd10Codes: appointmentToEdit?.procedureOrder?.icdCodes
  });

  const {
    data: labOrderIcd10Codes,
    isLoading: isLoadingLabOrderICD10Codes,
    isFetching: isFetchingLabOrderICD10Codes
  } = getIcd10Codes({ icd10Codes: appointmentToEdit?.labOrder?.icdCodes });

  const orderBundleDetails: OrderFormServer = useMemo(
    () => appointmentToEdit?.labOrder || appointmentToEdit?.procedureOrder,
    [appointmentToEdit]
  );

  const orderDefaultValues: OrderFormParams = useMemo(
    () => ({
      patientId,
      patientName: '',
      purpose: orderBundleDetails?.purpose || null,
      labTestsIds: appointmentToEdit?.labOrder?.labTestsIds || [],
      labInstructions: appointmentToEdit?.labOrder?.labInstructions || '',
      labOrderIcd10Codes: labOrderIcd10Codes || [],
      proceduresInstructions:
        appointmentToEdit?.procedureOrder?.proceduresInstructions || '',
      procedureOrderIcd10Codes: procedureOrderIcd10Codes || [],
      proceduresIds: appointmentToEdit?.procedureOrder?.proceduresIds || [],
      requestingPhysician: orderBundleDetails?.requestingPhysician || null,
      billTo: orderBundleDetails?.billTo || LabOrderBillTo.PATIENT,
      vendorId: orderBundleDetails?.vendorId || null,
      urgency: orderBundleDetails?.urgency || Urgency.ROUTINE,
      isFasting: orderBundleDetails?.isFasting ? YesOrNo.YES : YesOrNo.NO,
      bundleNames: orderBundleDetails?.bundleNames || []
    }),
    [
      appointmentToEdit,
      labOrderIcd10Codes,
      procedureOrderIcd10Codes,
      orderBundleDetails
    ]
  );

  const handleOrderFormUpdates = (
    updatedOrderData: OrderFormParams,
    isDirty: boolean
  ) => {
    setOrderFormData(updatedOrderData);
    onDirtyFormChange(isDirty);
  };

  const handleAppointmentFormUpdates = (
    updatedAppointmentData: Appointment,
    isDirty: boolean
  ) => {
    setAppointmentFormData(updatedAppointmentData);
    onDirtyFormChange(isDirty);
  };

  const extractLabTestAndPanelIds = (appointmentDetails: Appointment) => {
    const labTestIds: string[] = [];
    const panelIds: string[] = [];

    appointmentDetails.panelIds?.forEach((orderId: string) => {
      if (orderId.includes(LAB_TEST_ID_PREFIX)) {
        const id: string = orderId.split(LAB_TEST_ID_PREFIX + '-').pop();
        labTestIds.push(id);
      } else {
        panelIds.push(orderId);
      }
    });

    return { labTestIds, panelIds };
  };

  const getStartDate = (appointmentDetails: Appointment) => {
    const startHour = dayjs(appointmentDetails?.startTime).hour();
    const startMinutes = dayjs(appointmentDetails?.startTime).minute();
    const updatedStartDate = dayjs(appointmentDetails?.date)
      .hour(startHour)
      .minute(startMinutes)
      .startOf('minute')
      .toDate();

    return updatedStartDate;
  };

  const createOrderDetails = useCallback(
    ({
      appointmentDetails
    }: {
      appointmentDetails: Appointment;
    }): { labOrder: OrderFormServer; procedureOrder: OrderFormServer } => {
      const hasLabOrderData = !!(
        orderFormData?.labTestsIds?.length ||
        orderFormData?.labOrderIcd10Codes?.length
      );
      const hasProcedureOrderData = !!(
        orderFormData?.proceduresIds?.length ||
        orderFormData?.procedureOrderIcd10Codes?.length
      );

      const labOrderDetails: OrderFormServer | undefined = hasLabOrderData
        ? {
            id: appointmentToEdit?.labOrder?.id,
            status: appointmentToEdit?.labOrder?.status,
            patientId,
            patientName: orderFormData?.patientName,
            purpose: appointmentDetails?.appointmentPurpose,
            labTestsIds: orderFormData?.labTestsIds,
            labInstructions: orderFormData?.labInstructions,
            labOrderIcd10Codes: orderFormData?.labOrderIcd10Codes,
            proceduresIds: [],
            proceduresInstructions: '',
            procedureOrderIcd10Codes: [],
            requestingPhysician: orderFormData?.requestingPhysician,
            billTo: orderFormData?.billTo,
            vendorId: orderFormData?.vendorId,
            urgency: orderFormData?.urgency,
            isFasting: orderFormData?.isFasting === YesOrNo.YES ? true : false,
            bundleNames: orderFormData?.bundleNames
          }
        : undefined;

      const procedureOrderDetails: OrderFormServer | undefined =
        hasProcedureOrderData
          ? {
              id: appointmentToEdit?.procedureOrder?.id,
              status: appointmentToEdit?.procedureOrder?.status,
              patientId,
              patientName: orderFormData?.patientName,
              purpose: appointmentDetails?.appointmentPurpose,
              labTestsIds: [],
              labInstructions: '',
              labOrderIcd10Codes: [],
              proceduresIds: orderFormData?.proceduresIds,
              proceduresInstructions: orderFormData?.proceduresInstructions,
              procedureOrderIcd10Codes: orderFormData?.procedureOrderIcd10Codes,
              requestingPhysician: orderFormData?.requestingPhysician,
              billTo: orderFormData?.billTo,
              vendorId: orderFormData?.vendorId,
              urgency: orderFormData?.urgency,
              isFasting:
                orderFormData?.isFasting === YesOrNo.YES ? true : false,
              bundleNames: orderFormData?.bundleNames
            }
          : undefined;

      return {
        labOrder: labOrderDetails,
        procedureOrder: procedureOrderDetails
      };
    },
    [orderFormData, patientId, appointmentToEdit]
  );

  const handleUpdateAppointment = async ({
    appointmentDetails,
    id,
    room,
    updatedStartDate,
    labTestIds,
    panelIds
  }: {
    appointmentDetails: Appointment;
    id: string;
    room: string;
    updatedStartDate: Date;
    labTestIds: string[];
    panelIds: string[];
  }) => {
    const createdOrderDetails = createOrderDetails({ appointmentDetails });
    updateAppointmentMutate(
      {
        ...appointmentDetails,
        id,
        room,
        date: updatedStartDate,
        labTestIds,
        panelIds,
        labOrder: createdOrderDetails.labOrder,
        procedureOrder: createdOrderDetails.procedureOrder
      },
      {
        onSettled: () => {
          closeLoadingOverlay();
        },
        onSuccess: (updatedAppointment) => {
          refetchAppointment();
          closeAllDialogs();

          if (updatedAppointment) {
            openDialog({
              fullWidth: true,
              maxWidth: 'sm',
              removeDefaultPadding: true,
              hideCloseButton: true,
              children: (
                <AppointmentDetails
                  appointment={updatedAppointment}
                  patientId={patientId}
                />
              )
            });
          }
        }
      }
    );
  };

  const handleCreateAppointment = async ({
    appointmentDetails,
    room,
    updatedStartDate,
    labTestIds,
    panelIds
  }: {
    appointmentDetails: Appointment;
    room: string;
    updatedStartDate: Date;
    labTestIds: string[];
    panelIds: string[];
  }) => {
    const createdOrderDetails = createOrderDetails({ appointmentDetails });

    createAppointmentMutate(
      {
        appointment: {
          ...appointmentDetails,
          room,
          date: updatedStartDate,
          labTestIds,
          panelIds,
          labOrder: createdOrderDetails?.labOrder,
          procedureOrder: createdOrderDetails?.procedureOrder
        }
      },
      {
        onSettled: () => {
          closeLoadingOverlay();
        },
        onSuccess: (newAppointment) => {
          const invalidateAppointments = (id: string) => {
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              id,
              querySubKeys[queryKeys.PATIENTS].APPOINTMENTS
            ]);
          };

          if (patientFullData?.hasPartner) {
            const mainId = patientFullData.personalInfo.id;
            const partnerId = patientFullData.partnerInfo.id;
            invalidateAppointments(
              appointmentDetails.patientId === mainId ? partnerId : mainId
            );
          } else {
            invalidateAppointments(patientId);
          }

          closeDialog();

          if (newAppointment) {
            openDialog({
              fullWidth: true,
              maxWidth: 'sm',
              removeDefaultPadding: true,
              hideCloseButton: true,
              children: (
                <AppointmentDetails
                  appointmentId={newAppointment.id}
                  appointment={newAppointment}
                  patientId={patientId}
                />
              )
            });
          }
        }
      }
    );
  };

  const onSubmit = async (appointmentDetails: Appointment) => {
    const { labTestIds, panelIds } =
      extractLabTestAndPanelIds(appointmentDetails);

    const updatedStartDate = getStartDate(appointmentDetails);

    if (appointmentFormData?.isVirtual) {
      delete appointmentDetails.location;
      delete appointmentDetails.room;
    }

    const room =
      appointmentDetails?.room === NO_ROOM_OPTION
        ? null
        : appointmentDetails?.room;

    openLoadingOverlay({ message: t('SCHEDULE_APPOINTMENT_LOADING') });
    if (appointmentToEdit) {
      appointmentDetails.id = appointmentToEdit.id;
      handleUpdateAppointment({
        appointmentDetails,
        id: appointmentToEdit.id,
        room,
        updatedStartDate,
        labTestIds,
        panelIds
      });
    } else {
      handleCreateAppointment({
        appointmentDetails,
        room,
        updatedStartDate,
        labTestIds,
        panelIds
      });
    }
  };

  const orderFormProps: OrderFormProps = {
    patientId,
    orderType: OrderType.INTERNAL,
    defaultValues: orderDefaultValues,
    purpose: appointmentFormData?.appointmentPurpose,
    onFormUpdate: handleOrderFormUpdates,
    patientInfo: patientFullData?.personalInfo,
    bundleNames: orderFormData?.bundleNames
  };

  const isLoadingICD10Codes =
    isLoadingProcedureOrderICD10Codes ||
    isLoadingLabOrderICD10Codes ||
    isFetchingProcedureOrderICD10Codes ||
    isFetchingLabOrderICD10Codes;
  const isLoadingForm =
    isLoadingAppointment || isFetchingAppointment || isLoadingICD10Codes;
  const isSubmitDisabled = isCreatingAppointment || isUpdatingAppointment;

  if (isLoadingForm) return <Loader />;

  return (
    <ScheduleAppointment
      patientId={patientId}
      isDisabled={isSubmitDisabled}
      onSubmit={onSubmit}
      defaultValues={appointmentDefaultValues}
      onFormUpdate={handleAppointmentFormUpdates}
      patientInfo={patientFullData?.personalInfo}
      OrderFormComponent={OrderForm}
      orderFormProps={orderFormProps}
    />
  );
};

export default ScheduleAndOrdersTab;
