import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';

import { radii, spacings } from 'src/components/styles/constants';
import Flex from 'src/components/layout/Flex';
import Typography from 'src/components/display/Typography';
import { Colors } from 'src/components/styles/colors';
import { fontWeights } from 'src/components/styles/fonts';
import Chip from 'src/components/data-entry/Chips/Chip';
import MiniIconButton from 'src/components/display/MiniIconButton/MiniIconButton';
import Loader from 'src/components/display/Loader/Loader';
import Card from 'src/components/display/Card';
import useDocumentsApi from 'src/hooks/useDocumentsApi';
import { orderTemplateId } from 'src/types/documents';
import { useDocumentEditor } from 'src/contexts/AppContexts/AppContexts';
import { getDocumentSignatureStatus } from '../../documents/utils/editorUtils/getDocumentSignatureStatus';

type OrderDocumentPreviewProps = {
  patientId: string;
  orderId?: string;
};

const StyledDocumentStatusChip = styled(Chip)<{
  statusColor?: Colors;
}>`
  font-weight: ${fontWeights.extraBold};
  background: ${({ statusColor }) => statusColor || Colors.alto2};
`;

const DocumentSummaryDisplay: FC<{
  status?: string;
  statusColor?: Colors;
  onClickEdit: () => void;
  title: string;
}> = ({ status, statusColor, onClickEdit, title }) => {
  const { t } = useTranslation();

  return (
    <Card
      padding={spacings.large}
      shadow
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flex={1}
      borderRadius={radii.medium}
    >
      <Flex alignItems="center" gap={spacings.large}>
        <MiniIconButton icon={<EditIcon />} onClick={onClickEdit} />
        <Typography fontWeight={fontWeights.bold} variant="h3">
          {title}
        </Typography>
      </Flex>

      <StyledDocumentStatusChip
        statusColor={statusColor}
        label={t(status).toUpperCase()}
      />
    </Card>
  );
};

export const OrderDocumentPreview: FC<OrderDocumentPreviewProps> = ({
  patientId,
  orderId
}) => {
  const { t } = useTranslation();

  const { getDocumentById } = useDocumentsApi();

  const { onDocumentEditorInfoChange, onDocumentsModalOpen } =
    useDocumentEditor();

  const { data: orderDocument, isLoading: isLoadingOrderDocument } =
    getDocumentById(patientId, orderId, {
      enabled: !!orderId && !!patientId
    });

  const { color, status } =
    getDocumentSignatureStatus({
      isDocumentCompleted: orderDocument?.isCompleted,
      isInitialVendorEmailSent: orderDocument?.isInitialVendorEmailSent
    }) || {};

  if (!orderId) {
    return (
      <Typography fontWeight={fontWeights.bold} variant="h3">
        {t('THERE_IS_NO_ORDERS_DOCUMENT_TO_DISPLAY')}
      </Typography>
    );
  }

  if (isLoadingOrderDocument) return <Loader />;

  return (
    <DocumentSummaryDisplay
      onClickEdit={() => {
        onDocumentEditorInfoChange({
          patientId,
          templateId: orderTemplateId,
          documentId: orderDocument?.id
        });
        onDocumentsModalOpen(true);
      }}
      status={status}
      statusColor={color}
      title={`${t('ORDER').toUpperCase()} #${orderId}`}
    />
  );
};
