import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import { TimeUnits } from 'src/utils/types';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(isBetween);

export const longDisplayDateFormat = 'MMMM DD, YYYY';
export const shortDisplayDateFormat = 'MMM DD';
export const longDateFormat = 'MM/DD/YYYY';
export const longDateTypingFormat = 'MMDDYYYY';
export const shortDateFormat = 'MM/DD';
export const dashSeparatedDateFormat = 'YYYY-MM-DD';
export const longTimeFormat = 'HH:mm:ss';
export const shortTimeFormat = 'hh:mm A';
export const shortTimeDateFormat = `${shortTimeFormat} ${shortDisplayDateFormat}`;
export const longDateTimeFormat = `${longDateFormat} ${shortTimeFormat}`;
export const longDateTimeMsFormat = `${dashSeparatedDateFormat}-${longTimeFormat}.SSS`;
type DateTimeFormatParams = {
  isShort?: boolean;
};

export const formatDateAndTime = (date: Date) => {
  const formattedDate = dayjs(date).format(longDisplayDateFormat);
  const formattedTime = dayjs(date).format(shortTimeFormat);
  const formattedDateAndTime = `${formattedDate} ${formattedTime}`;
  return formattedDateAndTime;
};

export const getUserAge = (
  birthdate: Date
): { years: number; months: number } => {
  const now = dayjs();
  const birth = dayjs(birthdate);
  const diff = now.diff(birth, 'month');
  const yearsDiff = Math.floor(diff / 12);
  const monthsDiff = diff % 12;
  return {
    years: yearsDiff,
    months: monthsDiff
  };
};

export const getDateFormat = (params?: DateTimeFormatParams): string =>
  params?.isShort ? shortDateFormat : longDateFormat;
export const getTimeFormat = (params?: DateTimeFormatParams): string =>
  params?.isShort ? shortTimeFormat : longTimeFormat;

export const convertDateToDayjs = (date: Date | null): Dayjs | null => {
  return date ? dayjs(date) : null;
};

export const roundToNearest15Min = (time: Dayjs): Dayjs => {
  const minutes = time.minute();
  const roundedMinutes = Math.round(minutes / 15) * 15 + 15;
  return time.minute(roundedMinutes).second(0); // Set seconds to 0 for a clean rounded time
};

export const getWeekRange = (date: Dayjs): string => {
  const startOfWeek = date.format('MMM DD');
  const endOfWeek = date.add(6, 'day').format('DD, YYYY');
  return `${startOfWeek}-${endOfWeek}`;
};

export const formatDuration = (
  duration: Record<TimeUnits, number>,
  outputFormat = 'h m'
): string => {
  const timeUnitRegex = new RegExp(
    `\\b(${Object.values(TimeUnits).join('|')})\\b`,
    'g'
  );

  return outputFormat
    .replace(timeUnitRegex, (match) => {
      const value = duration[match as TimeUnits];
      return value > 0 ? `${value}${match}` : '';
    })
    .trim();
};
