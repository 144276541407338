import { FC, ReactNode, useEffect, useState } from 'react';
import {
  useGridApiRef,
  DataGridPremium,
  DataGridPremiumProps
} from '@mui/x-data-grid-premium';
import {
  GridColumnMenu,
  GridColumnsPanel,
  GridFilterPanel,
  GridToolbar,
  GridPaginationModel,
  GridFilterInputValueProps,
  GRID_DATE_COL_DEF,
  getGridDateOperators,
  GridColTypeDef
} from '@mui/x-data-grid-pro';
import { Pagination, css, styled } from '@mui/material';
import { fonts, fontWeights } from 'src/components/styles/fonts';
import Box, { BoxProps } from 'src/components/layout/Box';
import { Colors } from 'src/components/styles/colors';
import {
  radii,
  spacings,
  shadows,
  iconSizes
} from 'src/components/styles/constants';
import { makeShouldForwardProps } from '../../utils';
import Loader from '../Loader';
import { PaginationProps as MUIPaginationProps } from '@mui/material/Pagination';
import DatePicker from 'src/components/data-entry/DatePicker';
import dayjs from 'dayjs';
import { DatePickerVariant } from 'src/components/data-entry/DatePicker/DatePicker';

type TableVariant =
  | 'default'
  | 'striped'
  | 'carded'
  | 'grid'
  | 'default-compact';

export enum RowHeights {
  xs = 25,
  sm = 30,
  md = 40,
  lg = 60
}

export enum ColumnHeights {
  xs = 25,
  sm = 30,
  md = 50,
  lg = 60
}

const getCustomRowHeight = (variant: TableVariant) => {
  switch (variant) {
    case 'carded':
      return RowHeights.lg;
    case 'grid':
      return RowHeights.xs;
    case 'striped':
    case 'default-compact':
      return RowHeights.sm;
    default:
      return RowHeights.md;
  }
};

export interface TableProps
  extends Omit<DataGridPremiumProps, 'rowHeight' | 'pagination' | 'loading'> {
  variant?: TableVariant;
  toolbar?: ReactNode;
  containerProps?: BoxProps;
  hideHeaders?: boolean;
  pagination?: boolean;
  hasBorder?: boolean;
  freeHeight?: boolean;
  hideFilterToolbar?: boolean;
  loading: boolean;
  onPaginationModelChange?: (pagination: GridPaginationModel) => void;
}

const StyledTableContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  border-radius: ${radii.large};
`;

const StyledTableToolBar = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: ${spacings.xlarge};
  font-weight: 700;
`;

const StyledTablePagination = styled(Pagination)`
  .MuiPaginationItem-root {
    box-shadow: ${shadows.default};
    border: none;
    padding: ${spacings.large};
    background: ${Colors.white};
    border-radius: ${radii.medium};
    max-height: ${iconSizes.large};
  }
  .MuiPaginationItem-root.Mui-selected {
    background: black;
    color: white;
  }
  .MuiPagination-ul li {
    font: ${fonts.button};
    color: black;
  }
  .MuiPagination-ul li:first-of-type .MuiPaginationItem-root::after {
    content: 'Previous';
    padding: ${spacings.small};
  }
  .MuiPagination-ul li:last-child .MuiPaginationItem-root::before {
    content: 'Next';
    padding: ${spacings.small};
  }
`;

const shouldForwardProp = makeShouldForwardProps(['variant', 'apiRef']);
const StyledTable = styled(DataGridPremium, {
  shouldForwardProp
})<TableProps>`
  min-height: 300px;
  border: 0;
  .MuiDataGrid-footerContainer {
    justify-content: center;
    padding: ${spacings.medium};
  }

  .MuiDataGrid-toolbarContainer {
    .MuiButtonBase-root {
      color: ${Colors.emperor};
    }
  }

  ${({ variant }) => {
    switch (variant) {
      case 'carded':
        return css`
          .MuiDataGrid-footerContainer {
            justify-content: center;
          }

          .MuiDataGrid-row {
            .MuiBox-root {
              align-items: center;
              height: 100%;
            }
          }

          background-color: ${Colors.transparent};
          .MuiDataGrid-main {
            .MuiDataGrid-row {
              border-radius: ${radii.large};
              box-shadow: ${shadows.default};
              padding-inline: ${spacings.none};
              .MuiDataGrid-cell:focus-within {
                outline: none;
              }
            }
          }

          .MuiDataGrid-virtualScroller {
            overflow: hidden;
          }
        `;
      case 'grid':
        return css`
          .MuiDataGrid-cell {
            border: 1px solid ${Colors.alto};
            font-weight: ${fontWeights.extraBold};
            color: ${Colors.emperor};
          }
        `;
      case 'striped':
        return css`
          .MuiDataGrid-columnHeaders,
          .MuiDataGrid-row:nth-of-type(even),
          .MuiDataGrid-row:nth-of-type(even):hover {
            background-color: ${Colors.lightGray};
          }

          .MuiDataGrid-columnHeaders,
          .MuiDataGrid-cell {
            border-style: none;
          }
        `;
      case 'default':
      default:
        return css`
          .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within {
            outline: none;
          }

          .MuiDataGrid-row {
            border-style: solid;
            border-width: 0 0 1px;
            border-color: ${Colors.alto};
          }
          .MuiDataGrid-columnHeadersInner {
            border-style: solid;
            border-width: 0 0 6px;
            border-color: ${Colors.alto};
          }
          .MuiDataGrid-columnHeaderTitle {
            color: ${Colors.silverChalice};
            font: ${fonts.badge};
            font-weight: ${fontWeights.extraBold};
          }
        `;
    }
  }};

  .MuiDataGrid-main {
    border-radius: 0;
  }

  .MuiDataGrid-columnHeader,
  .MuiDataGrid-cell {
    color: ${Colors.emperor};
    display: flex;
    align-items: center;
    padding: 0;
  }

  .MuiDataGrid-row {
    width: calc(100% - 2px);
    margin: 0 auto;
    --rowBorderColor: transparent;
  }

  ${({ columns }) =>
    columns
      ? css`
          .MuiDataGrid-cell[aria-colindex='${columns.length}'],
          .MuiDataGrid-columnHeader[aria-colindex='${columns.length}'] {
            padding-inline-end: ${spacings.medium};
          }

          .MuiDataGrid-cell[aria-colindex='1'],
          .MuiDataGrid-columnHeader[aria-colindex='1'] {
            padding-inline-start: ${spacings.medium};
          }
        `
      : ''}

  .MuiDataGrid-columnHeaders {
    border-radius: 0;

    & > [role='row'] {
      background-color: ${Colors.white};
    }

    ${({ hideHeaders }) =>
      hideHeaders
        ? css`
            display: none;
          `
        : ''}
  }

  ${({ hasBorder }) =>
    !hasBorder
      ? css`
          .MuiDataGrid-withBorderColor {
            border-color: transparent;
          }
        `
      : ''}

  .MuiDataGrid-row:hover {
    background-color: initial;
  }

  .MuiDataGrid-footerContainer {
    border: 0;
  }

  .MuiDataGrid-columnHeader:focus,
  .MuiDataGrid-columnHeader:focus-within,
  .MuiDataGrid-cell:focus,
  .MuiDataGrid-cell:focus-within {
    outline: none;
  }
  .MuiDataGrid-columnSeparator {
    display: none;
  }

  .MuiDataGrid-menuIcon {
    margin-right: 0;
  }

  .MuiDataGrid-columnHeader--pinnedLeft,
  .MuiDataGrid-cell--pinnedLeft {
    background: ${Colors.white};
  }

  ${({ freeHeight, variant = 'default' }) =>
    freeHeight &&
    css`
      .MuiDataGrid-main {
        .MuiDataGrid-row,
        .MuiDataGrid-cell {
          min-height: ${getCustomRowHeight(variant)}px !important;
          max-height: inherit !important;
        }
      }

      .MuiDataGrid-virtualScroller {
        overflow-x: hidden;
      }
    `}

  .loading {
    opacity: 50%;
  }
`;

const StyledLoaderBox = styled(Box)`
  display: flex;
  min-height: 60px;
  justify-content: center;
  align-items: center;
  background: ${Colors.defaultMuiGridOverlay};
  height: 100%;
`;

const StyledColumnMenu = styled(GridColumnMenu)`
  .MuiSvgIcon-root {
    color: ${Colors.emperor};
  }

  .MuiButtonBase-root {
    color: ${Colors.emperor};

    &:hover {
      background-color: ${Colors.cupidAlpha25};
    }
  }
`;

const StyledColumnPanel = styled(GridColumnsPanel)`
  .MuiDataGrid-columnsPanelRow {
    color: ${Colors.emperor};
  }

  .MuiSwitch-root {
    width: 50px;

    .MuiSwitch-track {
      border-radius: ${radii.full};
    }

    .MuiSwitch-switchBase {
      margin-top: 2px;
      margin-left: 2px;
      &.Mui-checked {
        color: ${Colors.cupid};
        &:hover {
          background-color: ${Colors.cupidAlpha25};
        }
        .MuiSwitch-thumb {
          background-color: ${Colors.cupid};
        }
      }
    }

    .Mui-checked + .MuiSwitch-track {
      background-color: ${Colors.cupid};
    }
  }

  .MuiFormLabel-root {
    color: ${Colors.alto2};
    &.Mui-focused {
      color: ${Colors.emperor};
    }
  }
  .MuiInputBase-root {
    color: ${Colors.emperor};
    &:hover {
      &::before {
        border-color: ${Colors.emperor};
      }
    }
    &::after {
      border-color: ${Colors.cupid};
    }
  }

  .MuiButtonBase-root.MuiButton-text {
    color: ${Colors.emperor};
    &:hover {
      background-color: ${Colors.cupidAlpha25};
    }
  }
`;

const StyledFilterPanel = styled(GridFilterPanel)`
  .MuiFormLabel-root {
    color: ${Colors.alto2};
    &.Mui-focused {
      color: ${Colors.emperor};
    }
  }
  .MuiInputBase-root {
    color: ${Colors.emperor};
    &:hover {
      &::before {
        border-color: ${Colors.emperor};
      }
    }
    &::after {
      border-color: ${Colors.cupid};
    }
  }

  .MuiButtonBase-root {
    color: ${Colors.emperor};
    &:hover {
      background-color: ${Colors.cupidAlpha25};
    }
  }
  .MuiSvgIcon-root {
    color: ${Colors.emperor};
  }
`;

interface TablePaginationProps extends MUIPaginationProps {
  onPaginationModelChange: (pageModel: GridPaginationModel) => void;
  pageModel: GridPaginationModel;
}

const TablePagination: FC<TablePaginationProps> = ({
  onPaginationModelChange,
  count,
  pageModel
}) => {
  const page = pageModel.page;
  const pageCount = count;

  return (
    <StyledTablePagination
      shape="rounded"
      page={page + 1}
      count={pageCount}
      onChange={(_, value) => {
        const newPage = value - 1;
        onPaginationModelChange?.({ ...pageModel, page: newPage });
      }}
    />
  );
};

export const dateColumn: GridColTypeDef<Date, string> = {
  ...GRID_DATE_COL_DEF,
  filterOperators: getGridDateOperators(false)
    .filter((item) => item.value !== 'isEmpty' && item.value !== 'isNotEmpty')
    .map((item) => ({
      ...item,
      InputComponent: GridFilterDateInput
    })),
  valueGetter: (value) => {
    return value ? dayjs(value).toDate() : null;
  }
};

function GridFilterDateInput(props: GridFilterInputValueProps): JSX.Element {
  const { item, applyValue, apiRef } = props;

  useEffect(() => {
    handleFilterChange(null); // set default value
  }, []);

  const handleFilterChange = (newValue: Date | null) => {
    applyValue({
      ...item,
      value: newValue ? dayjs(newValue).toDate() : null
    });
  };

  return (
    <DatePicker
      value={dayjs(item?.value).toDate()}
      autoFocus
      onChange={handleFilterChange}
      label={apiRef.current.getLocaleText('filterPanelInputLabel')}
      variant={DatePickerVariant.TABLE_FILTER}
      noBorder
      noShadow
    />
  );
}

const Table: FC<TableProps> = ({
  columns,
  rows,
  rowCount,
  variant = 'default',
  toolbar,
  containerProps,
  onPaginationModelChange,
  paginationMode = 'client',
  pagination = true,
  paginationModel = { page: 0, pageSize: 5 },
  hideFilterToolbar,
  getRowHeight = () => 'auto',
  ...otherProps
}) => {
  const [pageModel, setPageModel] = useState(paginationModel);

  const handlePaginationModelChange = (
    paginationModel: GridPaginationModel
  ) => {
    onPaginationModelChange?.(paginationModel);
    setPageModel(paginationModel);
  };
  const apiRef = useGridApiRef();
  const calculatedRowCount =
    rowCount || rows.length
      ? Math.ceil((rowCount || rows.length) / pageModel.pageSize)
      : 0; // TODO - Update this to use rowCount only when the all the Tables are configured to support only server-side pagination.

  return (
    <StyledTableContainer sx={{ height: '100%' }} {...containerProps}>
      {toolbar && (
        <StyledTableToolBar sx={{ height: '15%' }}>
          {toolbar}
        </StyledTableToolBar>
      )}

      <StyledTable
        apiRef={apiRef}
        rows={rows}
        rowCount={rowCount}
        columns={columns}
        variant={variant}
        paginationModel={{ ...pageModel }}
        pagination={pagination}
        paginationMode={paginationMode}
        rowHeight={getCustomRowHeight(variant)}
        disableRowSelectionOnClick
        disableDensitySelector
        getRowHeight={getRowHeight}
        slots={{
          toolbar: () => (hideFilterToolbar ? <></> : <GridToolbar />),
          loadingOverlay: () => (
            <StyledLoaderBox>
              <Loader size={iconSizes.large} />
            </StyledLoaderBox>
          ),
          pagination: pagination
            ? () => (
                <TablePagination
                  onPaginationModelChange={handlePaginationModelChange}
                  count={calculatedRowCount}
                  pageModel={pageModel}
                />
              )
            : () => null,
          columnMenu: StyledColumnMenu,
          filterPanel: StyledFilterPanel,
          columnsPanel: StyledColumnPanel
        }}
        {...otherProps}
      />
    </StyledTableContainer>
  );
};

export default Table;
