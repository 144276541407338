import { useMutation } from 'react-query';

import { AppError } from '../types/global';
import { verifyReCaptchaRequest } from 'src/api/auth.api';

function useAuth() {
  return {
    verifyReCaptcha: () =>
      // verifyReCaptcha creates a new mutation instance every time it gets
      // called (it does not persist state between calls) as we need to verify client user is not a bot.
      useMutation<boolean, AppError, { token: string }>(({ token }) =>
        verifyReCaptchaRequest(token)
      )
  };
}

export default useAuth;
