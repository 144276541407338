import { FC, useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { GridRowParams } from '@mui/x-data-grid-premium';

import { Appointment } from 'src/types/appointment';
import useAppointments from 'src/hooks/useAppointments';
import { useGetScheduleColumns } from 'src/modules/patients/overview/useGetScheduleColumns';
import { Colors } from 'src/components/styles/colors';
import { fontWeights } from 'src/components/styles/fonts';
import { pageSizes } from 'src/components/styles/constants';
import { ScheduleTable } from 'src/modules/patients/overview/ScheduleCard';
import Typography from 'src/components/display/Typography';
import MiniCalendar from 'src/components/display/MiniCalendar';
import { DrawerContainerCard } from './DailyWorkListDrawer';

export const DailyWorkList: FC<{
  date: Dayjs;
  onDateChange: (date: Dayjs) => void;
  onRowClick: (params: {
    patientId: string;
    appointment?: Appointment | null;
  }) => void;
}> = ({ date, onDateChange, onRowClick }) => {
  const { t } = useTranslation();
  const { getColumns } = useGetScheduleColumns();

  const [startOfDay, endOfDay] = useMemo(() => {
    return [date.startOf('day'), date.endOf('day')];
  }, [date]);

  const { getAggregatedAppointments } = useAppointments();
  const {
    data: appointments,
    isLoading: isAggregatedAppointmentsLoading,
    isFetching: isAggregatedAppointmentsFetching
  } = getAggregatedAppointments({
    minDate: startOfDay,
    maxDate: endOfDay
  });

  const columns = getColumns({
    hideLaunchMeetingButton: true,
    hideDate: true
  });
  const isLoading =
    isAggregatedAppointmentsLoading || isAggregatedAppointmentsFetching;

  return (
    <DrawerContainerCard backgroundColor={Colors.dawnPink}>
      <Typography variant="h1" fontWeight={fontWeights.extraBold}>
        {t('DAILY_WORK_LIST')}
      </Typography>
      <MiniCalendar
        onChange={(newDate) => onDateChange(dayjs(newDate))}
        view="week"
        fullWidth
        value={date}
      />
      <ScheduleTable
        hideHeaders
        variant="carded"
        getRowSpacing={() => ({ bottom: 10 })}
        columns={columns}
        rows={appointments || []}
        paginationModel={{ page: 0, pageSize: pageSizes.SMALL }}
        loading={isLoading}
        onRowClick={({ row: appointment }: GridRowParams<Appointment>) => {
          const { patient } = appointment;
          onRowClick({
            patientId: patient.id,
            appointment
          });
        }}
      />
    </DrawerContainerCard>
  );
};
