import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import PatientsSearchBar from 'src/layouts/CollapsedSidebarLayout/Header/PatientsSearchBar';
import Button from 'src/components/display/Button';
import Flex from 'src/components/layout/Flex';
import { spacings } from 'src/components/styles/constants';
import Typography from 'src/components/display/Typography';
import Loader from 'src/components/display/Loader';
import Box from 'src/components/layout/Box';
import { SearchFieldVariants } from 'src/components/data-entry/SearchField/SearchField';
import { downloadBlobData } from 'src/components/utils';
import useGeneralApi from 'src/hooks/useGeneralApi';
import { longDateTimeFormat } from 'src/utils/dateAndTIme';
import { PatientPersonalInfo } from 'src/types/patient';

const DataExportTab: FC = () => {
  const [selectedPatientId, setSelectedPatientId] = useState<string>('');

  const { t } = useTranslation();
  const { getPatientData } = useGeneralApi();

  const {
    refetch: fetchPatientData,
    isLoading: isExporting,
    isRefetching: isReExportingData,
    data: blobToDownload,
    remove: removeBlobToDownload
  } = getPatientData(selectedPatientId);

  useEffect(() => {
    if (blobToDownload) {
      downloadBlobData(
        blobToDownload,
        `${selectedPatientId}-${dayjs().format(longDateTimeFormat)}.zip`
      );
    }
  }, [blobToDownload, selectedPatientId]);

  const handleSelectPatient = (patient: PatientPersonalInfo) => {
    if (blobToDownload) {
      removeBlobToDownload();
    }
    setSelectedPatientId(patient.id);
  };

  const isDisabled = !selectedPatientId || isExporting || isReExportingData;
  const isLoading = isExporting || isReExportingData;

  return (
    <Flex
      justifyContent="space-between"
      flexDirection="column"
      gap={spacings.large}
    >
      <Typography variant="h2">{t('DATA_EXPORT_HEADER')}</Typography>
      <Flex flexDirection="column" gap={spacings.xsmall}>
        <Typography variant="body1">
          {t('DATA_EXPORT_DESCRIPTION_1')}
        </Typography>
        <Typography variant="body1">
          {t('DATA_EXPORT_DESCRIPTION_2')}
        </Typography>
      </Flex>
      <Typography variant="body1">
        {t('DATA_EXPORT_SUB_DESCRIPTION')}
      </Typography>
      <Box maxWidth="50%">
        <PatientsSearchBar
          fullWidth={false}
          placeholder={t('SEARCH_PATIENT_PLACEHOLDER')}
          variant={SearchFieldVariants.DEFAULT}
          onSelectPatient={handleSelectPatient}
          onSearchTermClear={() => {
            setSelectedPatientId('');
          }}
          label={t('PATIENT_LABEL')}
        />
      </Box>
      <Button onClick={() => fetchPatientData()} disabled={isDisabled}>
        {isLoading ? t('EXPORTING_DATA') : t('GENERATE_EXPORT_BUTTON')}
        <Box margin={spacings.small}>{isLoading && <Loader />}</Box>
      </Button>
    </Flex>
  );
};

export default DataExportTab;
