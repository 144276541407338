import { FC } from 'react';
import {
  TimePicker as MuiTimePicker,
  TimePickerProps as MuiTimePickerProps
} from '@mui/x-date-pickers';
import { styled } from '@mui/system';
import Box from 'src/components/layout/Box';
import { spacings } from 'src/components/styles/constants';
import { fonts } from 'src/components/styles/fonts';
import { Colors } from 'src/components/styles/colors';
import InputLabel from 'src/components/data-entry/InputLabel';
import { InputFieldProps } from '../InputField';
import { makeShouldForwardProps } from 'src/components/utils';

interface TimePickerProps
  extends MuiTimePickerProps<Date>,
    Pick<InputFieldProps, 'helperText' | 'error' | 'fullWidth' | 'required'> {}

const shouldForwardProp = makeShouldForwardProps(['error']);

export const StyledTimePickerContainer = styled(Box, {
  shouldForwardProp
})<TimePickerProps>`
  .MuiInputBase-root {
    height: 40px;
    color: ${Colors.emperor};
  }

  .MuiInputBase-root .MuiButtonBase-root {
    color: ${Colors.emperor};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ error }) =>
      error ? Colors.alizarinCrimson : Colors.emperor};
    border-width: 1pt;
    box-shadow: 0px 0px 5px rgba(112, 112, 112, 0.5);
  }

  .MuiFormControl-root.MuiTextField-root {
    width: 100%;
  }

  .MuiFormHelperText-root {
    margin: 3px ${spacings.none} ${spacings.none} ${spacings.none};
    color: ${({ error }) => (error ? Colors.alizarinCrimson : Colors.emperor)};
    font: ${fonts.caption};
  }
`;

const TimePicker: FC<TimePickerProps> = ({
  label,
  helperText,
  error,
  fullWidth,
  required,
  ...otherProps
}) => {
  return (
    <StyledTimePickerContainer
      error={error}
      width={fullWidth ? '100%' : 'auto'}
    >
      {label && <InputLabel label={label} error={error} required={required} />}
      <Box width="100%">
        <MuiTimePicker
          {...otherProps}
          ampm
          format="hh:mm aa"
          slotProps={{
            textField: {
              helperText,
              placeholder: '--:-- AM'
            }
          }}
        />
      </Box>
    </StyledTimePickerContainer>
  );
};

export default TimePicker;
