import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { styled } from '@mui/system';

import Button from 'src/components/display/Button/Button';
import { Appointment } from 'src/types/appointment';
import { spacings } from 'src/components/styles/constants';
import Box from 'src/components/layout/Box';
import InputField from 'src/components/data-entry/InputField';
import Flex from 'src/components/layout/Flex';
import useAppointments from 'src/hooks/useAppointments';
import { useDialog } from 'src/contexts/UIContexts';

interface AppointmentCancellationDialogProps {
  appointment: Appointment;
}

interface AppointmentCancellationForm {
  cancellationReason: string;
}

const StyledButton = styled(Button)`
  padding: 0;
  padding-top: ${spacings.xlarge};
  display: flex;
`;

export const AppointmentCancellationDialog: FC<
  AppointmentCancellationDialogProps
> = ({ appointment }) => {
  const { t } = useTranslation();
  const { closeDialog } = useDialog();

  const { cancelAppointment } = useAppointments();
  const { mutate: cancelAppointmentMutate, isLoading: isDeletingAppointment } =
    cancelAppointment();

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<AppointmentCancellationForm>({
    mode: 'onChange',
    defaultValues: {
      cancellationReason: ''
    }
  });

  const onSubmit = async (data: AppointmentCancellationForm) => {
    await cancelAppointmentMutate({
      appointmentId: appointment.id,
      patientId: appointment.patientId,
      cancellationReason: data.cancellationReason
    });
    closeDialog();
  };

  return (
    <Box paddingTop={spacings.large}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Box marginBottom={spacings.medium}>
          <Controller
            name="cancellationReason"
            control={control}
            rules={{
              required: t('MUST_SPECIFIY_REASON')
            }}
            render={({ field: { onChange, value } }) => (
              <InputField
                onChange={onChange}
                value={value}
                fullWidth
                label={t('CANCELLATION_REASON')}
                error={!!errors.cancellationReason}
                helperText={errors?.cancellationReason?.message}
              />
            )}
          />
        </Box>
        <Flex justifyContent="space-between">
          <Box>
            <StyledButton
              onClick={closeDialog}
              bgColor="transparent"
              textColor="emperor"
            >
              {t('CANCEL_ACTION')}
            </StyledButton>
          </Box>
          <Box width="30%">
            <Button disabled={isDeletingAppointment} fullWidth type="submit">
              {t('CONFIRM')}
            </Button>
          </Box>
        </Flex>
      </form>
    </Box>
  );
};
