import { FC, useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import dayjs from 'dayjs';

import Flex from 'src/components/layout/Flex/Flex';
import Box from 'src/components/layout/Box/Box';
import Button from 'src/components/display/Button/Button';
import MiniIconButton from 'src/components/display/MiniIconButton/MiniIconButton';
import { spacings } from 'src/components/styles/constants';
import Typography from 'src/components/display/Typography/Typography';
import InputField from 'src/components/data-entry/InputField/InputField';
import DatePicker from 'src/components/data-entry/DatePicker/DatePicker';
import Select from 'src/components/data-entry/Select/Select';
import TimePicker from 'src/components/data-entry/TimePicker/TimePicker';
import Loader from 'src/components/display/Loader/Loader';
import { getFullName } from 'src/utils/general';
import {
  InCycleAppointmentTypes,
  AppointmentPurposes
} from 'src/types/appointment';
import usePatientsApi from '../../../hooks/usePatientsApi';
import InputLabel from '../../../components/data-entry/InputLabel/InputLabel';
import {
  NewCycleFormValues,
  CycleScheduleForm as CycleScheduleFormType
} from '../../../types/cycle';
import { StyledForm } from './MedicationProtocolForm';

interface CycleScheduleFormProps {
  patientId: string;
  handleSubmitForm: (details: CycleScheduleFormType) => void;
  cycleData?: NewCycleFormValues;
  handleClickBack?: () => void;
}

const CycleScheduleForm: FC<CycleScheduleFormProps> = ({
  patientId,
  handleSubmitForm,
  cycleData,
  handleClickBack
}) => {
  const { t } = useTranslation();
  const { getPatientById } = usePatientsApi();

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);

  const appointmentTypes = {
    ...InCycleAppointmentTypes
  };

  const sevenAm = dayjs(cycleData.startDate)
    .set('hour', 7)
    .set('minute', 0)
    .set('second', 0)
    .toDate();

  const defaultAppointmentsCounter = Array(4);

  const defaultValues: CycleScheduleFormType = {
    patientName: '',
    cycleDayOne: dayjs(cycleData.cycleDayOne).toDate(),
    appointments: defaultAppointmentsCounter.fill(0).map(() => ({
      appointmentPurpose: AppointmentPurposes.IN_CYCLE,
      appointmentType: appointmentTypes.BASELINE_IUI,
      date: sevenAm,
      startTime: sevenAm,
      isVirtual: false,
      endTime: dayjs(sevenAm).add(1, 'hour').toDate()
    }))
  };

  const { control, formState, handleSubmit, setValue, getValues, watch } =
    useForm<CycleScheduleFormType>({
      mode: 'onChange',
      defaultValues
    });
  const {
    fields: appointments,
    append,
    remove
  } = useFieldArray({
    control,
    name: 'appointments'
  });

  const { cycleDayOne, appointments: watchedAppointments } = watch();

  useEffect(() => {
    if (cycleDayOne) {
      watchedAppointments?.forEach((appointment, index) => {
        const { startTime: currentStartTime } = getValues(
          `appointments.${index}`
        );

        const [currHour, currMin] = [
          dayjs(currentStartTime).hour(),
          dayjs(currentStartTime).minute()
        ];

        const adjustedStartTime = dayjs(cycleDayOne)
          .set('hour', currHour)
          .set('minute', currMin)
          .toDate();

        const adjustedEndTime = dayjs(adjustedStartTime)
          .add(1, 'hour')
          .toDate();

        setValue(`appointments.${index}.date`, adjustedStartTime);
        setValue(`appointments.${index}.endTime`, adjustedEndTime);
      });
    }
  }, [cycleDayOne]);

  useEffect(() => {
    if (!patient) return;
    setValue('patientName', getFullName(patient.personalInfo));
  }, [patient]);

  const { errors } = formState;

  const isLoading = isLoadingPatient;

  if (isLoading) return <Loader />;

  const onSubmit = async (details: CycleScheduleFormType) => {
    handleSubmitForm(details);
  };

  const handleDateTime = ({
    dateTime,
    index,
    onChange,
    isStartTime = false
  }: {
    dateTime: Date;
    index: number;
    onChange: (date: Date) => void;
    isStartTime?: boolean;
  }) => {
    const chosenDateTime = dayjs(dateTime).toDate();
    const { startTime: currentStartTime, date: currentDate } = getValues(
      `appointments.${index}`
    );

    const [currHour, currMin] = [
      dayjs(currentStartTime).hour(),
      dayjs(currentStartTime).minute()
    ];

    const [chosenHour, chosenMin] = [
      dayjs(dateTime).hour(),
      dayjs(dateTime).minute()
    ];

    const adjustedStartTime = dayjs(isStartTime ? currentDate : chosenDateTime)
      .set('hour', isStartTime ? chosenHour : currHour)
      .set('minute', isStartTime ? chosenMin : currMin)
      .toDate();

    const adjustedEndTime = dayjs(adjustedStartTime).add(1, 'hour').toDate();

    setValue(`appointments.${index}.date`, adjustedStartTime);
    setValue(`appointments.${index}.endTime`, adjustedEndTime);
    onChange(adjustedStartTime);
  };

  return (
    <Box marginTop={spacings.xlarge} height="100%">
      <StyledForm noValidate onSubmit={handleSubmit(onSubmit)}>
        <Flex alignItems="flex-start" gap={spacings.x2large}>
          <Box alignSelf="center" visibility="hidden">
            <InputLabel label="" />
            <MiniIconButton icon={<></>} />
          </Box>
          <Box width="40%">
            <Controller
              name="patientName"
              control={control}
              rules={{
                required: t('PATIENT_NAME_REQUIRED')
              }}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={t('PATIENT_NAME_LABEL')}
                  placeholder={t('PATIENT_NAME_PLACEHOLDER')}
                  error={!!errors.patientName}
                  helperText={errors?.patientName?.message}
                  required
                  disabled
                  fullWidth
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="cycleDayOne"
              control={control}
              rules={{
                required: t('CYCLE_DAY_1_REQUIRED')
              }}
              render={({ field: { ref, ...field } }) => (
                <DatePicker
                  {...field}
                  inputRef={ref}
                  label={t('CYCLE_DAY_1_LABEL').toUpperCase()}
                  error={!!errors.cycleDayOne}
                  helperText={errors?.cycleDayOne?.message}
                  fullWidth
                />
              )}
            />
          </Box>
          <Box flex={1} visibility="hidden"></Box>
        </Flex>
        {appointments.map((appointment, index) => (
          <Flex
            alignItems="center"
            key={appointment.id}
            marginTop={spacings.xlarge}
            gap={spacings.x2large}
          >
            <Box alignSelf="center">
              <InputLabel label="" />
              <MiniIconButton
                icon={<RemoveIcon />}
                onClick={() => remove(index)}
              />
            </Box>
            <Box width="40%">
              <Controller
                name={`appointments.${index}.appointmentType`}
                control={control}
                rules={{
                  required: t('APPOINTMENT_TYPE_REQUIRED')
                }}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    inputRef={ref}
                    label={t('APPOINTMENT_TYPE_LABEL')}
                    error={!!errors.appointments?.[index]?.appointmentType}
                    helperText={errors.appointments?.[index]?.appointmentType}
                    defaultOption={t('APPOINTMENT_TYPE_DEFAULT_OPTION')}
                    options={Object.entries(appointmentTypes).map(
                      ([label, value]) => ({
                        label: t(label),
                        value
                      })
                    )}
                  />
                )}
              />
            </Box>
            <Box flex={1}>
              <Controller
                name={`appointments.${index}.date`}
                control={control}
                rules={{
                  required: t('APPOINTMENT_DATE_REQUIRED')
                }}
                render={({ field: { ref, onChange, ...field } }) => (
                  <DatePicker
                    {...field}
                    label={t('APPOINTMENT_DATE').toUpperCase()}
                    inputRef={ref}
                    minDate={cycleDayOne}
                    onChange={(date) =>
                      handleDateTime({ dateTime: date, index, onChange })
                    }
                    error={!!errors.appointments?.[index]?.date}
                    helperText={errors.appointments?.[index]?.date?.message}
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box flex={1}>
              <Controller
                name={`appointments.${index}.startTime`}
                control={control}
                rules={{
                  required: t('APPOINTMENT_TIME_REQUIRED')
                }}
                render={({ field: { ref, onChange, ...field } }) => (
                  <TimePicker
                    {...field}
                    label={t('TIME')}
                    inputRef={ref}
                    ampm={false}
                    onChange={(time) =>
                      handleDateTime({
                        dateTime: time,
                        index,
                        onChange,
                        isStartTime: true
                      })
                    }
                    error={!!errors.appointments?.[index]?.startTime}
                    helperText={
                      errors.appointments?.[index]?.startTime?.message
                    }
                    fullWidth
                  />
                )}
              />
            </Box>
          </Flex>
        ))}
        <Flex
          alignItems="center"
          marginTop={spacings.xlarge}
          gap={spacings.x2large}
        >
          <MiniIconButton
            icon={<AddIcon />}
            onClick={() =>
              append({
                appointmentPurpose: AppointmentPurposes.IN_CYCLE,
                appointmentType: appointmentTypes.BASELINE_IUI,
                date: dayjs().toDate(),
                panelIds: [],
                startTime: dayjs().toDate(),
                isVirtual: false,
                endTime: dayjs().toDate()
              })
            }
          />
          <Typography variant="body1">
            {t('ADD_ANOTHER_APPOINTMENT')}
          </Typography>
        </Flex>
        <Flex mt="20px" justifyContent="space-between">
          <Box width="20%">
            {handleClickBack && (
              <Button
                type="button"
                bgColor="alto"
                textColor="darkGray"
                fullWidth
                onClick={handleClickBack}
              >
                {t('BACK')}
              </Button>
            )}
          </Box>
          <Box width="20%">
            <Button fullWidth type="submit">
              {t('CONFIRM_SCHEDULE')}
            </Button>
          </Box>
        </Flex>
      </StyledForm>
    </Box>
  );
};

export default CycleScheduleForm;
