import { styled } from '@mui/system';
import { FC, useState } from 'react';
import { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Autocomplete from 'src/components/data-entry/Autocomplete';
import SearchField, {
  SearchFieldProps,
  SearchFieldVariants
} from 'src/components/data-entry/SearchField/SearchField';
import { Colors } from 'src/components/styles/colors';
import { spacings } from 'src/components/styles/constants';
import { makeShouldForwardProps } from 'src/components/utils';
import usePatientsApi from 'src/hooks/usePatientsApi';
import { PatientPersonalInfo } from 'src/types/patient';
import { getFullName } from 'src/utils/general';

interface PatientsSearchBarProps extends Omit<SearchFieldProps, 'onChange'> {
  onSelectPatient: (patient: PatientPersonalInfo) => void;
  startIcon?: React.ReactElement;
  variant?: SearchFieldVariants;
  onSearchTermClear?: () => void;
  errors?: FieldErrors<any>;
}

const StyledListItem = styled('li')`
  &.Mui-focused {
    && {
      background-color: ${Colors.vistaWhite};
    }
  }
`;

const StyledAutocomplete = styled(Autocomplete, {
  shouldForwardProp: makeShouldForwardProps(['variant'])
})<{ variant: SearchFieldVariants }>`
  .MuiInputBase-root.MuiOutlinedInput-root {
    padding: ${({ variant }) =>
      variant === SearchFieldVariants.STYLED ? spacings.medium : 0};
  }
`;

export const PatientsSearchBar: FC<PatientsSearchBarProps> = ({
  startIcon,
  variant,
  onSearchTermClear,
  onSelectPatient,
  fullWidth = true,
  placeholder,
  errors,
  label
}) => {
  const [chosenPatient, setChosenPatient] = useState<PatientPersonalInfo | {}>(
    {}
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState<boolean>(false);

  const { t } = useTranslation();
  const { searchPatients } = usePatientsApi();

  const { data: patients, isLoading: isLoadingPatients } = searchPatients(
    searchTerm,
    { enabled: searchTerm.length >= 2 }
  );

  if (!chosenPatient) {
    return <></>;
  }

  const handleSelectPatient = (patient: PatientPersonalInfo) => {
    setSearchTerm('');
    setIsAutocompleteOpen(false);
    setChosenPatient(patient);
    onSelectPatient(patient);
  };

  return (
    <StyledAutocomplete
      variant={variant}
      fullWidth={fullWidth}
      open={isAutocompleteOpen}
      options={patients || []}
      role="list-box"
      disableClearable
      value={chosenPatient}
      onChange={(_, patient: PatientPersonalInfo) => {
        handleSelectPatient(patient);
      }}
      isLoading={isLoadingPatients}
      getOptionLabel={(option: PatientPersonalInfo) => getFullName(option)}
      renderOption={(props, patient: PatientPersonalInfo) => {
        return (
          <StyledListItem
            {...props}
            onClick={() => handleSelectPatient(patient)}
          >
            {getFullName(patient)}
          </StyledListItem>
        );
      }}
      renderInput={(params) => (
        <SearchField
          placeholder={placeholder}
          inputRef={params.InputProps.ref}
          error={!!errors?.patient}
          helperText={errors?.patient?.message}
          label={label && t('PATIENT_LABEL')}
          inputProps={{ ...params.inputProps, id: 'patients-search-bar' }}
          startIcon={startIcon}
          searchFieldVariant={variant}
          onChange={(value) => {
            if (value.length >= 2) {
              setIsAutocompleteOpen(true);
            } else {
              setIsAutocompleteOpen(false);
            }
            setSearchTerm(value);
          }}
          onSearchTermClear={() => {
            setChosenPatient({});
            setSearchTerm('');
            onSearchTermClear?.();
          }}
          value={searchTerm}
        />
      )}
    />
  );
};

export default PatientsSearchBar;
