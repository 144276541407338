import {
  AppointmentPurposes,
  AppointmentTypes,
  ConsultationAppointmentTypes,
  DefaultClinicRoomOptions,
  DiagnosticAppointmentTypes,
  FinancialAppointmentTypes,
  InCycleAppointmentTypes,
  MaleProcedureAppointmentTypes,
  PregnancyAppointmentTypes,
  SurgeryAppointmentTypes,
  ThirdPartyScreeningAppointmentTypes
} from 'src/types/appointment';
import i18n from '../../../../i18n/i18n';

const appointmentTypeOptionsCycle = Object.entries(InCycleAppointmentTypes).map(
  ([label, value]) => ({
    label: i18n.t(label),
    value
  })
);

const appointmentTypeOptionsConsultation = Object.entries(
  ConsultationAppointmentTypes
).map(([label, value]) => ({
  label: i18n.t(label),
  value
}));

const appointmentTypeOptionsDiagnostic = Object.entries(
  DiagnosticAppointmentTypes
).map(([label, value]) => ({
  label: i18n.t(label),
  value
}));

const appointmentTypeOptionsPregnancy = Object.entries(
  PregnancyAppointmentTypes
).map(([label, value]) => ({
  label: i18n.t(label),
  value
}));

const appointmentTypeThirdPartyScreening = Object.entries(
  ThirdPartyScreeningAppointmentTypes
).map(([label, value]) => ({
  label: i18n.t(label),
  value
}));

const appointmentTypeOptionsFinancial = Object.entries(
  FinancialAppointmentTypes
).map(([label, value]) => ({
  label: i18n.t(label),
  value
}));

const appointmentTypeOptionsMaleProcedures = Object.entries(
  MaleProcedureAppointmentTypes
).map(([label, value]) => ({
  label: i18n.t(label),
  value
}));

const appointmentTypeOptionsSurgery = Object.entries(
  SurgeryAppointmentTypes
).map(([label, value]) => ({
  label: i18n.t(label),
  value
}));

export const appointmentPurposeOptions = Object.entries(
  AppointmentPurposes
).map(([key, value]) => ({
  label: i18n.t(key),
  value: value
}));

export const defaultRoomOptions = Object.values(DefaultClinicRoomOptions).map(
  (value) => ({
    value,
    label: i18n.t(value)
  })
);

export const getAppointmentTypeOptions = (
  appointmentPurpose: AppointmentPurposes
): { label: string; value: AppointmentTypes }[] => {
  switch (appointmentPurpose) {
    case AppointmentPurposes.IN_CYCLE:
      return appointmentTypeOptionsCycle;
    case AppointmentPurposes.CONSULTATION:
      return appointmentTypeOptionsConsultation;
    case AppointmentPurposes.DIAGNOSTIC:
      return appointmentTypeOptionsDiagnostic;
    case AppointmentPurposes.PREGNANCY:
      return appointmentTypeOptionsPregnancy;
    case AppointmentPurposes.THIRD_PARTY_SCREENING:
      return appointmentTypeThirdPartyScreening;
    case AppointmentPurposes.FINANCIAL:
      return appointmentTypeOptionsFinancial;
    case AppointmentPurposes.MALE_PROCEDURES:
      return appointmentTypeOptionsMaleProcedures;
    case AppointmentPurposes.SURGERY:
      return appointmentTypeOptionsSurgery;
  }
};
