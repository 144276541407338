import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { YesOrNo } from 'src/types/global';
import { OrderFormServer } from 'src/types/patient-server';
import {
  OrderFormParams,
  LabOrderBillTo,
  Urgency
} from 'src/types/appointment';
import Box from 'src/components/layout/Box/Box';
import { spacings } from 'src/components/styles/constants';
import { Colors } from 'src/components/styles/colors';
import Typography from 'src/components/display/Typography';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { OrderForm, OrderType } from '../common/OrderForm';

export const AddExternalOrderForm: FC<{ patientId: string }> = ({
  patientId
}) => {
  const { t } = useTranslation();
  const { createOrder } = usePatientsApi();

  const defaultValues: OrderFormParams = {
    patientId,
    patientName: '',
    purpose: null,
    labTestsIds: [],
    labInstructions: '',
    labOrderIcd10Codes: [],
    proceduresInstructions: '',
    procedureOrderIcd10Codes: [],
    proceduresIds: [],
    requestingPhysician: null,
    billTo: LabOrderBillTo.PATIENT,
    vendorId: null,
    urgency: Urgency.ROUTINE,
    isFasting: YesOrNo.NO
  };

  const { mutate: handleCreateOrder, isLoading: isSubmittingOrder } =
    createOrder();

  const onSubmit = (details: OrderFormParams) => {
    const orderDetails: OrderFormServer = {
      ...details,
      isFasting: details?.isFasting === YesOrNo.YES ? true : false
    };

    handleCreateOrder(orderDetails);
  };

  return (
    <Box marginTop={spacings.large}>
      <Box marginBottom={spacings.x2large}>
        <Typography color={Colors.emperor} variant="h5">
          {t('EXTERNAL_ORDER_FORM_SUB_HEADER')}
        </Typography>
      </Box>
      <OrderForm
        patientId={patientId}
        orderType={OrderType.EXTERNAL}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        isDisabled={isSubmittingOrder}
      />
    </Box>
  );
};
