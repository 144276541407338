import { FC, ReactNode } from 'react';
import { Backdrop } from '@mui/material';
import { styled } from '@mui/system';

import Box from 'src/components/layout/Box';
import { alpha, Colors } from '../../styles/colors';
import { zIndices, spacings, radii } from '../../styles/constants';
import Typography from '../Typography';
import Loader from '../Loader';

export interface LoadingOverlayProps {
  message?: ReactNode;
  open: boolean;
}

const backdropBackground = `${Colors.black}${alpha[2]}`;

const StyledBackdrop = styled(Backdrop)`
  z-index: ${zIndices.highest};
  background: ${backdropBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${spacings.xlarge};
  background: ${Colors.white};
  padding: ${spacings.large};
  border-radius: ${radii.medium};
`;

const StyledTypography = styled(Typography)`
  line-height: ${spacings.x2large};
`;

const LoadingOverlay: FC<LoadingOverlayProps> = ({
  open,
  message = 'Loading...'
}) => {
  const messageIsString = typeof message === 'string';

  return (
    <StyledBackdrop open={open}>
      <StyledBox width={420}>
        {messageIsString ? (
          <StyledTypography variant="h2">{message}</StyledTypography>
        ) : (
          { message }
        )}
        <Loader />
      </StyledBox>
    </StyledBackdrop>
  );
};

export default LoadingOverlay;
