import { FC, ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import ClearIcon from '@mui/icons-material/Clear';

import Chip from 'src/components/data-entry/Chips/Chip';
import InputLabel from 'src/components/data-entry/InputLabel';
import Box from 'src/components/layout/Box';
import Flex from 'src/components/layout/Flex';
import { Colors } from 'src/components/styles/colors';
import { radii, spacings } from 'src/components/styles/constants';
import Icd10Selector from 'src/modules/patients/common/Icd10Selector';
import { Icd10Code } from 'src/types/codes';
import { ChipsProps } from 'src/components/data-entry/Chips/Chips';

const StyledCodesChipsContainer = styled(Box)`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: ${spacings.small};
  padding: ${spacings.small};
  flex: 1;
`;

const StyledCodesChipsLabel = styled(InputLabel)`
  display: flex;
  flex-direction: column;
  gap: ${spacings.large};
  margin-bottom: ${spacings.small};
`;

type BaseChipsProps = Pick<ChipsProps, 'error' | 'helperText' | 'disabled'>;

interface Icd10ChipsBaseProps extends BaseChipsProps {
  label?: string;
  flattenValue?: boolean;
}

type FlattenedChipsProps = {
  flattenValue: true;
  value: string[];
  onChange: (value: string[]) => void;
};

type StructuredChipsProps = {
  flattenValue?: false;
  value: Icd10Code[];
  onChange: (value: Icd10Code[]) => void;
};

export type Icd10ChipsProps = Icd10ChipsBaseProps &
  (FlattenedChipsProps | StructuredChipsProps);

export const Icd10Chips = ({
  error,
  helperText,
  label,
  disabled,
  flattenValue,
  value,
  onChange
}: Icd10ChipsProps): ReactElement => {
  const { t } = useTranslation();

  const Icd10ChipsContent: FC<{
    codes: Icd10Code[] | string[];
    onChange: (value: Icd10Code[] | string[]) => void;
  }> = ({ codes, onChange }) => {
    const uniqueCodes = useMemo(
      () =>
        flattenValue
          ? Array.from(new Set(codes as string[]))
          : Array.from(
              new Set((codes as Icd10Code[]).map((code) => code.code))
            ).map((code) =>
              (codes as Icd10Code[]).find((c) => c.code === code)
            ),
      [codes]
    );

    const codeLabelList = useMemo(() => uniqueCodes as string[], [uniqueCodes]);
    const codeList = useMemo(() => uniqueCodes as Icd10Code[], [uniqueCodes]);

    const handleAddChip = useCallback(
      (newCode: Icd10Code) => {
        if (flattenValue) {
          if (
            newCode &&
            !codeLabelList.some((code) => code === newCode.label)
          ) {
            onChange([...codeLabelList, newCode.label]);
          }
        } else {
          if (newCode && !codeList.some((code) => code.code === newCode.code)) {
            onChange([...codeList, newCode]);
          }
        }
      },
      [codeLabelList, codeList]
    );

    const handleRemoveChip = useCallback(
      (codeToRemove: Icd10Code | string) => {
        if (flattenValue) {
          onChange(codeLabelList.filter((code) => code !== codeToRemove));
        } else {
          const chip = codeToRemove as Icd10Code;
          onChange(codeList.filter((code) => code.code !== chip.code));
        }
      },
      [codeLabelList, codeList]
    );

    return (
      <>
        <Icd10Selector
          error={error}
          helperText={helperText}
          onChange={handleAddChip}
          value={null}
          disabled={disabled}
        />
        <StyledCodesChipsContainer>
          {uniqueCodes.map((code) => {
            return (
              <Chip
                key={!flattenValue ? code.code : code}
                label={!flattenValue ? code.label : code}
                deleteIcon={<ClearIcon />}
                onDelete={() => handleRemoveChip(code)}
                disabled={disabled}
              />
            );
          })}
        </StyledCodesChipsContainer>
      </>
    );
  };

  return (
    <>
      <StyledCodesChipsLabel label={label || t('ENTER_DIAGNOSTIC_CODE')} />
      <Flex
        flexDirection="column"
        border={`1px solid ${Colors.gray}`}
        borderRadius={`${radii.medium}`}
        height="100%"
      >
        <Icd10ChipsContent codes={value} onChange={onChange} />;
      </Flex>
    </>
  );
};
