import { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Box, styled, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { collection, doc, onSnapshot } from 'firebase/firestore';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { firestore } from 'src/utils/firebase';
import useMeApi from 'src/hooks/useMeApi';
import usePatientsApi from 'src/hooks/usePatientsApi';
import { spacings } from 'src/components/styles/constants';
import { fontWeights } from 'src/components/styles/fonts';
import { Colors } from 'src/components/styles/colors';
import Scrollbar from 'src/components/layout/Scrollbar';
import Card from 'src/components/display/Card';
import Typography from 'src/components/display/Typography';
import Flex from 'src/components/layout/Flex/Flex';
import Loader from 'src/components/display/Loader';
import IconButton from 'src/components/display/IconButton';

import BottomBarContent from './BottomBarContent';
import ChatContent from './ChatContent';
import { ChannelTypes } from 'src/types/inbox';
import useMessagesApi from 'src/hooks/useMessages';
import { queryKeys, querySubKeys } from 'src/hooks/queryKeys';
import { getFullName } from 'src/utils/general';
import { makeShouldForwardProps } from 'src/components/utils';
import { CardProps } from 'src/components/display/Card/Card';
import { useAuth } from 'src/contexts/AppContexts/AppContexts';

const shouldForwardPropRoot = makeShouldForwardProps(['isPopover']);
const RootWrapper = styled(Box, {
  shouldForwardProp: shouldForwardPropRoot
})<{ isPopover?: boolean }>`
  flex: 1;
  display: flex;
`;

const ChatWindow = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: white;
`;

const shouldForwardPropCard = makeShouldForwardProps(['expanded', 'maxWidth']);
const StyledCard = styled(Card, {
  shouldForwardProp: shouldForwardPropCard
})<{ expanded: boolean }>`
  padding: ${spacings.xlarge};
  flex: 1;
  display: flex;
  flex-direction: column;
  ${({ expanded, maxWidth }) =>
    expanded
      ? `
          height: 80vh;
          width: 60vw;
        `
      : `
          width: ${maxWidth};
          max-width: ${maxWidth};
        `}
`;

interface ApplicationMessengerProps extends CardProps {
  chatType?: ChannelTypes;
  patientId?: string;
  onClose?: () => void;
  isPopover?: boolean;
  showExpandButton?: boolean;
}

const ApplicationsMessenger: FC<ApplicationMessengerProps> = ({
  patientId: patientIdProp,
  onClose,
  showExpandButton = false,
  maxWidth = '600px',
  width = '100%',
  chatType,
  isPopover = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const { getMe } = useMeApi();
  const { data: me, isLoading: isLoadingMe } = getMe();
  const { getPatientById } = usePatientsApi();
  const { getPatientChannel } = useMessagesApi();
  const { patientId: paramPatientId } = useParams();
  const { getMessages, sendChannelMessage } = useMessagesApi();
  const [expanded, setExpanded] = useState(false);

  const { mutate: sendMessage, isLoading: isSending } = sendChannelMessage();
  const patientId = patientIdProp || paramPatientId;

  const { data: channelData } = getPatientChannel(patientId, chatType, {
    enabled: !!patientId
  });

  const {
    data: messagesData,
    isLoading: isLoadingPatientMessages,
    // Purposely ignore this since it shows a loader when sending/receiving a messeage
    isFetching: _isFetching
  } = getMessages(channelData?.id, { enabled: !!channelData });

  const {
    data: patient,
    isLoading: isLoadingPatient,
    isFetching: isFetchingPatient
  } = getPatientById(patientId);

  const queryClient = useQueryClient();
  const bottomEl = useRef(null);

  const scrollToBottom = () => {
    const container = bottomEl?.current?.lastElementChild?.firstChild;
    const scrollDistance = container.scrollHeight - container.scrollTop;

    container?.lastElementChild?.scrollIntoView({
      behavior: scrollDistance > container.clientHeight ? 'auto' : 'smooth'
    });
  };

  const invalidateMessages = () => {
    queryClient.invalidateQueries([
      queryKeys.CHANNELS,
      channelData?.id,
      querySubKeys[queryKeys.CHANNELS].MESSAGES
    ]);
  };

  useEffect(() => {
    let unsub = () => {};

    if (patientId && channelData) {
      const patientsReadCollections = collection(firestore, 'patients-read');
      const patientsReadDoc = doc(patientsReadCollections, patientId);

      unsub = onSnapshot(patientsReadDoc, () => {
        if (channelData.type === ChannelTypes.Patient) {
          invalidateMessages();
        }
      });
    }
    return unsub;
  }, [patientId, channelData]);

  useEffect(() => {
    let unsub = () => {};

    if (isLoadingMe) {
      return unsub;
    }

    if (patientId && auth.user && me && channelData) {
      const { user } = me;
      const staffCollections = collection(firestore, 'staff');
      const staffDoc = doc(staffCollections, user.id);

      unsub = onSnapshot(staffDoc, () => {
        invalidateMessages();
      });
    }
    return unsub;
  }, [patientId, auth.user, me, isLoadingMe, channelData]);

  useEffect(() => {
    scrollToBottom();
  }, [messagesData]);

  return (
    <StyledCard
      shadow
      expanded={expanded}
      maxWidth={maxWidth}
      width={width}
      {...rest}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        marginBottom={spacings.small}
      >
        <Typography variant="h2">
          {chatType === ChannelTypes.Patient
            ? t('PATIENT_CHAT')
            : t('INTERNAL_CHAT')}
        </Typography>
        <Flex gap={spacings.small}>
          {showExpandButton && (
            <IconButton
              icon={<OpenInFullIcon />}
              iconSize="xsmall"
              bgColor="gray"
              onClick={() => setExpanded((prev) => !prev)}
            />
          )}
          {onClose && (
            <IconButton
              iconSize="xsmall"
              bgColor="gray"
              icon={<CloseIcon />}
              onClick={() => onClose()}
            />
          )}
        </Flex>
      </Flex>
      {!isLoadingPatient && !isFetchingPatient && (
        <Typography
          variant="h4"
          marginBottom={spacings.small}
          fontWeight={fontWeights.extraBold}
        >
          {getFullName(patient?.personalInfo)}
        </Typography>
      )}
      <Box
        borderBottom={`1px solid ${Colors.alto2}`}
        width="100%"
        marginY={spacings.medium}
      />
      <RootWrapper className="Mui-FixedWrapper" isPopover={isPopover}>
        <ChatWindow>
          <Box flex={1} ref={bottomEl}>
            <Scrollbar>
              {isLoadingPatientMessages ? (
                <Flex height="100%" justifyContent="center" alignItems="center">
                  <Loader colorBase={Colors.emperor} />
                </Flex>
              ) : (
                <ChatContent
                  data={messagesData}
                  staffPictureId={me?.user?.pictureId}
                  patientPictureId={patient?.personalInfo?.pictureId}
                />
              )}
            </Scrollbar>
          </Box>
          <Divider />
          <BottomBarContent
            isSending={isSending}
            expanded={expanded}
            sendMessage={(text) => {
              sendMessage({
                channelId: channelData?.id,
                patientId,
                message: text
              });
            }}
          />
        </ChatWindow>
      </RootWrapper>
    </StyledCard>
  );
};

export default ApplicationsMessenger;
