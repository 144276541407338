import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { spacings } from 'src/components/styles/constants';
import { fontWeights } from 'src/components/styles/fonts';
import Typography from 'src/components/display/Typography/Typography';
import Flex from 'src/components/layout/Flex/Flex';
import Box from 'src/components/layout/Box/Box';
import { Colors } from 'src/components/styles/colors';
import Card from 'src/components/display/Card/Card';
import Chips, {
  ChipsVariants,
  NotesList
} from 'src/components/data-entry/Chips/Chips';
import {
  Cycle,
  CycleGameteComment,
  Gamete,
  TreatmentTypes
} from 'src/types/cycle';
import useCycle from 'src/hooks/useCycle';
import useMeApi from 'src/hooks/useMeApi';
import Loader from 'src/components/display/Loader';

const StyledFlexContainer = styled(Flex)`
  justify-content: space-between;
  width: '100%';
  align-items: center;
  flex: 1;
`;

const StyledBorderBox = styled(Box)`
  border-bottom: 1px solid ${Colors.mercury};
  flex: 1;
  width: '100%';
`;

export const EmbryoDevelopmentCard: FC<{
  fullWidth?: boolean;
  cycle: Cycle;
}> = ({ fullWidth, cycle }) => {
  const { t } = useTranslation();
  const { patientId, cycleId } = useParams();
  const { updateCycleGameteComments } = useCycle();

  const { mutate: handleUpdateCycleComments } = updateCycleGameteComments();

  const { getMe } = useMeApi();
  const { data: me, isLoading } = getMe();

  const [eggCommentChips, setEggCommentChips] = useState<CycleGameteComment[]>(
    cycle?.eggComments || []
  );
  const [spermCommentChips, setSpermCommentChips] = useState<
    CycleGameteComment[]
  >(cycle?.spermComments || []);
  const [embryoCommentChips, setEmbryoCommentChips] = useState<
    CycleGameteComment[]
  >(cycle?.embryoComments || []);

  const [notesHaveChanged, setNotesHaveChanged] = useState(false);
  const [commentsToDelete, setCommentsToDelete] = useState<string[]>([]);

  useEffect(() => {
    if (cycle) {
      setEggCommentChips(cycle?.eggComments || []);
      setSpermCommentChips(cycle?.spermComments || []);
      setEmbryoCommentChips(cycle?.embryoComments || []);
    }
  }, [cycle]);

  if (!cycle) {
    return (
      <Card height="100%">
        <Flex height="100%" alignItems="center" justifyContent="center">
          <Typography align="center" fontWeight={fontWeights.extraBold}>
            {t(
              'THERE_IS_NO_ACTIVE_CYCLE_FOR_THIS_PATIENT_PLEASE_ADD_A_CYCLE_TO_GET_STARTED'
            )}
          </Typography>
        </Flex>
      </Card>
    );
  }

  const handleSaveNotes = async (
    type: Gamete,
    singleCommentToDelete?: string
  ) => {
    let commentsToCreate: CycleGameteComment[] = [];
    if (!singleCommentToDelete) {
      if (!notesHaveChanged) return;

      switch (type) {
        case Gamete.EGG:
          commentsToCreate = eggCommentChips.filter(({ id }) => !id);
          break;
        case Gamete.SEMEN:
          commentsToCreate = spermCommentChips.filter(({ id }) => !id);
          break;
        case Gamete.EMBRYO:
          commentsToCreate = embryoCommentChips.filter(({ id }) => !id);
          break;
      }
    }

    if (commentsToCreate.length || singleCommentToDelete) {
      await handleUpdateCycleComments(
        {
          cycleId,
          patientId,
          commentsToCreate,
          commentsToDelete: singleCommentToDelete
            ? [singleCommentToDelete]
            : commentsToDelete
        },
        {
          onSuccess: () => {
            setCommentsToDelete([]);
          }
        }
      );
      setNotesHaveChanged(false);
    }
  };

  const isEggFreezingCycle =
    cycle.treatmentType === TreatmentTypes.EGG_FREEZING;

  if (isLoading) return <Loader />;

  return (
    <Card padding={spacings.xlarge} boxSizing="border-box">
      <Typography fontWeight={fontWeights.extraBold} variant="h3">
        {t('EMBRYO_DEVELOPMENT').toUpperCase()}
      </Typography>
      <Flex flexDirection="column" justifyContent="space-between">
        <Flex
          flexDirection="column"
          justifyContent="center"
          marginY={spacings.medium}
        >
          <StyledFlexContainer>
            <Typography fontWeight={fontWeights.extraBold}>
              {t('EGG_COMMENTS').toUpperCase()}
            </Typography>
            <Box marginBottom={spacings.small}>
              <Chips
                variant={ChipsVariants.NOTES}
                value={eggCommentChips}
                onClose={() => {
                  handleSaveNotes(Gamete.EGG);
                }}
                chipsInputLabel={t('ADD_COMMENT')}
                allowAddingNewOptions
                onAddChip={(newChip) => {
                  setEggCommentChips([
                    ...eggCommentChips,
                    {
                      value: newChip,
                      patientId,
                      cycleId,
                      type: Gamete.EGG,
                      createdByStaffId: me.user.id,
                      createdAt: new Date()
                    }
                  ]);
                  setNotesHaveChanged(true);
                }}
                onRemoveChip={(_, index) => {
                  setEggCommentChips(
                    eggCommentChips.filter(
                      (_, currIndex) => currIndex !== index
                    )
                  );
                  setCommentsToDelete([
                    ...commentsToDelete,
                    eggCommentChips[index].id
                  ]);
                  setNotesHaveChanged(true);
                }}
              />
            </Box>
          </StyledFlexContainer>

          <StyledBorderBox />
          <NotesList
            notes={eggCommentChips}
            onRemoveNote={(_, index) => {
              setEggCommentChips(
                eggCommentChips.filter((_, currIndex) => currIndex !== index)
              );
              handleSaveNotes(Gamete.EGG, eggCommentChips[index].id);
            }}
            fullWidth={fullWidth}
          />
        </Flex>
        {!isEggFreezingCycle && (
          <Flex
            flexDirection="column"
            justifyContent="center"
            marginY={spacings.medium}
          >
            <StyledFlexContainer>
              <Typography fontWeight={fontWeights.extraBold}>
                {t('SPERM_COMMENTS').toUpperCase()}
              </Typography>
              <Box marginBottom={spacings.small}>
                <Chips
                  chipsInputLabel={t('ADD_COMMENT')}
                  allowAddingNewOptions
                  value={spermCommentChips}
                  variant={ChipsVariants.NOTES}
                  onClose={() => {
                    handleSaveNotes(Gamete.SEMEN);
                  }}
                  onAddChip={(newChip) => {
                    setSpermCommentChips([
                      ...spermCommentChips,
                      {
                        value: newChip,
                        patientId,
                        cycleId,
                        type: Gamete.SEMEN,
                        createdByStaffId: me.user.id,
                        createdAt: new Date()
                      }
                    ]);
                    setNotesHaveChanged(true);
                  }}
                  onRemoveChip={(_, index) => {
                    setSpermCommentChips(
                      spermCommentChips.filter(
                        (_, currIndex) => currIndex !== index
                      )
                    );
                    setCommentsToDelete([
                      ...commentsToDelete,
                      spermCommentChips[index].id
                    ]);
                    setNotesHaveChanged(true);
                  }}
                />
              </Box>
            </StyledFlexContainer>
            <StyledBorderBox />

            <NotesList
              notes={spermCommentChips}
              onRemoveNote={(_, index) => {
                setSpermCommentChips(
                  spermCommentChips.filter(
                    (_, currIndex) => currIndex !== index
                  )
                );
                handleSaveNotes(Gamete.SEMEN, spermCommentChips[index].id);
              }}
              fullWidth={fullWidth}
            />
          </Flex>
        )}
        {!isEggFreezingCycle && (
          <Flex
            flexDirection="column"
            justifyContent="center"
            marginY={spacings.medium}
          >
            <StyledFlexContainer>
              <Typography fontWeight={fontWeights.extraBold}>
                {t('EMBRYO_COMMENTS').toUpperCase()}
              </Typography>
              <Box marginBottom={spacings.small}>
                <Chips
                  chipsInputLabel={t('ADD_COMMENT')}
                  allowAddingNewOptions
                  variant={ChipsVariants.NOTES}
                  value={embryoCommentChips}
                  onClose={() => handleSaveNotes(Gamete.EMBRYO)}
                  onAddChip={(newChip) => {
                    setEmbryoCommentChips([
                      ...embryoCommentChips,
                      {
                        value: newChip,
                        patientId,
                        cycleId,
                        type: Gamete.EMBRYO,
                        createdByStaffId: me.user.id,
                        createdAt: new Date()
                      }
                    ]);
                    setNotesHaveChanged(true);
                  }}
                  onRemoveChip={(_, index) => {
                    setEmbryoCommentChips(
                      embryoCommentChips.filter(
                        (_, currIndex) => currIndex !== index
                      )
                    );
                    setCommentsToDelete([
                      ...commentsToDelete,
                      embryoCommentChips[index].id
                    ]);
                    setNotesHaveChanged(true);
                  }}
                />
              </Box>
            </StyledFlexContainer>
            <StyledBorderBox />

            <NotesList
              notes={embryoCommentChips}
              onRemoveNote={(_, index) => {
                setEmbryoCommentChips(
                  embryoCommentChips.filter(
                    (_, currIndex) => currIndex !== index
                  )
                );
                handleSaveNotes(Gamete.EMBRYO, embryoCommentChips[index].id);
              }}
              fullWidth={fullWidth}
            />
          </Flex>
        )}
      </Flex>
    </Card>
  );
};
