import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';
import { useTranslation } from 'react-i18next';

import {
  checkCycleHasEmbryologyReportRequest,
  createCycleRequest,
  getCycleById,
  getCycleTemplatesRequest,
  getPatientCurrentCycle,
  getPatientCyclesRequest,
  getReleasedData,
  removePrescriptionsFromCycle,
  updateCycleGameteCommentsRequest,
  updateCycleRequest,
  updateReleasedDate,
  upsertCycleNotesRequest
} from 'src/api/cycle.api';
import { useToast, useDialog } from 'src/contexts/UIContexts';
import { CreatePatientDocumentPayload } from 'src/types/documents';
import {
  Cycle,
  NewCycleFormValues,
  ReleasedEggsData,
  CycleTemplates
} from 'src/types/cycle';
import { EggAndEmbryoNotesToCreate } from 'src/types/eggAndEmbryo';
import { AppError } from '../types/global';
import {
  CycleGameteCommentsUpdate,
  CycleNotesToCreate,
  UpdateCyclePayload
} from '../types/cycle';
import { ToastType } from '../components/display/Toast/Toast';
import { queryKeys, querySubKeys } from './queryKeys';

function useCycle() {
  const { t } = useTranslation();
  const { openToast, handleQueryResultToast } = useToast();
  const { closeDialog } = useDialog();

  const queryClient = useQueryClient();

  return {
    removePrescriptionsFromCycle: () =>
      useMutation<void, AppError, { cycleId: string }>(
        ({ cycleId }) => removePrescriptionsFromCycle(cycleId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_REMOVE_CYCLE_PRESCRIPTOIN')
            }),
          onSuccess: (_, { cycleId }) => {
            queryClient.invalidateQueries([queryKeys.CYCLES, cycleId]);
            closeDialog();
          }
        }
      ),
    updateCycle: () =>
      useMutation<
        {
          id: string;
          orderDocumentIds?: string[];
          prescriptionsDocumentId?: string;
        },
        AppError,
        UpdateCyclePayload
      >(
        ({ cycle, appointmentsWithOrderDocumentPayload }) =>
          updateCycleRequest({ cycle, appointmentsWithOrderDocumentPayload }),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_CYCLE')
            }),
          onSuccess: (_, { cycle: { patientId, id: cycleId } }) => {
            openToast({
              title: t('UPDATE_CYCLE_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([queryKeys.PATIENTS, patientId]);
            queryClient.invalidateQueries([queryKeys.CYCLES, cycleId]);
            queryClient.invalidateQueries([
              queryKeys.CYCLES,
              querySubKeys[queryKeys.CYCLES].CURRENT_CYCLE,
              querySubKeys[queryKeys.CYCLES].PATIENTS,
              patientId
            ]);
          }
        }
      ),
    updateReleasedData: () =>
      useMutation<
        ReleasedEggsData[],
        AppError,
        { cycleId: string; patientId: string; releasedData: ReleasedEggsData[] }
      >(
        ({ cycleId, patientId, releasedData }) =>
          updateReleasedDate(cycleId, patientId, releasedData),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_RELEASE_DATA')
            }),
          onSuccess: (_, { cycleId, patientId }) => {
            openToast({
              title: t('UPDATE_RELEASED_DATA_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([
              queryKeys.CYCLES,
              cycleId,
              querySubKeys[queryKeys.CYCLES].PATIENTS,
              patientId,
              querySubKeys[queryKeys.CYCLES].CURRENT_CYCLE,
              querySubKeys[queryKeys.CYCLES].RELEASED_DATA
            ]);
          }
        }
      ),
    createCycle: () =>
      useMutation<
        {
          id: string;
          ordersDocumentId?: string;
          prescriptionsDocumentId?: string;
        },
        AppError,
        {
          cycle: NewCycleFormValues;
          ordersDocumentPayload?: CreatePatientDocumentPayload;
          prescriptionsDocumentPayload?: CreatePatientDocumentPayload;
        }
      >(
        ({ cycle, ordersDocumentPayload, prescriptionsDocumentPayload }) => {
          return createCycleRequest({
            cycle,
            ordersDocumentPayload,
            prescriptionsDocumentPayload
          });
        },
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_CREATE_CYCLE')
            }),
          onSuccess: (_, { cycle: { patientId } }) => {
            openToast({
              title: t('CREATE_NEW_CYCLE_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([queryKeys.PATIENTS]);
            queryClient.invalidateQueries([queryKeys.PATIENTS, patientId]);
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].PATIENT_HISTORY
            ]);
            queryClient.invalidateQueries([
              queryKeys.CYCLES,
              querySubKeys[queryKeys.CYCLES].CURRENT_CYCLE,
              querySubKeys[queryKeys.CYCLES].PATIENTS,
              patientId
            ]);
          }
        }
      ),
    upsertCycleNotes: () =>
      useMutation<EggAndEmbryoNotesToCreate, AppError, CycleNotesToCreate>(
        upsertCycleNotesRequest,
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPSERT_CYCLE_NOTES')
            }),
          onSuccess: (_, { cycleId }) => {
            queryClient.invalidateQueries([
              queryKeys.CYCLES,
              cycleId,
              querySubKeys[queryKeys.CYCLES].GRAPH_DATA
            ]);
          }
        }
      ),
    getPatientCycles: (
      patientId: string,
      options?: UseQueryOptions<Cycle[], AppError>
    ) =>
      useQuery<Cycle[], AppError>(
        [queryKeys.PATIENTS, patientId, 'cycles'],
        () => getPatientCyclesRequest(patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_PATIENT_CYCLES')
            }),
          ...options
        }
      ),
    getCycleById: (
      cycleId: string,
      options?: UseQueryOptions<Cycle, AppError>
    ) =>
      useQuery<Cycle, AppError>(
        [queryKeys.CYCLES, cycleId],
        () => getCycleById(cycleId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_CYCLE')
            }),
          ...options
        }
      ),
    getReleasedData: (
      cycleId: string,
      patientId: string,
      options?: UseQueryOptions<ReleasedEggsData[], AppError>
    ) =>
      useQuery<ReleasedEggsData[], AppError>(
        [
          queryKeys.CYCLES,
          cycleId,
          querySubKeys[queryKeys.CYCLES].PATIENTS,
          patientId,
          querySubKeys[queryKeys.CYCLES].CURRENT_CYCLE,
          querySubKeys[queryKeys.CYCLES].RELEASED_DATA
        ],
        () => getReleasedData(cycleId, patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_CYCLE')
            }),
          ...options
        }
      ),
    checkCycleHasEmbryologyReport: (
      cycleId: string,
      patientId: string,
      options?: UseQueryOptions<boolean, AppError>
    ) =>
      useQuery<boolean, AppError>(
        [
          queryKeys.CYCLES,
          cycleId,
          querySubKeys[queryKeys.CYCLES].PATIENTS,
          patientId,
          querySubKeys[queryKeys.CYCLES].HAS_EMBRYOLOGY_REPORT
        ],
        () => checkCycleHasEmbryologyReportRequest(cycleId, patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_CHECK_FOR_EMBRYOLOGY_REPORT')
            }),
          enabled: !!cycleId && !!patientId,
          ...options
        }
      ),
    getCurrentCycle: (
      patientId: string,
      options?: UseQueryOptions<Cycle, AppError>
    ) =>
      useQuery<Cycle, AppError>(
        [
          queryKeys.CYCLES,
          querySubKeys[queryKeys.CYCLES].CURRENT_CYCLE,
          querySubKeys[queryKeys.CYCLES].PATIENTS,
          patientId
        ],
        () => getPatientCurrentCycle(patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_CURRENT_CYCLE')
            }),
          ...options
        }
      ),
    updateCycleGameteComments: () =>
      useMutation<
        CycleGameteCommentsUpdate,
        AppError,
        CycleGameteCommentsUpdate
      >(updateCycleGameteCommentsRequest, {
        onSettled: (data, error) =>
          handleQueryResultToast({
            data,
            error,
            actionName: t('ACTION_TITLE_UPDATE_CYCLE_GAMETE_COMMENTS')
          }),
        onSuccess: (_, { cycleId, patientId }) => {
          queryClient.invalidateQueries([
            queryKeys.CYCLES,
            cycleId,
            querySubKeys[queryKeys.CYCLES].GRAPH_DATA
          ]);
          queryClient.invalidateQueries([
            queryKeys.PATIENTS,
            patientId,
            querySubKeys[queryKeys.PATIENTS].CYCLES
          ]);
        }
      }),
    getCycleTemplates: () =>
      useQuery<CycleTemplates[], AppError>(
        [queryKeys.CYCLES, querySubKeys[queryKeys.CYCLES].TEMPLATES],
        () => getCycleTemplatesRequest()
      )
  };
}

export default useCycle;
