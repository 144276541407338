import { useQuery, UseQueryOptions } from 'react-query';
import { useTranslation } from 'react-i18next';
import { getClinicOrderBundles, getClinicInfoById } from '../api/clinics.api';
import { Clinic } from 'src/types/clinic';
import { AppError } from 'src/types/global';
import { queryKeys, querySubKeys } from './queryKeys';
import { OrderBundle } from 'src/types/appointment';
import { useToast } from 'src/contexts/UIContexts';

function useClinicsApi() {
  const { t } = useTranslation();
  const { handleQueryResultToast } = useToast();
  return {
    getClinicInfo: (
      clinicId: string,
      options?: UseQueryOptions<Clinic, Error>
    ) =>
      useQuery<Clinic, AppError>(
        [queryKeys.CLINICS, clinicId],
        () => getClinicInfoById(),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_CLINIC')
            }),
          ...options
        }
      ),
    getClinicOrderBundles: () =>
      useQuery<OrderBundle[], AppError>(
        [queryKeys.CLINICS, querySubKeys[queryKeys.CLINICS].ORDER_BUNDLES],
        () => getClinicOrderBundles()
      )
  };
}

export default useClinicsApi;
