import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import {
  Controller,
  DefaultValues,
  Path,
  PathValue,
  useForm
} from 'react-hook-form';

import { getFullName } from 'src/utils/general';
import useGeneralApi from 'src/hooks/useGeneralApi';
import useStaffMembers from 'src/hooks/useStaffMembers';
import useCodes from 'src/hooks/useCodes';
import useClinicsApi from 'src/hooks/useClinicsApi';
import { YesOrNo } from 'src/types/global';
import { ServiceType } from 'src/types/codes';
import { VendorNames, VendorsKey } from 'src/types/hl7messages';
import {
  AppointmentOrOrderPurpose,
  LabOrderBillTo,
  OrderBundle,
  OrderFormParams,
  Urgency
} from 'src/types/appointment';
import { PatientPersonalInfo } from 'src/types/patient';
import Loader from 'src/components/display/Loader/Loader';
import Box from 'src/components/layout/Box/Box';
import {
  iconSizes,
  spacings,
  spacingsPercentage
} from 'src/components/styles/constants';
import Flex from 'src/components/layout/Flex/Flex';
import Select from 'src/components/data-entry/Select/Select';
import Chips from 'src/components/data-entry/Chips/Chips';
import Typography from 'src/components/display/Typography';
import TextArea from 'src/components/data-entry/TextArea';
import MultiSelect from 'src/components/data-entry/MultiSelect/MultiSelect';
import InputLabel from 'src/components/data-entry/InputLabel';
import Button from 'src/components/display/Button';
import { SearchFieldVariants } from 'src/components/data-entry/SearchField/SearchField';
import PatientPartnerSelect from 'src/modules/calendar/appointmentTabs/PatientPartnerSelect';
import PatientsSearchBar from 'src/layouts/CollapsedSidebarLayout/Header/PatientsSearchBar';
import { Icd10Chips } from '../common/Icd10Chips';
import { DoctorChips } from '../common/DoctorChips';
import usePatientsApi from '../../../hooks/usePatientsApi';
import {
  billToOptions,
  bundleOptions,
  labItemsOptions,
  orderPurposeOptions,
  procedureItemsOptions,
  urgencyOptions,
  vendorsOptions,
  yesNoOptions
} from '../utils/options';

const StyledLoaderBox = styled(Box)`
  display: flex;
  min-height: 160px;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export enum OrderType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL'
}

export type OrderFormProps = {
  patientId: string;
  orderType: OrderType;
  defaultValues: DefaultValues<OrderFormParams>;
  purpose?: AppointmentOrOrderPurpose;
  bundleNames?: string[];
  isDisabled?: boolean;
  onSubmit?: (data: OrderFormParams) => void;
  onFormUpdate?: (data: OrderFormParams, isDirty: boolean) => void;
  patientInfo?: PatientPersonalInfo;
};

const allowedVendors = [VendorsKey.Labcorp, VendorsKey.Tosho];

export const OrderForm: FC<OrderFormProps> = ({
  patientId,
  orderType,
  purpose,
  bundleNames,
  isDisabled,
  onSubmit,
  onFormUpdate,
  defaultValues,
  patientInfo
}) => {
  const [isProcedureOnly, setIsProcedureOnly] = useState(false);
  const [selectedBundleNames, setSelectedBundleNames] = useState<string[]>([]);
  const [isLoadingBundleData, setIsLoadingBundleData] = useState(false);
  const [
    selectedBundlesProcedureOrderIcd10Codes,
    setSelectedBundlesProcedureOrderIcd10Codes
  ] = useState<string[]>([]);
  const [
    selectedBundlesLabOrderIcd10Codes,
    setSelectedBundlesLabOrderIcd10Codes
  ] = useState<string[]>([]);

  const { t } = useTranslation();
  const { getPatientById } = usePatientsApi();
  const { getBillingServices, getIcd10Codes } = useCodes();
  const { getOrdersVendors } = useGeneralApi();
  const { getStaffMemberById } = useStaffMembers();
  const { getClinicOrderBundles } = useClinicsApi();

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);

  const primaryPhysicianId = patient?.primaryPhysician;

  const {
    data: clinicExternalOrderBundles,
    isLoading: isLoadingClinicExternalOrderBundles,
    isFetching: isFetchingClinicBundles
  } = getClinicOrderBundles();

  const {
    data: patientPrimaryStaffMember,
    isLoading: isLoadingPatientPrimaryStaffMember,
    isFetching: isFetchingPatientPrimaryStaffMember
  } = getStaffMemberById(primaryPhysicianId, {
    enabled: !!primaryPhysicianId
  });

  const { data: procedureItems, isLoading: isLoadingProcedureItems } =
    getBillingServices({ serviceType: ServiceType.PROCEDURE });

  const {
    data: vendors,
    isLoading: isLoadingOrderVendors,
    isFetching: isFetchingOrderVendors
  } = getOrdersVendors();

  const {
    data: procedureOrderIcd10Codes,
    isLoading: isLoadingProcedureOrderICD10Codes,
    isFetching: isFetchingProcedureOrderICD10Codes
  } = getIcd10Codes({ icd10Codes: selectedBundlesProcedureOrderIcd10Codes });
  const {
    data: labOrderIcd10Codes,
    isLoading: isLoadingLabOrderICD10Codes,
    isFetching: isFetchingLabOrderICD10Codes
  } = getIcd10Codes({ icd10Codes: selectedBundlesLabOrderIcd10Codes });

  const { control, formState, handleSubmit, setValue, watch, getValues } =
    useForm<OrderFormParams, unknown>({
      mode: 'onChange',
      defaultValues
    });

  const { errors, isDirty } = formState;
  const watchFormValues = watch();
  const isLabTestsSelected = !!watchFormValues?.labTestsIds?.length;
  const watchVendor = vendors?.find(
    (vendor) => vendor.id === watchFormValues?.vendorId
  );
  const watchVendorKey = watchVendor?.key;

  const { data: labs, refetch: refetchLabs } = getBillingServices({
    serviceType: ServiceType.LAB,
    withVendors: true,
    vendorKeys: [watchVendorKey]
  });

  const isLoading =
    isLoadingPatient ||
    isLoadingOrderVendors ||
    isLoadingProcedureItems ||
    isLoadingPatientPrimaryStaffMember ||
    isFetchingPatientPrimaryStaffMember ||
    isLoadingClinicExternalOrderBundles ||
    isFetchingClinicBundles;

  const bundlesWithIds: OrderBundle[] = useMemo(
    () =>
      clinicExternalOrderBundles?.map((bundle) => {
        return {
          ...bundle,
          loincList: bundle.loincList, // Keep LOINC codes as is - (Due to the fact that the Labs are not fetched yet)
          cptList: bundle.cptList?.map(
            (cpt) =>
              procedureItems?.find((proc) => proc.billingCode === cpt)?.id
          )
        };
      }),
    [clinicExternalOrderBundles, procedureItems]
  );

  const procedureItemList = useMemo(
    () => procedureItemsOptions(procedureItems),
    [procedureItems]
  );
  const vendorList = useMemo(() => vendorsOptions(vendors), [vendors]);
  const labItemList = useMemo(() => labItemsOptions(labs), [labs]);
  const bundleList = useMemo(
    () => bundleOptions(bundlesWithIds),
    [bundlesWithIds]
  );

  const getLabTestIdsFromBundles = useCallback(
    (selectedBundleNames: string[]): string[] => {
      const selectedBundleObjects = bundlesWithIds?.filter((bundle) =>
        selectedBundleNames.includes(bundle.bundleName)
      );

      return selectedBundleObjects?.flatMap(
        (bundle) =>
          bundle.loincList
            ?.map((loinc) => labs?.find((lab) => lab.orderCode === loinc)?.id)
            .filter(Boolean) || []
      );
    },
    [bundlesWithIds, labs]
  );

  const handleUpdateFieldValue = useCallback(
    (
      fieldName: Path<OrderFormParams>,
      value: PathValue<OrderFormParams, typeof fieldName>
    ) => {
      setValue(fieldName, value);
    },
    [setValue]
  );

  const handleUpdateFormField = useCallback(
    <T extends Path<OrderFormParams>>(
      fieldName: T,
      value: PathValue<OrderFormParams, T>
    ) => {
      if (onFormUpdate) {
        const currentFormValues = getValues();

        onFormUpdate(
          {
            ...currentFormValues,
            [fieldName]: value
          },
          isDirty
        );
      }

      handleUpdateFieldValue(fieldName, value);
    },
    [isDirty, getValues, handleUpdateFieldValue]
  );

  const handleSelectPatient = (patient: PatientPersonalInfo) => {
    handleUpdateFormField('patientId', patient.id);
    handleUpdateFormField('patientName', getFullName(patient));
  };

  const handleBundleChange = async (selectedBundleNames: string[]) => {
    const bundlesProcedureOrderCodes = [];
    const bundlesLabOrderCodes = [];

    setIsLoadingBundleData(true);
    setSelectedBundleNames(selectedBundleNames);

    const selectedBundleObjects = bundlesWithIds.filter((bundle) =>
      selectedBundleNames?.includes(bundle.bundleName)
    );

    selectedBundleObjects.forEach((bundleObject) => {
      if (bundleObject.labOrderIcd10Codes) {
        bundlesLabOrderCodes.push(...bundleObject.labOrderIcd10Codes);
      }

      if (bundleObject.procedureOrderIcd10Codes) {
        bundlesProcedureOrderCodes.push(
          ...bundleObject.procedureOrderIcd10Codes
        );
      }
    });

    setValue('bundleNames', selectedBundleNames);

    setSelectedBundlesProcedureOrderIcd10Codes(bundlesProcedureOrderCodes);
    setSelectedBundlesLabOrderIcd10Codes(bundlesLabOrderCodes);

    if (!selectedBundleNames.length) {
      handleUpdateFormField('vendorId', null);
      handleUpdateFormField('purpose', null);
      handleUpdateFormField('urgency', null);
      handleUpdateFormField('labTestsIds', []);
      handleUpdateFormField('proceduresIds', []);
      handleUpdateFormField('labOrderIcd10Codes', []);
      handleUpdateFormField('procedureOrderIcd10Codes', []);
    } else {
      const selectedBundlesObjects = bundlesWithIds.filter((bundle) =>
        selectedBundleNames.includes(bundle.bundleName)
      );

      const bundleWithVendor = selectedBundlesObjects.find(
        (bundle) => bundle.vendorName
      );

      if (!watchVendorKey || !allowedVendors.includes(watchVendorKey)) {
        const selectedVendors = allowedVendors.map((vendorKey) =>
          vendors?.find((vendor) => vendor.name === VendorNames[vendorKey])
        );

        const isBundleWithAllowedVendorSelected = selectedBundlesObjects.some(
          (bundle) =>
            selectedVendors.some((vendor) => vendor?.name === bundle.vendorName)
        );

        if (isBundleWithAllowedVendorSelected) {
          const selectedVendor = selectedVendors.find(
            (vendor) => vendor?.name === bundleWithVendor?.vendorName
          );

          handleUpdateFormField('vendorId', selectedVendor?.id);

          await refetchLabs();
        }
      }

      if (bundleWithVendor) {
        if (orderType === OrderType.EXTERNAL) {
          setValue('purpose', bundleWithVendor?.purpose);
        }

        handleUpdateFormField('urgency', bundleWithVendor?.urgency);
      } else {
        const bundle = selectedBundlesObjects[0];

        if (orderType === OrderType.EXTERNAL) {
          setValue('purpose', bundle?.purpose);
        }

        handleUpdateFormField('urgency', bundle?.urgency);
      }
    }

    const procedureIds = selectedBundleObjects.flatMap(
      (bundle) => bundle.cptList?.filter(Boolean) || []
    );

    const labTestIds = getLabTestIdsFromBundles(selectedBundleNames);

    handleUpdateFormField('labTestsIds', [...new Set(labTestIds)]);
    handleUpdateFormField('proceduresIds', [...new Set(procedureIds)]);
    handleUpdateFormField('bundleNames', selectedBundleNames);

    setIsLoadingBundleData(false);
  };

  useEffect(() => {
    if (watchVendorKey) {
      refetchLabs();
    }
  }, [watchVendorKey, refetchLabs]);

  useEffect(() => {
    if (labs && !watchFormValues?.labTestsIds?.length) {
      const labTestIds = getLabTestIdsFromBundles(selectedBundleNames);

      handleUpdateFormField('labTestsIds', [...new Set(labTestIds)]);
    }
  }, [labs, selectedBundleNames]);

  useEffect(() => {
    if (procedureOrderIcd10Codes?.length) {
      handleUpdateFormField('procedureOrderIcd10Codes', [
        ...new Set(procedureOrderIcd10Codes)
      ]);
    }
  }, [procedureOrderIcd10Codes]);

  useEffect(() => {
    if (labOrderIcd10Codes?.length) {
      handleUpdateFormField('labOrderIcd10Codes', [
        ...new Set(labOrderIcd10Codes)
      ]);
    }
  }, [labOrderIcd10Codes]);

  useEffect(() => {
    if (!patient) return;
    handleSelectPatient(patient.personalInfo);
  }, [patient]);

  useEffect(() => {
    if (!patientPrimaryStaffMember) return;

    handleUpdateFormField('requestingPhysician', patientPrimaryStaffMember.id);
  }, [patientPrimaryStaffMember]);

  useEffect(() => {
    setIsProcedureOnly(
      watchFormValues?.proceduresIds?.length &&
        !watchFormValues?.labTestsIds?.length
    );
  }, [watchFormValues]);

  useEffect(() => {
    if (orderType === OrderType.INTERNAL) {
      handleUpdateFormField('purpose', purpose);
    }
  }, [orderType, purpose]);

  useEffect(() => {
    if (bundleNames?.length && !selectedBundleNames?.length) {
      setSelectedBundleNames(bundleNames);
    }
  }, [bundleNames, selectedBundleNames, setSelectedBundleNames]);

  useEffect(() => {
    if (patientInfo) {
      handleUpdateFormField('patientId', patientInfo.id);
      handleUpdateFormField('patientName', getFullName(patientInfo));
    }
  }, [patientInfo]);

  if (isLoading) {
    return (
      <StyledLoaderBox>
        <Loader size={iconSizes.large} />
      </StyledLoaderBox>
    );
  }

  return (
    <form noValidate>
      <Flex gap={spacings.medium} flexDirection="column">
        <Flex gap={spacings.large}>
          {orderType === OrderType.EXTERNAL && (
            <>
              <Box width="25%">
                <Controller
                  name="patientName"
                  control={control}
                  render={() => {
                    if (patientId) {
                      return (
                        <PatientPartnerSelect
                          patientId={patientId}
                          errors={errors}
                          onChange={handleSelectPatient}
                          value={getValues('patientId')}
                        />
                      );
                    } else {
                      return (
                        <PatientsSearchBar
                          placeholder={t('SEARCH_PATIENT_PLACEHOLDER')}
                          variant={SearchFieldVariants.DEFAULT}
                          onSelectPatient={handleSelectPatient}
                          onSearchTermClear={() => {
                            handleUpdateFormField('patientId', '');
                            handleUpdateFormField('patientName', '');
                          }}
                          errors={errors}
                          label={t('PATIENT_LABEL')}
                        />
                      );
                    }
                  }}
                />
              </Box>
              <Box width="25%">
                <Controller
                  name="purpose"
                  control={control}
                  rules={{
                    required: t('PURPOSE_REQUIRED')
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <Select
                      {...field}
                      label={t('PICK_PURPOSE')}
                      inputRef={ref}
                      error={!!errors?.purpose}
                      helperText={errors?.purpose?.message}
                      options={orderPurposeOptions}
                      defaultOption={t('PICK_A_PURPOSE')}
                    />
                  )}
                />
              </Box>
            </>
          )}
          <Box width="25%">
            <InputLabel label={t('PICK_BUNDLES').toUpperCase()} />
            <MultiSelect
              options={bundleList}
              value={selectedBundleNames}
              onChange={handleBundleChange}
              placeholder={t('PICK_BUNDLES')}
              disabled={isLoadingBundleData || isFetchingClinicBundles}
              displayEmpty
              withCheckbox
              shouldSortOptions
            />
          </Box>
          {orderType === OrderType.EXTERNAL && (
            <Box width="25%">
              <Controller
                name="requestingPhysician"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DoctorChips
                    id="edit-order-requesting-physician"
                    showSelectedValue
                    label={t('REQUESTING_PROVIDER')}
                    value={[value]}
                    onAddChip={(newSelectedDoctorId) =>
                      onChange(newSelectedDoctorId)
                    }
                  />
                )}
              />
            </Box>
          )}
        </Flex>
        <Flex
          gap={spacings.large}
          marginTop={spacings.medium}
          justifyContent="space-between"
        >
          <Box width={`calc(${spacingsPercentage.xlarge} - ${spacings.large})`}>
            <Controller
              name="proceduresIds"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Chips
                  renderSelectedOptionsOutside
                  value={value}
                  titleComponent={
                    <Typography variant="h3" align="center">
                      {t('SET_PROCEDURE_ORDERS')}
                    </Typography>
                  }
                  options={procedureItemList}
                  onAddChip={(chipValue) => {
                    const newValue = [...value, chipValue];
                    onChange(newValue);
                    handleUpdateFormField('proceduresIds', newValue);
                  }}
                  onRemoveChip={(chipValue) => {
                    const newValue = value?.filter((val) => val !== chipValue);
                    onChange(newValue);
                    handleUpdateFormField('proceduresIds', newValue);
                  }}
                  shouldSortOptions
                />
              )}
            />
          </Box>
          <Box width={`calc(${spacingsPercentage.xlarge} - ${spacings.large})`}>
            <Controller
              name="proceduresInstructions"
              control={control}
              render={({ field: { onChange, ...field } }) => (
                <TextArea
                  {...field}
                  label={t('PROCEDURE_INSTRUCTIONS').toUpperCase()}
                  error={!!errors?.proceduresInstructions}
                  helperText={errors?.proceduresInstructions?.message}
                  labelProps={{ rowGap: spacings.small }}
                  fullWidth
                  minRows={3}
                  resize="auto"
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleUpdateFormField(
                      'proceduresInstructions',
                      e.target.value
                    );
                  }}
                />
              )}
            />
          </Box>
          <Box
            minHeight="30%"
            maxHeight="30%"
            marginBottom={spacings.x2large}
            width={`calc(${spacingsPercentage.medium} - ${spacings.large})`}
            maxWidth={`calc(${spacingsPercentage.medium} - ${spacings.large})`}
          >
            <Controller
              name="procedureOrderIcd10Codes"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Icd10Chips
                    error={!!errors.procedureOrderIcd10Codes}
                    helperText={errors?.procedureOrderIcd10Codes?.message}
                    label={t('ENTER_DIAGNOSTIC_CODE')}
                    disabled={
                      isLoadingProcedureOrderICD10Codes ||
                      isFetchingProcedureOrderICD10Codes
                    }
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            />
          </Box>
        </Flex>
        <Flex gap={spacings.large}>
          <Box width="25%">
            <Controller
              name="vendorId"
              control={control}
              rules={{
                required: !isProcedureOnly
                  ? t('PERFORMING_LAB_REQUIRED')
                  : undefined
              }}
              render={({ field: { onChange, ref, ...field } }) => (
                <Select
                  {...field}
                  label={t('CHOOSE_LAB').toUpperCase()}
                  inputRef={ref}
                  error={!!errors?.vendorId}
                  helperText={errors?.vendorId?.message}
                  defaultOption={t('CHOOSE_LAB')}
                  isLoading={isLoadingOrderVendors || isFetchingOrderVendors}
                  options={vendorList}
                  disabled={isLabTestsSelected}
                  shouldSortOptions
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleUpdateFormField('vendorId', e.target.value);
                  }}
                />
              )}
            />
          </Box>
          <Box width="25%">
            <Controller
              name="billTo"
              control={control}
              rules={{
                required: t('BILL_TO_REQUIRED')
              }}
              render={({ field: { onChange, ref, value, ...field } }) => (
                <Select
                  {...field}
                  label={t('BILL_TO').toUpperCase()}
                  inputRef={ref}
                  value={value}
                  error={!!errors?.billTo}
                  helperText={errors?.billTo?.message}
                  defaultOption={t('BILL_TO')}
                  options={billToOptions}
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleUpdateFormField(
                      'billTo',
                      e.target.value as LabOrderBillTo
                    );
                  }}
                />
              )}
            />
          </Box>
          <Box width="25%">
            <Controller
              name="urgency"
              control={control}
              rules={{
                required: t('URGENT_REQUIRED')
              }}
              render={({ field: { onChange, ref, value, ...field } }) => (
                <Select
                  {...field}
                  label={t('URGENT').toUpperCase()}
                  inputRef={ref}
                  value={value}
                  error={!!errors?.urgency}
                  defaultOption={t('CHOOSE_URGENCY')}
                  helperText={errors?.urgency?.message}
                  options={urgencyOptions}
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleUpdateFormField('urgency', e.target.value as Urgency);
                  }}
                />
              )}
            />
          </Box>
          <Box width="25%">
            <Controller
              name="isFasting"
              control={control}
              rules={{
                required: t('FASTING_REQUIRED')
              }}
              render={({ field: { onChange, ref, value, ...field } }) => (
                <Select
                  {...field}
                  label={t('Fasting').toUpperCase()}
                  inputRef={ref}
                  value={value}
                  error={!!errors?.isFasting}
                  helperText={errors?.isFasting?.message}
                  defaultOption={t('IS_FASTING')}
                  options={yesNoOptions}
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleUpdateFormField(
                      'isFasting',
                      e.target.value ? YesOrNo.YES : YesOrNo.NO
                    );
                  }}
                />
              )}
            />
          </Box>
        </Flex>
        <Flex
          gap={spacings.large}
          marginTop={spacings.medium}
          justifyContent="space-between"
        >
          <Box width={`calc(${spacingsPercentage.xlarge} - ${spacings.large})`}>
            <Typography
              visibility={watchFormValues?.vendorId ? 'hidden' : 'visible'}
              variant="caption"
              align="center"
            >
              {t('CHOOSE_LAB_IN_ORDER_TO_SELECT_LABS')}
            </Typography>
            <Controller
              name="labTestsIds"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Chips
                  renderSelectedOptionsOutside
                  value={value}
                  titleComponent={
                    <Typography variant="h3" align="center">
                      {t('SET_LAB_ORDERS')}
                    </Typography>
                  }
                  options={labItemList}
                  onAddChip={(chipValue) => {
                    onChange([...value, chipValue]);
                    handleUpdateFormField('labTestsIds', [...value, chipValue]);
                  }}
                  onRemoveChip={(chipValue) => {
                    const newValue = value?.filter((val) => val !== chipValue);
                    onChange(newValue);
                    handleUpdateFormField('labTestsIds', newValue);
                  }}
                  shouldSortOptions
                  disabled={!watchFormValues?.vendorId}
                />
              )}
            />
          </Box>
          <Box width={`calc(${spacingsPercentage.xlarge} - ${spacings.large})`}>
            <Controller
              name="labInstructions"
              control={control}
              render={({ field: { onChange, ref: _ref, ...field } }) => (
                <TextArea
                  {...field}
                  label={t('LAB_INSTRUCTIONS').toUpperCase()}
                  error={!!errors?.labInstructions}
                  helperText={errors?.labInstructions?.message}
                  fullWidth
                  minRows={3}
                  resize="auto"
                  disabled={!watchFormValues?.vendorId}
                  labelProps={{ rowGap: spacings.small }}
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleUpdateFormField('labInstructions', e.target.value);
                  }}
                />
              )}
            />
          </Box>
          <Box
            minHeight="30%"
            maxHeight="30%"
            marginBottom={spacings.x2large}
            width={`calc(${spacingsPercentage.medium} - ${spacings.large})`}
            maxWidth={`calc(${spacingsPercentage.medium} - ${spacings.large})`}
          >
            <Controller
              name="labOrderIcd10Codes"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Icd10Chips
                    error={!!errors.labOrderIcd10Codes}
                    helperText={errors?.labOrderIcd10Codes?.message}
                    label={t('ENTER_DIAGNOSTIC_CODE')}
                    disabled={
                      isLoadingLabOrderICD10Codes ||
                      isFetchingLabOrderICD10Codes
                    }
                    onChange={onChange}
                    value={value}
                  />
                );
              }}
            />
          </Box>
        </Flex>
      </Flex>
      {orderType === OrderType.EXTERNAL && (
        <Flex justifyContent="end" marginTop={spacings.large}>
          <Box>
            <Button
              fullWidth
              type="submit"
              disabled={isDisabled}
              onClick={handleSubmit(onSubmit)}
            >
              {isDisabled ? <Loader /> : t('SUBMIT')}
            </Button>
          </Box>
        </Flex>
      )}
    </form>
  );
};
