import { FC, useMemo } from 'react';
import {
  DesktopTimePicker,
  DesktopTimePickerProps as MuiDesktopTimePickerProps
} from '@mui/x-date-pickers';

import { TimeUnits } from 'src/utils/types';
import { StyledTimePickerContainer } from '../TimePicker/TimePicker';
import InputLabel from '../InputLabel';
import { InputFieldProps } from '../InputField';

interface DesktopTimePickerProps
  extends Omit<MuiDesktopTimePickerProps<Date>, 'value' | 'onChange'>,
    Pick<InputFieldProps, 'helperText' | 'error' | 'fullWidth' | 'required'> {
  value: Record<TimeUnits, number>;
  onChange: (duration: Record<TimeUnits, number>) => void;
}

export const DurationPicker: FC<DesktopTimePickerProps> = ({
  value,
  onChange,
  error,
  helperText,
  fullWidth,
  label,
  required,
  name
}) => {
  const timePickerValue = useMemo(() => {
    if (!value) return null;

    const hours = value[TimeUnits.h] || 0;
    const minutes = value[TimeUnits.m] || 0;
    const calculatedTime = new Date();
    calculatedTime.setHours(hours, minutes, 0);

    return calculatedTime;
  }, [value]);

  const handleOnChange = (newValue?: Date) => {
    if (newValue) {
      const hours = newValue.getHours();
      const minutes = newValue.getMinutes();
      const duration: Record<TimeUnits, number> = {
        [TimeUnits.h]: hours,
        [TimeUnits.m]: minutes,
        [TimeUnits.ms]: newValue.getMilliseconds(),
        [TimeUnits.s]: newValue.getSeconds(),
        [TimeUnits.d]: Math.floor(hours / 24),
        [TimeUnits.mm]: Math.floor(minutes / 60),
        [TimeUnits.y]: Math.floor(hours / (24 * 365))
      };

      onChange(duration);
    }
  };

  return (
    <StyledTimePickerContainer
      error={error}
      width={fullWidth ? '100%' : 'auto'}
    >
      {label && <InputLabel label={label} error={error} required={required} />}
      <DesktopTimePicker
        ampm={false}
        name={name}
        value={timePickerValue}
        views={['hours', 'minutes']}
        onChange={handleOnChange}
        slotProps={{
          textField: {
            helperText,
            placeholder: '--:--',
            error
          }
        }}
      />
    </StyledTimePickerContainer>
  );
};
