import { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MultiSelectOption } from 'src/components/data-entry/MultiSelect/MultiSelect';

import Select from 'src/components/data-entry/Select';
import { SelectProps } from 'src/components/data-entry/Select/Select';
import Loader from 'src/components/display/Loader';
import usePatientsApi from 'src/hooks/usePatientsApi';
import { Appointment } from 'src/types/appointment';
import { AddProgressNoteForm } from 'src/types/feed';
import { PatientPersonalInfo } from 'src/types/patient';
import { getFullName } from 'src/utils/general';

interface PatientPartnerSelectProps
  extends Omit<SelectProps, 'onChange' | 'options'> {
  patientId: string;
  errors: FieldErrors<Appointment | AddProgressNoteForm>;
  onChange: (patient: PatientPersonalInfo) => void;
}

const PatientPartnerSelect = ({
  patientId,
  errors,
  onChange,
  value
}: PatientPartnerSelectProps) => {
  const { t } = useTranslation();
  const { getPatientById } = usePatientsApi();

  const getPatientOrPartnerPersonalInfo = (
    patientId: string
  ): PatientPersonalInfo | undefined =>
    patientId === patientFullData.personalInfo?.id
      ? patientFullData.personalInfo
      : patientFullData.partnerInfo;

  const {
    data: patientFullData,
    isLoading: isLoadingPatient,
    isFetching: isFetchingPatient
  } = getPatientById(patientId);

  const isLoading = isLoadingPatient || isFetchingPatient;

  const options: MultiSelectOption[] = [
    {
      label: getFullName(patientFullData?.personalInfo),
      value: patientFullData?.personalInfo?.id
    },
    {
      label: getFullName(patientFullData?.partnerInfo),
      value: patientFullData?.partnerInfo?.id
    }
  ];

  if (isLoading) return <Loader />;

  return (
    <Select
      isLoading={isLoading}
      label={t('PATIENT_LABEL')}
      value={value}
      options={options}
      id="patient-partner-select"
      onChange={(event) => {
        const patientId = event.target.value;
        const chosenPatient = getPatientOrPartnerPersonalInfo(patientId);

        onChange(chosenPatient);
      }}
      helperText={errors?.patientId?.message}
      error={!!errors?.patientId}
      disabled={!patientFullData?.hasPartner}
    />
  );
};

export default PatientPartnerSelect;
