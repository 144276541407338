import { FC } from 'react';
import { Colors } from '../../../components/styles/colors';
import { useTranslation } from 'react-i18next';
import { fontWeights } from '../../../components/styles/fonts';
import { DotProps, DotWithLabel } from './DotWithLabel';

const labsDots: DotProps[] = [
  {
    fill: Colors.cupid,
    label: 'E2'
  },
  {
    fill: Colors.poloBlue,
    label: 'PG'
  },
  {
    fill: Colors.mustard,
    label: 'LH'
  },
  {
    fill: Colors.gray,
    label: 'FSH'
  },
  {
    fill: Colors.riptide,
    label: 'Beta HCG'
  }
];
const folliclesDots: DotProps[] = [
  {
    fill: Colors.viola,
    label: 'LEFT'
  },
  {
    fill: Colors.poloBlue,
    label: 'RIGHT'
  }
];

export const GraphSideMap: FC = () => {
  const { t } = useTranslation();
  const startingYPosition = 300;
  const startingXPosition = 0;
  const dotRadius = 5;
  const dotMargin = 15;
  const labsSpacing = 20;
  const folliclesStartingYPosition = 430;

  return (
    <g>
      <text
        x={0}
        y={startingYPosition}
        color={Colors.mineShaft}
        fontWeight={fontWeights.extraBold}
        fontSize={16}
      >
        {t('LABS')}
      </text>
      {labsDots.map((dot, index) => (
        <DotWithLabel
          key={`dot-labs-${index}`}
          dot={dot}
          dotMargin={dotMargin}
          dotRadius={dotRadius}
          index={index}
          labsSpacing={labsSpacing}
          startingXPosition={startingXPosition}
          startingYPosition={startingYPosition}
        />
      ))}

      <text
        x={0}
        y={folliclesStartingYPosition}
        color={Colors.mineShaft}
        fontWeight={fontWeights.extraBold}
        fontSize={16}
      >
        {t('FOLLICLES')}
      </text>
      {folliclesDots.map((dot, index) => (
        <DotWithLabel
          key={`dot-follicles-${index}`}
          dot={dot}
          dotMargin={dotMargin}
          dotRadius={dotRadius}
          index={index}
          labsSpacing={labsSpacing}
          startingXPosition={startingXPosition}
          startingYPosition={folliclesStartingYPosition}
        />
      ))}
    </g>
  );
};
