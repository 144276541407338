import { useTranslation } from 'react-i18next';
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';

import { ToastType } from 'src/components/display/Toast/Toast';
import {
  createEmbryologyReportRequest,
  getEggsAndEmbryosReportRequest,
  updateEggRequest,
  upsertNotesRequest,
  getEggAndEmbryoByPatientId,
  getViableEggsListRequest
} from 'src/api/eggAndEmbryo.api';
import {
  EggAndEmbryo,
  EggAndEmbryoNotesToCreate,
  EggsAndEmbryosReport,
  EmbryologyReport,
  UpdateEgg
} from 'src/types/eggAndEmbryo';
import { AppError } from 'src/types/global';
import { EggsAndEmbryosReportServer } from 'src/types/patient-server';
import { useToast, useDialog } from 'src/contexts/UIContexts';
import { EmbryologyRecord } from 'src/modules/patients/gameteAndCryo/EmbryologyTable';
import { queryKeys, querySubKeys } from './queryKeys';

function useEggsEmbryos() {
  const { openToast, handleQueryResultToast } = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { closeDialog } = useDialog();

  return {
    getViableEggsList: (
      patientId: string,
      options?: UseQueryOptions<EggAndEmbryo[], AppError>
    ) =>
      useQuery<EggAndEmbryo[], AppError>(
        [
          queryKeys.PATIENTS,
          patientId,
          querySubKeys[queryKeys.PATIENTS].EMBRYOS_LIST
        ],
        () => getViableEggsListRequest(patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_VIABLE_EGGS')
            }),
          ...options
        }
      ),
    createEmbryologyReport: () =>
      useMutation<EggsAndEmbryosReportServer, AppError, EmbryologyReport>(
        (report: EmbryologyReport) => createEmbryologyReportRequest(report),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_CREATE_EMBRYOLOGY_REPORT')
            }),
          onSuccess: (_, { cycleId, patientId }) => {
            openToast({
              title: t('CREATE_EMBRYOLOGY_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].CYCLES,
              cycleId,
              querySubKeys[queryKeys.PATIENTS].EGG_AND_EMBRYO
            ]);
            queryClient.invalidateQueries([
              queryKeys.CYCLES,
              cycleId,
              querySubKeys[queryKeys.CYCLES].PATIENTS,
              patientId,
              querySubKeys[queryKeys.CYCLES].HAS_EMBRYOLOGY_REPORT
            ]);
            queryClient.invalidateQueries([
              queryKeys.CYCLES,
              cycleId,
              querySubKeys[queryKeys.CYCLES].GRAPH_DATA
            ]);
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].EGG_AND_EMBRYO
            ]);
            closeDialog();
          }
        }
      ),
    getEggsAndEmbryosReport: (
      patientId: string,
      cycleId: string,
      options?: UseQueryOptions<EggsAndEmbryosReport, AppError>
    ) =>
      useQuery<EggsAndEmbryosReport, AppError>(
        [
          queryKeys.PATIENTS,
          patientId,
          querySubKeys[queryKeys.PATIENTS].CYCLES,
          cycleId,
          querySubKeys[queryKeys.PATIENTS].EGG_AND_EMBRYO
        ],
        () => getEggsAndEmbryosReportRequest(patientId, cycleId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_EGGS_AND_EMBRYOS_REPORT')
            }),
          ...options
        }
      ),
    upsertNotes: () =>
      useMutation<
        EggAndEmbryoNotesToCreate,
        AppError,
        EggAndEmbryoNotesToCreate
      >(upsertNotesRequest, {
        onError: (_) => {
          openToast({
            title: t('CREATE_EGGS_NOTES_ERROR_TOAST_TITLE'),
            children: t('TRY_AGAIN_LATER'),
            type: ToastType.ERROR
          });
        },
        onSuccess: (_, { cycleId, patientId }) => {
          queryClient.invalidateQueries([
            queryKeys.PATIENTS,
            patientId,
            querySubKeys[queryKeys.PATIENTS].CYCLES,
            cycleId,
            querySubKeys[queryKeys.PATIENTS].EGG_AND_EMBRYO
          ]);
          queryClient.invalidateQueries([
            queryKeys.CYCLES,
            cycleId,
            querySubKeys[queryKeys.CYCLES].GRAPH_DATA
          ]);
          queryClient.invalidateQueries([
            queryKeys.PATIENTS,
            patientId,
            querySubKeys[queryKeys.PATIENTS].EGG_AND_EMBRYO
          ]);
        }
      }),
    updateEgg: () =>
      useMutation<
        EggsAndEmbryosReportServer,
        AppError,
        { patientId: string; eggId: string; cycleId: string; egg: UpdateEgg }
      >(
        ({
          egg,
          eggId,
          cycleId,
          patientId
        }: {
          patientId: string;
          eggId: string;
          cycleId: string;
          egg: UpdateEgg;
        }) => updateEggRequest({ patientId, cycleId, eggId, egg }),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_EGG')
            }),
          onSuccess: (_, { cycleId, patientId }) => {
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].CYCLES,
              cycleId,
              querySubKeys[queryKeys.PATIENTS].EGG_AND_EMBRYO
            ]);
            queryClient.invalidateQueries([
              queryKeys.CYCLES,
              cycleId,
              querySubKeys[queryKeys.CYCLES].GRAPH_DATA
            ]);
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].EGG_AND_EMBRYO
            ]);
          }
        }
      ),

    getPatientEggsAndEmbryos: ({ patientId }: { patientId?: string }) =>
      useQuery<EmbryologyRecord[], AppError>(
        [
          queryKeys.PATIENTS,
          patientId,
          querySubKeys[queryKeys.PATIENTS].EGG_AND_EMBRYO
        ],
        () => {
          return getEggAndEmbryoByPatientId(patientId);
        },
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_PATIENT_EGG_AND_EMBRYO')
            })
        }
      )
  };
}

export default useEggsEmbryos;
