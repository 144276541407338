import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/display/Button/Button';
import { spacings } from 'src/components/styles/constants';
import Center from 'src/components/layout/Center/Center';
import Typography from 'src/components/display/Typography';
import Flex from 'src/components/layout/Flex';
import Box from 'src/components/layout/Box';
import { fontWeights } from 'src/components/styles/fonts';
import { orderTemplateId, proceduresTemplateId } from 'src/types/documents';
import { useDocumentEditor } from 'src/contexts/AppContexts/AppContexts';

interface OpenOrderDocumentsProps {
  patientId: string;
  proceduresDocumentId?: string;
  labsDocumentId?: string;
}

const OpenOrderDocuments: FC<OpenOrderDocumentsProps> = ({
  patientId,
  labsDocumentId,
  proceduresDocumentId
}) => {
  const { t } = useTranslation();

  const { onDocumentEditorInfoChange, onDocumentsModalOpen } =
    useDocumentEditor();

  return (
    <Center sx={{ flexDirection: 'column' }} gap={spacings.large}>
      <Typography variant="h2" fontWeight={fontWeights.extraBold}>
        {t('EXTERNAL_ORDER_HAS_BEEN_CREATED')}
      </Typography>
      <Typography variant="h4">{t('PLEASE_SIGN_THE_ORDERS_NOW')}</Typography>
      <Flex gap={spacings.xlarge} alignItems="center">
        <Box>
          {labsDocumentId ? (
            <Button
              onClick={() => {
                onDocumentEditorInfoChange({
                  patientId,
                  templateId: orderTemplateId,
                  documentId: labsDocumentId
                });
                onDocumentsModalOpen(true);
              }}
            >
              {t('SIGN_LAB_ORDER')}
            </Button>
          ) : (
            <Typography>{t('NO_LAB_ORDER_TO_DISPLAY')}</Typography>
          )}
        </Box>

        <Box>
          {proceduresDocumentId ? (
            <Button
              onClick={() => {
                onDocumentEditorInfoChange({
                  patientId,
                  templateId: proceduresTemplateId,
                  documentId: proceduresDocumentId
                });
                onDocumentsModalOpen(true);
              }}
            >
              {t('SIGN_PROCEDURE_ORDER')}
            </Button>
          ) : (
            <Typography>{t('NO_PROCEDURE_ORDER_TO_DISPLAY')}</Typography>
          )}
        </Box>
      </Flex>
    </Center>
  );
};

export default OpenOrderDocuments;
