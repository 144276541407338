import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { Controller, useForm } from 'react-hook-form';

import { getExtension, getFullName } from 'src/utils/general';
import { useDialog } from 'src/contexts/UIContexts';
import Loader from 'src/components/display/Loader/Loader';
import Box from 'src/components/layout/Box/Box';
import { spacings } from 'src/components/styles/constants';
import InputField from 'src/components/data-entry/InputField/InputField';
import Flex from 'src/components/layout/Flex/Flex';
import Button from 'src/components/display/Button/Button';
import Select from 'src/components/data-entry/Select/Select';
import { AddDocumentFormValues, DocumentTypes } from 'src/types/documents';
import Typography from 'src/components/display/Typography/Typography';
import { fontWeights } from 'src/components/styles/fonts';
import FileUpload from 'src/components/data-entry/FileUpload';
import useDocumentsApi from '../../../hooks/useDocumentsApi';
import usePatientsApi from '../../../hooks/usePatientsApi';

const StyledLoaderBox = styled(Box)`
  display: flex;
  min-height: 160px;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const AddDocumentForm: FC<{
  patientId: string;
  defaultDocumentType?: DocumentTypes;
  onSuccess?: (
    documentId: string,
    documentType?: DocumentTypes,
    forceSave?: boolean
  ) => void;
}> = ({ patientId, onSuccess, defaultDocumentType }) => {
  const { t } = useTranslation();
  const [isUploadFile, setIsUploadFile] = useState(false);
  const { getPatientById } = usePatientsApi();
  const { closeAllDialogs } = useDialog();
  const {
    getDocumentsTemplates,
    createPatientDocument,
    createPatientDocumentFromTemplate
  } = useDocumentsApi();

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);

  const { data: templates, isLoading: isLoadingTemplates } =
    getDocumentsTemplates();

  const { isLoading: isUploading, mutate: createPatientDocumentMutate } =
    createPatientDocument();

  const {
    isLoading: isUploadingDocumentFromTemplate,
    mutate: createPatientDocumentFromTemplateMutate
  } = createPatientDocumentFromTemplate();

  const defaultValues: AddDocumentFormValues = {
    documentType: defaultDocumentType ?? DocumentTypes.CONSENT,
    patientName: getFullName(patient),
    templateName: '',
    file: null,
    fileName: ''
  };

  const { control, formState, handleSubmit, setValue, watch, getValues } =
    useForm<AddDocumentFormValues>({
      mode: 'onChange',
      defaultValues
    });

  const { errors } = formState;
  const { documentType } = watch();

  const getTemplateTypeOptions = (
    documentType: DocumentTypes
  ): { label: string; value: string }[] => {
    const filteredTemplates = templates?.filter(
      ({ type }) => type === documentType
    );

    if (!filteredTemplates) {
      return [];
    }

    return filteredTemplates.map(({ id, name }) => ({
      label: t(name),
      value: id
    }));
  };

  const onSubmit = async ({
    file,
    templateId,
    fileName,
    documentType
  }: AddDocumentFormValues) => {
    if (isUploadFile) {
      const fileExtension = getExtension(file.name || fileName);

      // remove extension from file name
      const fileNameWithoutExtension = fileName?.replace(/\.[^/.]+$/, '');

      const fileToSubmit: File = fileName
        ? new File([file], `${fileNameWithoutExtension}.${fileExtension}`)
        : file;

      createPatientDocumentMutate(
        {
          file: fileToSubmit,
          patientId,
          documentType
        },
        {
          onSuccess: () => {
            closeAllDialogs();
          }
        }
      );
    } else {
      createPatientDocumentFromTemplateMutate(
        { patientId, templateId },
        {
          onSuccess: (documentId: string) => {
            if (documentId) {
              closeAllDialogs();
              if (onSuccess) {
                onSuccess(documentId, documentType);
              }
            }
          }
        }
      );
    }
  };

  const templateOptions = getTemplateTypeOptions(documentType);

  useEffect(() => {
    if (!patient) return;
    setValue('patientName', getFullName(patient.personalInfo));
  }, [patient]);

  const isLoading =
    isLoadingPatient || isLoadingTemplates || isUploadingDocumentFromTemplate;

  if (isLoading)
    return (
      <StyledLoaderBox>
        <Loader />
      </StyledLoaderBox>
    );

  return (
    <Box marginTop={spacings.large} gap={spacings.large} minWidth={400}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Flex flexDirection="column" gap={spacings.xlarge}>
          <Box flex={1}>
            <Controller
              name="patientName"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  disabled={!!patient}
                  inputRef={ref}
                  label={t('PATIENT_NAME')}
                  placeholder={t('PATIENT_NAME')}
                  error={!!errors.patientName}
                  helperText={errors?.patientName?.message}
                  fullWidth
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="documentType"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  label={t('CHOOSE_DOCUMENT_TYPE')}
                  inputRef={ref}
                  error={!!errors?.documentType}
                  helperText={errors?.documentType?.message}
                  defaultOption={t('DOCUMENT_TYPE')}
                  options={Object.entries(DocumentTypes)
                    .filter(
                      ([_key, value]) => value !== DocumentTypes.MEDICAL_NOTES
                    )
                    .map(([key, value]) => ({
                      label: t(key),
                      value: value
                    }))}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            {isUploadFile ? (
              <>
                <Controller
                  name="fileName"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      label={t('DOCUMENT_NAME')}
                      placeholder={t('NAME_YOUR_DOCUMENT')}
                      error={!!errors.fileName}
                      helperText={errors?.fileName?.message}
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="file"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <FileUpload
                      submitOnFileUpload
                      maxFiles={1}
                      onUploadFiles={(files) => {
                        onChange(files[0]);
                        const currFileName = getValues('fileName');
                        if (!currFileName) {
                          setValue('fileName', files[0].name);
                        }
                      }}
                      accept={{
                        'image/png': ['.png'],
                        'image/jpeg': ['.jpeg', '.jpg'],
                        pdf: ['.pdf']
                      }}
                    />
                  )}
                />
              </>
            ) : (
              <Controller
                name="templateId"
                control={control}
                rules={{
                  required: t('TEMPLATE_NAME_REQUIRED')
                }}
                render={({ field: { ref, onChange, ...field } }) => (
                  <Select
                    {...field}
                    label={t('CHOOSE_TEMPLATE_NAME')}
                    inputRef={ref}
                    error={!!errors?.templateName}
                    helperText={errors?.templateName?.message}
                    defaultOption={t('TEMPLATE_NAME')}
                    onChange={(ev) => {
                      const { value } = ev.target;

                      const chosenTemplate = templates?.find(
                        ({ id }) => id === value
                      );

                      setValue('templateName', chosenTemplate.name);

                      onChange(value);
                    }}
                    options={templateOptions}
                  />
                )}
              />
            )}
          </Box>
        </Flex>

        <Flex
          justifyContent="center"
          paddingX={spacings.x2large}
          marginTop={spacings.x2large}
          width="50%"
          marginX="auto"
        >
          <Button fullWidth type="submit" disabled={isUploading}>
            {isUploading ? <Loader /> : t('SUBMIT')}
          </Button>
        </Flex>
        <Flex
          justifyContent="center"
          paddingX={spacings.x2large}
          marginTop={spacings.medium}
          width="50%"
          marginX="auto"
          sx={{ cursor: 'pointer' }}
          onClick={() => setIsUploadFile(!isUploadFile)}
        >
          <Typography variant="caption" fontWeight={fontWeights.extraBold}>
            {t(
              isUploadFile ? 'CHOOSE_FROM_TEMPLATE' : 'UPLOAD_A_DOCUMENT'
            ).toUpperCase()}
          </Typography>
        </Flex>
      </form>
    </Box>
  );
};
