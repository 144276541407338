import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Loader from 'src/components/display/Loader';
import MiniIconButton from 'src/components/display/MiniIconButton';
import Typography from 'src/components/display/Typography';
import Flex from 'src/components/layout/Flex';
import { spacings } from 'src/components/styles/constants';
import { fontWeights } from 'src/components/styles/fonts';
import usePatientsApi from 'src/hooks/usePatientsApi';
import { getFullName } from 'src/utils/general';

import DiagnosticsSelector, {
  getNewLetterFromIndex
} from '../DiagnosticsSelector';
import EncounterDetailsSection from '../EncounterDetailsSection';
import AddIcon from '@mui/icons-material/Add';
import CodingTable from '../CodingTable';
import { DevTool as ReactHookFormDevTool } from '@hookform/devtools';
import { v4 as uuidv4 } from 'uuid';
import useEncounters from 'src/hooks/useEncounters';
import Button from 'src/components/display/Button';
import { EncounterCodeRow, EncounterDiagnosis } from 'src/types/encounter';
import SyncIcon from '@mui/icons-material/Sync';
import { styled } from '@mui/system';
import { TooltipWrapper } from 'src/components/display/Tooltip';
import useAppointments from 'src/hooks/useAppointments';
import useCodes from 'src/hooks/useCodes';
import {
  EncounterServiceCode,
  EncounterServiceCodeType,
  ServiceType
} from 'src/types/codes';
import { OrderBundle } from 'src/types/appointment';
import { VendorsKey } from 'src/types/hl7messages';
import { Colors } from 'src/components/styles/colors';
import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';
import { queryKeys, querySubKeys } from 'src/hooks/queryKeys';
import { Documents } from './Documents';
import { createRowFromIcdCode } from 'src/modules/patients/utils/conversions';
import useCareTeam from 'src/hooks/useCareTeam';

interface CodesTabProps {
  codeRows: EncounterCodeRow[];
  diagnoses: Array<EncounterDiagnosis | null>;
  createdByStaffId?: string;
}

const StyledSyncIcon = styled(SyncIcon)`
  color: ${Colors.white};
  &.MuiSvgIcon-root {
    fill: ${Colors.white};
    stroke: ${Colors.white};
  }

  button:disabled & {
    color: ${Colors.black};
    fill: ${Colors.black};
    stroke: ${Colors.black};
  }
`;

export interface DxOptionMappedType {
  [key: string]: EncounterDiagnosis;
}

const CodingTab: FC<{
  appointmentId: string;
  patientId: string;
  onDirtyFormChange: (dirty: boolean) => void | Promise<void>;
}> = ({ appointmentId, patientId, onDirtyFormChange }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { getPatientById } = usePatientsApi();
  const {
    getEncounterByAppointmentId,
    getEncounterCodeRowsByAppointmentId,
    updateEncounterRows
  } = useEncounters();

  const {
    getAppointmentById,
    getAppointmentCodingTemplates,
    updateAppointment
  } = useAppointments();
  const { getEncounterServiceCodes, getBillingServices, getIcd10Codes } =
    useCodes();
  const { data: serviceCodeOptions, isLoading: isLoadingServiceCodeOptions } =
    getEncounterServiceCodes();
  const [icdCodesFromTemplate, setIcdCodesFromTemplate] = useState<string[]>(
    []
  );
  const { data: icd10Codes } = getIcd10Codes({
    icd10Codes: icdCodesFromTemplate
  });
  const {
    mutate: updateEncounterRowsMutation,
    isLoading: isUpdatingEncounterRows
  } = updateEncounterRows();
  const { data: encounter, isLoading: isLoadingEncounter } =
    getEncounterByAppointmentId(appointmentId);

  const { data: appointmentToEdit } = getAppointmentById(
    appointmentId,
    patientId,
    {
      enabled: !!appointmentId
    }
  );

  const { data: procedureItems, isLoading: isLoadingProcedureItems } =
    getBillingServices({ serviceType: ServiceType.PROCEDURE });

  const { getPatientPrimaryCareTeamMember } = useCareTeam();

  const {
    data: patientPrimaryCareTeamMember,
    isLoading: isLoadingPrimaryCareTeamMember
  } = getPatientPrimaryCareTeamMember(patientId);

  const { data: labs, isLoading: isLoadingLabs } = getBillingServices({
    serviceType: ServiceType.LAB,
    withVendors: true,
    vendorKeys: [VendorsKey.Labcorp, VendorsKey.Other]
  });

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);

  const { data: serverCodeRows, isLoading: isLoadingServerCodeRows } =
    getEncounterCodeRowsByAppointmentId(appointmentId);

  const [dxPointerOptions, setDxPointerOptions] = useState<DxOptionMappedType>(
    {}
  );

  const [isCodesSynced, setIsCodesSynced] = useState(false);

  const {
    data: appointmentCodingTemplates,
    isLoading: isLoadingAppointmentCodingTemplates
  } = getAppointmentCodingTemplates();

  const { mutate: updateAppointmentMutate } = updateAppointment();

  //const documents = [mockDocument, mockDocument]; // TODO - create a request that grabs related documents with the PatientDocument type
  const defaultValues: CodesTabProps = {
    diagnoses: encounter?.diagnoses || [null],
    codeRows: serverCodeRows || [],
    createdByStaffId:
      encounter?.createdByStaffId || patientPrimaryCareTeamMember?.id
  };

  const { control, formState, watch, setValue, setError, reset } =
    useForm<CodesTabProps>({
      mode: 'onChange',
      defaultValues
    });

  useEffect(() => {
    reset(defaultValues);
  }, [serverCodeRows, encounter, patientPrimaryCareTeamMember]);

  const { isDirty, errors } = formState;

  const { createdByStaffId, diagnoses, codeRows } = watch();

  useEffect(() => {
    const newDxOptions: DxOptionMappedType = {};
    diagnoses.forEach((dx, index) => {
      if (dx) {
        newDxOptions[index] = {
          index,
          description: dx.description,
          label: getNewLetterFromIndex(index),
          icd10Code: dx.icd10Code
        };
      }
    });

    setDxPointerOptions(newDxOptions);
  }, [diagnoses]);

  const handleDiagnosisIndexChange = ({
    index,
    isDeleting
  }: {
    index: number;
    isDeleting?: boolean;
  }) => {
    // remove all the pointers to the edited index
    const newCodeRows = codeRows?.map((row) => {
      const newDxPointers = row.dxPointers
        ?.filter((pointer) => pointer.index !== index)
        .map((pointer) => {
          // if remove happened, shift all following indexes down by 1
          if (isDeleting) {
            if (+pointer.index > +index) {
              const newKey = +pointer.index - 1;
              const newPointer: EncounterDiagnosis = {
                ...pointer,
                index: newKey,
                label: getNewLetterFromIndex(newKey)
              };
              return newPointer;
            }
          }
          return pointer;
        });
      return {
        ...row,
        dxPointers: newDxPointers
      };
    });
    setValue('codeRows', newCodeRows);
  };

  const isDiagnosisArrayValid = !diagnoses.filter((dx) => dx === null).length;

  const handleSubmit = () => {
    if (!isDiagnosisArrayValid) {
      setError('diagnoses', {
        type: 'custom',
        message: t('NO_NULL_DIAGNOSES_ERROR')
      });
      return;
    }

    updateEncounterRowsMutation({
      appointmentId,
      codeRows: codeRows.map(
        ({ billingService, modifiers, dxPointers, ...restOfRow }) => ({
          ...restOfRow,
          billingServiceId: billingService.id,
          modifiers: modifiers?.map((modifier) => modifier.id) || [],
          dxPointers: dxPointers?.map((pointer) => pointer.index) || []
        })
      ),
      encounterId: encounter?.id,
      encounter: {
        diagnoses,
        createdByStaffId
      }
    });
  };

  useEffect(() => {
    if (isDirty) {
      onDirtyFormChange(true);
    }
  }, [isDirty]);

  useEffect(() => {
    if (icd10Codes?.length) {
      const icd10CodesFormatted = createRowFromIcdCode(icd10Codes);

      setValue('diagnoses', icd10CodesFormatted);
    }
  }, [icd10Codes, setValue]);

  const handleApplyCodingTemplate = () => {
    if (isCodesSynced) return;
    const appointmentType = appointmentToEdit?.appointmentType;
    const appointmentTemplate = appointmentCodingTemplates?.find(
      ({ templateName }) => templateName === appointmentType
    );

    if (!appointmentTemplate) return;

    const currentRows = codeRows || [];
    const newRows: EncounterCodeRow[] =
      appointmentTemplate.orders
        ?.flatMap((order) => {
          const { code, bundle } = order || {};

          if (bundle?.bundleName) {
            return createRowsFromBundle(bundle);
          }

          return createRowFromCode(code);
        })
        .filter(Boolean) ?? [];

    setValue('codeRows', [...currentRows, ...newRows]);

    setIcdCodesFromTemplate([
      ...(Array.isArray(appointmentTemplate.orderIcd10Codes)
        ? appointmentTemplate.orderIcd10Codes
        : [])
    ]);

    if (appointmentToEdit) {
      const startTime = dayjs(appointmentToEdit.date);
      const endTime = startTime.add(
        appointmentTemplate.defaultDuration,
        'millisecond'
      );
      updateAppointmentMutate(
        {
          ...appointmentToEdit,
          room: appointmentTemplate.room,
          isVirtual: appointmentTemplate.isVirtual,
          date: startTime.toDate(),
          endTime: endTime.toDate()
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              appointmentId,
              querySubKeys[queryKeys.PATIENTS].APPOINTMENTS
            ]);
          }
        }
      );
    }

    setIsCodesSynced(true);
  };

  const createRowsFromBundle = (bundle: OrderBundle): EncounterCodeRow[] => {
    const bundleTemplate = {
      ...bundle,
      loincList: bundle.loincList
        ?.map((loinc) => labs?.find((lab) => lab.orderCode === loinc)?.id)
        .filter(Boolean),
      cptList: bundle.cptList
        ?.map(
          (cpt) => procedureItems?.find((proc) => proc.billingCode === cpt)?.id
        )
        .filter(Boolean)
    };

    if (!bundleTemplate.loincList?.length && !bundleTemplate.cptList?.length)
      return [];

    const labsWithBillingCodeType: EncounterServiceCode[] = labs?.map(
      (lab) => ({
        ...lab,
        billingCodeType: null
      })
    );

    const loincRows: EncounterCodeRow[] = bundleTemplate.loincList.map(
      (loincId) => createRowFromService(labsWithBillingCodeType, loincId)
    );
    const cptRows: EncounterCodeRow[] = bundleTemplate.cptList.map((cptId) =>
      createRowFromService(
        serviceCodeOptions,
        cptId,
        EncounterServiceCodeType.CPT
      )
    );

    return [...loincRows, ...cptRows].filter(Boolean);
  };

  const createRowFromCode = (code: string) => {
    const serviceFromDB = serviceCodeOptions?.find(
      (serviceCode) =>
        serviceCode.billingCodeType === EncounterServiceCodeType.CPT &&
        serviceCode.billingCode === code
    );

    return serviceFromDB
      ? createRowFromService(serviceCodeOptions, serviceFromDB.id)
      : null;
  };

  const createRowFromService = (
    serviceList: EncounterServiceCode[],
    serviceId: string,
    serviceType = null
  ): EncounterCodeRow => {
    const serviceFromDB = serviceList?.find((serviceCode) => {
      if (serviceType) {
        return (
          serviceCode.billingCodeType === serviceType &&
          serviceCode.id === serviceId
        );
      }
      return serviceCode.id === serviceId;
    });

    if (!serviceFromDB) return null;

    return {
      id: uuidv4(),
      encounterId: encounter.id || '',
      billingService: serviceFromDB,
      modifiers: [],
      dxPointers: [{ icd10Code: '', description: '', index: 0, label: 'A' }],
      serviceUnit: 1
    };
  };

  const isLoading =
    isLoadingEncounter ||
    isLoadingPatient ||
    isLoadingServerCodeRows ||
    isLoadingServiceCodeOptions ||
    isLoadingProcedureItems ||
    isLoadingLabs ||
    isLoadingAppointmentCodingTemplates ||
    isLoadingPrimaryCareTeamMember;

  const isSyncDisabled =
    isLoadingServerCodeRows || serverCodeRows.length > 0 || isCodesSynced;

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Flex
      flexDirection="column"
      gap={spacings.x2large}
      paddingBottom={spacings.large}
    >
      <Flex gap={spacings.x3large}>
        <Flex flexDirection="column" gap={spacings.xlarge} flex={1}>
          <Flex gap={spacings.xlarge}>
            <Controller
              name="createdByStaffId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <EncounterDetailsSection
                  patientFullName={getFullName(patient?.personalInfo)}
                  encounterDate={encounter?.encounterDate}
                  encounterId={encounter?.displayId}
                  performedBy={value}
                  performedByHeader={t('RENDERING_PROVIDER')}
                  onPerformedByChange={onChange}
                />
              )}
            />
            {
              // TODO - Bring this back when ready
              /* <InsuranceDetailsSection
                primaryInsurance={insuranceInfo?.primaryInsurance}
                secondaryInsurance={insuranceInfo?.secondaryInsurance}
                eligibility={insuranceInfo?.eligibility}
              /> */
            }
          </Flex>
          <Flex flexDirection="column">
            <Controller
              name="diagnoses"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DiagnosticsSelector
                  error={!!errors?.diagnoses}
                  helperText={errors?.diagnoses?.message}
                  values={value}
                  onChange={({
                    indexChanged,
                    diagnoses,
                    isDeleting
                  }: {
                    diagnoses: Array<EncounterDiagnosis | null>;
                    indexChanged?: number;
                    isDeleting?: boolean;
                  }) => {
                    if (indexChanged)
                      handleDiagnosisIndexChange({
                        index: indexChanged,
                        isDeleting
                      });
                    return onChange(diagnoses);
                  }}
                />
              )}
            />
          </Flex>
        </Flex>
        <Documents patientId={patientId} encounter={encounter} />
      </Flex>
      <Controller
        name="codeRows"
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <Flex justifyContent="space-between" alignItems="center">
              <Typography variant="h2" fontWeight={fontWeights.extraBold}>
                {t('SERVICE_CODING')}
              </Typography>
              <Flex gap={spacings.medium} alignItems="center">
                <Typography variant="caption">{t('SERVICE_LINE')}</Typography>
                <MiniIconButton
                  disabled={isLoadingServerCodeRows}
                  onClick={() => onChange([...value, { id: uuidv4() }])}
                  icon={<AddIcon />}
                  iconColor="emperor"
                  bgColor="alto"
                />
                <TooltipWrapper title={t('SYNC_CODES')}>
                  <MiniIconButton
                    disabled={isSyncDisabled}
                    onClick={handleApplyCodingTemplate}
                    icon={<StyledSyncIcon />}
                    bgColor="black"
                  />
                </TooltipWrapper>
              </Flex>
            </Flex>
            <CodingTable
              rows={value}
              onChange={onChange}
              dxPointerOptions={dxPointerOptions}
            />
          </>
        )}
      />
      <Flex justifyContent="flex-end">
        <Button
          type="submit"
          onClick={handleSubmit}
          disabled={isLoading || isUpdatingEncounterRows}
        >
          {isUpdatingEncounterRows ? <Loader /> : t('SAVE')}
        </Button>
      </Flex>
      <ReactHookFormDevTool control={control} />
    </Flex>
  );
};

export default CodingTab;
