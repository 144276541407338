import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/system';
import dayjs from 'dayjs';

import Button from 'src/components/display/Button/Button';
import Card from 'src/components/display/Card/Card';
import Typography from 'src/components/display/Typography/Typography';
import Flex from 'src/components/layout/Flex/Flex';
import { spacings } from 'src/components/styles/constants';
import { AvatarSizes } from 'src/components/display/Avatar/Avatar';
import Box from 'src/components/layout/Box/Box';
import { fonts, fontWeights } from 'src/components/styles/fonts';
import MiniIconButton from 'src/components/display/MiniIconButton/MiniIconButton';
import { Colors } from 'src/components/styles/colors';
import Slider from 'src/components/data-entry/Slider/Slider';
import NumberPreview from 'src/components/display/NumberPreview/NumberPreview';
import PatientAvatar from 'src/components/display/Avatar/PatientAvatar';
import {
  BasicInfo,
  HormonalData,
  HormonalDataDetails,
  PatientProperties,
  PersonSex,
  SpermAnalysis
} from 'src/types/patient';
import { UserTypes } from 'src/types/user';
import {
  getCycleLengthText,
  getNumberPreviewSizeByValue,
  getFullName,
  getUserAge
} from 'src/utils/general';
import { useGetDefaultAvatar } from 'src/utils/defaultImages';
import { longDateFormat } from 'src/utils/dateAndTIme';
import { usePopover } from 'src/contexts/UIContexts';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { PatientOverviewCardSkeleton } from '../../../components/display/Skeletons/PatientOverviewCardSkeleton';
import { BasicsForm } from '../common/BasicsForm';
import { CreateActivateCycleButton } from './CreateActivateCycleButton';

const StyledSpecsKeyTypography = styled(Typography)`
  font: ${fonts.pill};
  flex: 1;
`;
const StyledSpecsValueTypography = styled(Typography)`
  font: ${fonts.pill};
  font-weight: ${fontWeights.extraBold};
  flex: 2;
`;
export const StyledBoxWithBottomBorder = styled(Box)`
  padding-bottom: ${spacings.medium};
  border-bottom: 1px solid ${Colors.mercury};
`;

export const StyledBoxWithMarginRight = styled(Box)`
  margin-right: ${spacings.medium};
  flex-direction: column;
  flex: 1;
`;

export const StyledBoxWithMarginLeft = styled(Box)`
  margin-left: ${spacings.medium};
  flex-direction: column;
  flex: 1;
`;

export const StyledFlexboxWithBasicNumbers = styled(Flex)`
  height: 100px;

  .MuiBox-root {
    height: 100%;
    padding: 0 5%;
  }
`;

const HormonalSlider: FC<{
  label: string;
  details: HormonalDataDetails;
}> = ({ label, details: { max, min, unit, value: valueProp } }) => {
  // value is coming as string from the BE
  // here is a convertion till we fix that
  const value = Number(valueProp) || 0;
  const DEFAULT_MAX = 100;
  const DEFAULT_MIN = 0;

  return (
    <Slider
      value={value}
      label={`${label} ${unit || ''}`.trim()}
      max={max || DEFAULT_MAX}
      min={min || DEFAULT_MIN}
      disabled
      valueLabelDisplay="on"
    />
  );
};

const SpermAnalysisDisplay: FC<{ data: SpermAnalysis }> = ({ data }) => {
  const { volume, concentration, motility, morphology } = data || {};
  const { t } = useTranslation();

  return (
    <>
      <StyledBoxWithBottomBorder>
        <Typography variant="body1">
          {t('SPERM_ANALYSIS').toUpperCase()}
        </Typography>
      </StyledBoxWithBottomBorder>
      <Flex>
        <NumberPreview
          value={volume}
          topText={t('VOLUME')}
          bottomText={t('ML')}
        />
        <NumberPreview
          value={concentration}
          topText={t('CONCENTRATION')}
          bottomText={t('M/ML')}
        />
        <NumberPreview
          value={motility}
          topText={t('MOTILITY')}
          bottomText={t('PRECENT')}
        />
        <NumberPreview
          value={morphology}
          topText={t('MORPH')}
          bottomText={t('PERCENT')}
        />
      </Flex>
    </>
  );
};

const HormonalPanelDisplay: FC<{ data: HormonalData }> = ({ data }) => {
  const { t } = useTranslation();
  const hormonalDataArray = Object.entries(data || {});

  const hormonalDataColumnOne = hormonalDataArray.slice(
    hormonalDataArray.length / 2,
    hormonalDataArray.length
  );
  const hormonalDataColumnTwo = hormonalDataArray.slice(
    0,
    hormonalDataArray.length / 2
  );

  return (
    <>
      <StyledBoxWithBottomBorder marginBottom={spacings.xlarge}>
        <Typography variant="body1">{t('HORMONAL_PANEL')}</Typography>
      </StyledBoxWithBottomBorder>
      {hormonalDataColumnOne?.length > 0 ||
      hormonalDataColumnTwo?.length > 0 ? (
        <Flex gap={spacings.x2large}>
          {hormonalDataColumnOne?.length > 0 && (
            <StyledBoxWithMarginRight>
              {hormonalDataColumnOne.map(([label, details], index) => (
                <Box key={`${label}-${index}`}>
                  <HormonalSlider label={label} details={details} />
                </Box>
              ))}
            </StyledBoxWithMarginRight>
          )}
          {hormonalDataColumnTwo?.length > 0 && (
            <StyledBoxWithMarginLeft>
              {hormonalDataColumnTwo.map(([label, details], index) => (
                <Box key={`${label}-${index}`}>
                  <HormonalSlider label={`${label}`} details={details} />
                </Box>
              ))}
            </StyledBoxWithMarginLeft>
          )}
        </Flex>
      ) : (
        <Typography variant="body1">{t('NO_DATA')}</Typography>
      )}
    </>
  );
};

const UserSpecs: FC<{
  patientId: string;
  patientDisplayId?: string;
  properties: PatientProperties;
  basicInfo: BasicInfo;
  isPartner?: boolean;
}> = ({
  properties,
  basicInfo,
  patientId,
  patientDisplayId,
  isPartner = false
}) => {
  const { patientId: viewedPatientId } = useParams();
  const { t } = useTranslation();
  const { openPopover, closePopover } = usePopover();
  const { getDefaultAvatar } = useGetDefaultAvatar();

  const { age, maritalStatus, sex, dateOfBirth, phoneNumber } = basicInfo || {};

  const { months, years } = age || {};

  const { bmi, height, weight, cycle, ttc } = properties || {};

  const bmiToDisplay = Math.floor(bmi);

  const avatar = getDefaultAvatar({
    userId: patientId,
    userType: UserTypes.patients,
    pictureId: basicInfo?.pictureId
  });

  return (
    <Box>
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
        gap={spacings.xlarge}
        marginBottom={spacings.x2large}
      >
        <Box flex="0 1 auto">
          <PatientAvatar
            size={AvatarSizes.XLARGE}
            image={avatar}
            patientId={patientId}
          />
        </Box>
        <Box flex={1.5}>
          <Typography variant="h3" marginBottom={spacings.large}>
            {getFullName(basicInfo)}
          </Typography>
          <Flex gap={spacings.small}>
            <StyledSpecsKeyTypography>{t('AGE')}</StyledSpecsKeyTypography>
            <StyledSpecsValueTypography>
              {getUserAge({ months, years })}
            </StyledSpecsValueTypography>
          </Flex>
          <Flex gap={spacings.small}>
            <StyledSpecsKeyTypography>{t('DOB')}</StyledSpecsKeyTypography>
            <StyledSpecsValueTypography>
              {dayjs(dateOfBirth).format(longDateFormat)}
            </StyledSpecsValueTypography>
          </Flex>
          <Flex gap={spacings.small}>
            <StyledSpecsKeyTypography>{t('STATUS')}</StyledSpecsKeyTypography>
            <StyledSpecsValueTypography>
              {t(maritalStatus)}
            </StyledSpecsValueTypography>
          </Flex>
          <Flex gap={spacings.small}>
            <StyledSpecsKeyTypography>{t('SEX')}</StyledSpecsKeyTypography>
            <StyledSpecsValueTypography>
              {t(sex) || '--'}
            </StyledSpecsValueTypography>
          </Flex>
          <Flex gap={spacings.small}>
            <StyledSpecsKeyTypography>{t('PHONE')}</StyledSpecsKeyTypography>
            <StyledSpecsValueTypography>
              {t(phoneNumber)}
            </StyledSpecsValueTypography>
          </Flex>
          <Flex gap={spacings.small}>
            <StyledSpecsKeyTypography>
              {t('PATIENT_ID')}
            </StyledSpecsKeyTypography>
            <StyledSpecsValueTypography>
              {patientDisplayId?.toUpperCase()}
            </StyledSpecsValueTypography>
          </Flex>
        </Box>
      </Flex>
      <StyledBoxWithBottomBorder>
        <Flex gap={spacings.small} alignItems="center">
          <Typography variant="body1">{t('THE_BASICS')}</Typography>
          <MiniIconButton
            icon={<EditIcon style={{ fill: 'black' }} />}
            onClick={(ev) =>
              openPopover({
                header: t('THE_BASICS'),
                anchorEl: ev.currentTarget,
                paperPadding: spacings.large,
                children: (
                  <BasicsForm
                    width={300}
                    basics={{
                      patientId,
                      cycle,
                      height,
                      ttcMonths: ttc?.months,
                      ttcYears: ttc?.years,
                      weight
                    }}
                    sex={basicInfo?.sex}
                    invalidatePartnerId={isPartner ? viewedPatientId : null}
                    onSuccess={() => {
                      closePopover();
                    }}
                  />
                )
              })
            }
          />
        </Flex>
      </StyledBoxWithBottomBorder>
      <StyledFlexboxWithBasicNumbers
        paddingTop={spacings.large}
        alignItems="flex-start"
      >
        <NumberPreview
          height="100%"
          value={bmiToDisplay}
          topText={t('BMI')}
          paddingX={spacings.x2large}
        />
        {sex === PersonSex.FEMALE && (
          <>
            <NumberPreview
              value={ttc?.years}
              topText={t('TTC')}
              bottomText={t('YEARS')}
            />
            <NumberPreview
              size={getNumberPreviewSizeByValue(getCycleLengthText(cycle))}
              value={getCycleLengthText(cycle)}
              topText={t('CYCLE').toUpperCase()}
            />
          </>
        )}
      </StyledFlexboxWithBasicNumbers>
    </Box>
  );
};

export const PatientOverviewCard: FC<{}> = () => {
  const { patientId } = useParams();
  const { getPatientOverview, getPatientById } = usePatientsApi();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: patientOverview, isFetching: isLoadingOverview } =
    getPatientOverview(patientId);
  const { data: patient, isFetching: isLoadingPatient } =
    getPatientById(patientId);

  if (isLoadingOverview || isLoadingPatient)
    return <PatientOverviewCardSkeleton />;

  const { partnerInfo } = patientOverview || {};

  return (
    <Card shadow padding={spacings.xlarge}>
      <Flex flexDirection="column" gap={spacings.xlarge}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          marginBottom={spacings.large}
        >
          <Typography variant="h1">{t('PATIENT_OVERVIEW')}</Typography>
          <Box width="30%">
            <CreateActivateCycleButton patientId={patientId} />
          </Box>
        </Flex>
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          gap={spacings.x2large}
        >
          <Box flex={1}>
            <UserSpecs
              patientDisplayId={patientOverview?.patientBasicInfo?.displayId}
              patientId={patientId}
              properties={patientOverview?.patientProperties}
              basicInfo={patientOverview?.patientBasicInfo}
            />
          </Box>

          {partnerInfo?.id && (
            <Box flex={1}>
              <UserSpecs
                isPartner
                patientDisplayId={
                  patientOverview?.partnerInfo?.basicInfo?.displayId
                }
                patientId={partnerInfo.id}
                properties={patientOverview?.partnerInfo?.properties}
                basicInfo={patientOverview?.partnerInfo?.basicInfo}
              />
            </Box>
          )}
        </Flex>
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          gap={spacings.x2large}
          marginBottom={spacings.medium}
        >
          <Box flex={1}>
            {patient?.personalInfo?.sexAtBirth === PersonSex.FEMALE ? (
              <HormonalPanelDisplay data={patientOverview?.hormonalData} />
            ) : (
              <SpermAnalysisDisplay data={patientOverview?.spermAnalysis} />
            )}
          </Box>
          {partnerInfo &&
            (partnerInfo?.basicInfo?.sex === PersonSex.FEMALE ? (
              <Box flex={1}>
                <HormonalPanelDisplay data={partnerInfo?.hormonalData} />
              </Box>
            ) : (
              <Box flex={1}>
                <SpermAnalysisDisplay data={partnerInfo?.spermAnalysis} />
              </Box>
            ))}
        </Flex>
        <Flex justifyContent="center">
          <Button onClick={() => navigate('medical-history')}>
            {t('FULL_MEDICAL_HISTORY_CTA')}
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};
