import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';

import { spacings } from 'src/components/styles/constants';
import Flex from 'src/components/layout/Flex';
import Typography from 'src/components/display/Typography';
import { Colors } from 'src/components/styles/colors';
import { fonts, fontWeights } from 'src/components/styles/fonts';
import Avatar from 'src/components/display/Avatar/Avatar';
import Loader from 'src/components/display/Loader/Loader';
import PatientAvatar from 'src/components/display/Avatar/PatientAvatar';
import { TooltipWrapper } from 'src/components/display/Tooltip';
import IconButton from 'src/components/display/IconButton';
import { getFullName } from 'src/utils/general';
import { useGetDefaultAvatar } from 'src/utils/defaultImages';
import {
  formatDuration,
  getDateFormat,
  getTimeFormat
} from 'src/utils/dateAndTIme';
import useAppointments from 'src/hooks/useAppointments';
import useStaffMembers from 'src/hooks/useStaffMembers';
import {
  AddEditAppointmentTabs,
  Appointment,
  AppointmentPurposes,
  InCycleAppointmentTypes
} from 'src/types/appointment';
import { UserTypes } from 'src/types/user';
import { ReactComponent as BillingIcon } from 'src/assets/icons/billing.svg';
import { ReactComponent as CalendarIcon } from 'src/assets/icons/calendar2.svg';
import { ReactComponent as NotepadIcon } from 'src/assets/icons/notepad.svg';
import { ReactComponent as TrashIcon } from 'src/assets/icons/trash2.svg';
import { AppointmentCancellationDialog } from 'src/modules/calendar/AppointmentCancellationDialog';
import { useDialog } from 'src/contexts/UIContexts';
import AddEditAppointment from '../../calendar/AddEditAppointment';
import usePatientsApi from '../../../hooks/usePatientsApi';

import { OrderDocumentPreview } from './OrderDocumentPreview';

interface AppointmentDetailsProps {
  patientId: string;
  appointmentId?: string;
  appointment?: Appointment;
}

const ICON_SIZE = '19px';

const StyledIconButton = styled(IconButton)`
  color: black;
  background-color: ${Colors.transparent};

  &.MuiButtonBase-root {
    padding: ${spacings.none} 3px;
  }

  svg {
    width: ${ICON_SIZE};
  }

  :hover {
    background-color: ${Colors.transparent};
  }
`;

const CloseIconButton = styled(IconButton)`
  color: ${Colors.black};
  background-color: ${Colors.whiteSand};

  &.MuiButtonBase-root {
    margin-left: ${spacings.small};
    padding: ${spacings.xsmall};
    font-size: ${fonts.header3};
  }

  svg {
    width: ${ICON_SIZE};
    height: ${ICON_SIZE};
  }
`;

const DetailsBox = ({
  title,
  value,
  textAlign = 'start',
  valueElementId
}: {
  title: string;
  value: any;
  textAlign?: 'center' | 'start' | 'end';
  valueElementId?: string;
}) => {
  const { t } = useTranslation();
  const valueTypographyProps: { id?: string } = {};

  if (valueElementId) {
    valueTypographyProps.id = valueElementId;
  }

  return (
    <Flex flexDirection="column">
      <Typography
        variant="body1"
        fontWeight={fontWeights.extraBold}
        color={Colors.emperor}
      >
        {title}
      </Typography>
      <Typography
        {...valueTypographyProps}
        variant="body1"
        color={Colors.emperor}
        textAlign={textAlign}
      >
        {value || t('NO_DATA')}
      </Typography>
    </Flex>
  );
};

export const AppointmentDetails: FC<AppointmentDetailsProps> = ({
  appointmentId,
  patientId,
  appointment: appointmentProp
}) => {
  const currentAppointmentId = appointmentId || appointmentProp?.id;
  const { t } = useTranslation();
  const { getDefaultAvatar } = useGetDefaultAvatar();
  const { openDialog, closeDialog } = useDialog();
  const { getPatientById } = usePatientsApi();
  const { getStaffMembersNames } = useStaffMembers();
  const { getAppointmentById } = useAppointments();

  const {
    data: appointment,
    isLoading: isLoadingAppointment,
    isFetching: isFetchingAppointment
  } = getAppointmentById(currentAppointmentId, patientId, {
    enabled: !!currentAppointmentId && !!patientId
  });

  const appointmentToDisplay = useMemo<Appointment>(
    () => appointment || appointmentProp,
    [appointment, appointmentProp]
  );

  const {
    data: staffMembers,
    isLoading: isLoadingStaffMembers,
    isFetching: isFetchingStaffMembers
  } = getStaffMembersNames();

  const {
    data: patient,
    isLoading: isLoadingPatient,
    isFetching: isFetchingPatient
  } = getPatientById(appointmentToDisplay?.patientId);

  const isLoading =
    isLoadingAppointment ||
    isFetchingAppointment ||
    isLoadingStaffMembers ||
    isFetchingStaffMembers ||
    isLoadingPatient ||
    isFetchingPatient;

  const {
    appointmentType,
    date,
    duration,
    location,
    staffIds,
    patientNotes,
    isVirtual,
    internalNotes
  } = appointmentToDisplay || {
    appointmentType: InCycleAppointmentTypes.EGG_RETRIEVAL,
    appointmentPurpose: AppointmentPurposes.IN_CYCLE,
    patient: {
      personalInfo: {
        firstName: 'Jane',
        lastName: 'Doe'
      }
    }
  };

  const personalInfo = patient?.personalInfo;

  const physicians =
    staffMembers?.filter(({ id }) => staffIds?.includes(id)) || [];

  const patientFullName = getFullName(personalInfo);

  const avatar = getDefaultAvatar({
    userId: patientId,
    userType: UserTypes.patients,
    pictureId: patient?.personalInfo?.pictureId
  });

  const goToEditAppointment = (defaultTab?: AddEditAppointmentTabs) => {
    openDialog({
      header: t('EDIT_APPOINTMENT'),
      children: (
        <AddEditAppointment
          appointmentId={appointmentToDisplay?.id}
          patientId={appointmentToDisplay?.patientId}
          defaultTab={defaultTab}
        />
      ),
      fullWidth: true,
      fullHeight: true,
      maxWidth: 'lg'
    });
  };

  if (isLoading)
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        width="100%"
        minHeight={300}
      >
        <Loader />
      </Flex>
    );

  return (
    <Flex
      flexDirection="column"
      gap={spacings.xlarge}
      minWidth={550}
      padding={spacings.large}
    >
      <Flex gap={spacings.medium}>
        <Flex flexGrow={1} flexDirection="column" gap={spacings.small}>
          <Flex>
            <Flex flexDirection="row" alignItems="center" gap={spacings.large}>
              <PatientAvatar image={avatar} patientId={patientId} />
              <Typography
                id="appointment-details-patient-name"
                variant="h1"
                color={Colors.emperor}
              >
                {`${patientFullName || t('PATIENT_PLACEHOLDER')}`}
              </Typography>
            </Flex>
          </Flex>
          <Typography
            id="appointment-details-type"
            variant="h2"
            color={Colors.emperor}
          >
            {`${appointmentType || t('APPOINTMENT_TYPE_PLACEHOLDER')}`}
          </Typography>
        </Flex>
        <Flex flexWrap="nowrap">
          <TooltipWrapper arrow placement="top" title={t('CODING')}>
            <span>
              <StyledIconButton
                icon={<BillingIcon />}
                onClick={() => {
                  goToEditAppointment(AddEditAppointmentTabs.CODING);
                }}
              />
            </span>
          </TooltipWrapper>
          <TooltipWrapper arrow placement="top" title={t('ENCOUNTER_NOTE')}>
            <span>
              <StyledIconButton
                icon={<NotepadIcon />}
                onClick={() => {
                  goToEditAppointment(AddEditAppointmentTabs.ENCOUNTER_NOTE);
                }}
              />
            </span>
          </TooltipWrapper>
          <TooltipWrapper arrow placement="top" title={t('SCHEDULE_OR_ORDERS')}>
            <span>
              <StyledIconButton
                id="edit-appointment-button"
                icon={<CalendarIcon />}
                onClick={() => {
                  goToEditAppointment(
                    AddEditAppointmentTabs.SCHEDULE_OR_ORDERS
                  );
                }}
              />
            </span>
          </TooltipWrapper>
          <TooltipWrapper arrow placement="top" title={t('CANCEL_APPOINTMENT')}>
            <span>
              <StyledIconButton
                icon={<TrashIcon />}
                onClick={async () => {
                  openDialog({
                    header: t('CONFIRM_CANCEL_APPOINTMENT'),
                    children: (
                      <AppointmentCancellationDialog
                        appointment={appointment}
                      />
                    ),
                    maxWidth: 'sm',
                    hideCloseButton: true
                  });
                }}
              />
            </span>
          </TooltipWrapper>
          <span>
            <CloseIconButton
              icon={<CloseIcon />}
              onClick={closeDialog}
              bgColor="alto2"
            />
          </span>
        </Flex>
      </Flex>
      <Flex gap={spacings.x2large}>
        <DetailsBox
          title={t('DATE_OF_APPOINTMENT')}
          value={dayjs(date).format(getDateFormat())}
          valueElementId="appointment-details-date"
        />
        <DetailsBox
          title={t('START_TIME').toUpperCase()}
          valueElementId="appointment-details-start-time"
          value={dayjs(date).format(getTimeFormat({ isShort: true }))}
        />
        <DetailsBox
          title={t('DURATION').toUpperCase()}
          valueElementId="appointment-details-duration"
          value={formatDuration(duration)}
        />
      </Flex>
      <Flex>
        <DetailsBox
          title={t('LOCATION_LABEL')}
          value={isVirtual ? t('VIRTUAL') : location}
          valueElementId="appointment-details-location"
        />
      </Flex>
      <Flex gap={spacings.large} flexDirection="column" flex={0.5}>
        <Typography
          variant="h3"
          fontWeight={fontWeights.extraBold}
          color={Colors.emperor}
        >
          {t('PHYSICIAN_LABEL')}
        </Typography>
        <Flex flexWrap="wrap" gap={spacings.large}>
          {physicians?.map((physician, index) => {
            return (
              <Avatar
                key={index}
                alignAvatar="left"
                title={getFullName(physician)}
                subtitle={physician?.role}
              />
            );
          })}
        </Flex>
      </Flex>
      <Flex gap={spacings.large} flexDirection="column">
        <Typography
          variant="h3"
          fontWeight={fontWeights.extraBold}
          color={Colors.emperor}
        >
          {t('NOTES').toUpperCase()}
        </Typography>
        <Flex flexDirection="column" gap={spacings.large}>
          <DetailsBox title={t('NOTES_FOR_PATIENT')} value={patientNotes} />
          <DetailsBox
            title={t('INTERNAL_NOTES').toUpperCase()}
            value={internalNotes}
          />
        </Flex>
      </Flex>
      <Flex gap={spacings.large} flexDirection="column">
        <Typography
          variant="h3"
          fontWeight={fontWeights.extraBold}
          color={Colors.emperor}
        >
          {t('ORDERS').toUpperCase()}
        </Typography>
        <Flex gap={spacings.medium}>
          {appointmentToDisplay?.orderDocumentIds?.lab_order && (
            <OrderDocumentPreview
              orderId={appointmentToDisplay?.orderDocumentIds.lab_order}
              patientId={patientId}
            />
          )}
          {appointmentToDisplay?.orderDocumentIds?.procedure_order && (
            <OrderDocumentPreview
              orderId={appointmentToDisplay?.orderDocumentIds.procedure_order}
              patientId={patientId}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
