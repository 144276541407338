import { Dayjs } from 'dayjs';
import { ImageFile } from 'src/components/data-entry/ImageUpload/ImageUpload';
import { TimeUnits } from 'src/utils/types';
import { PatientPersonalInfo } from './patient';
import { Clinic } from './clinic';
import { YesOrNo } from './global';
import {
  BillingServiceCode,
  EncounterServiceCodeType,
  Icd10Code
} from './codes';
import { Encounter } from './encounter';
import { OrderFormServer } from './patient-server';

export enum ConsultationAppointmentTypes {
  NEW_PATIENT_CONSULTATION = 'New Patient Consultation',
  NEW_PATIENT_EGG_FREEZING = 'New Patient Egg Freezing',
  FOLLOW_UP_VISIT = 'Follow-up Visit General',
  FOLLOW_UP_VISIT_IVF_PREP = 'Follow-up Visit IVF Prep',
  FOLLOW_UP_VISIT_PGTA_RESULTS = 'Follow-up Visit PGTA Results',
  FOLLOW_UP_VISIT_PREGNANCY_TEST = 'Follow-up Visit Pregnancy Test',
  FOLLOW_UP_VISIT_POST_OP = 'Follow-up Visit Post-Op',
  PHONE_CONSULT = 'Phone Consult',
  RN_HEALTHCARE_PROFESSIONAL_CONSULT = 'RN/Healthcare Professional Consult',
  SURGICAL_CONSULT = 'Surgical Consult',
  GESTATIONAL_CARRIER_CONSULTATION = 'Gestational Carrier Consultation',
  DONOR_SCREENING = 'Donor Screening',
  CONSENTS = 'Consents',
  INJECTION_LESSON_IVF = 'Injection Lesson (IVF)'
}

export enum PregnancyAppointmentTypes {
  PREGNANCY_TEST_BLOODWORK = 'Pregnancy test (bloodwork)',
  OB_ULTRASOUND_TRANSVAGINAL = 'OB Ultrasound (Transvaginal)'
}

export enum DiagnosticAppointmentTypes {
  HSG_HYCOSY_HYFOSY = 'HSG/HyCoSy/HyFoSy',
  SALINE_SONOHYSTEROGRAM = 'Saline Sonohysterogram',
  ENDOMETRIAL_BIOPSY = 'Endometrial Biopsy',
  BLOOD_DRAW_ONLY = 'Blood Draw Only',
  SEMEN_ANALYSIS = 'Semen Analysis',
  ANTRAL_FOLLICLE_COUNT = 'Antral Follicle Count (AFC) US',
  TRIAL_EMBRYO_TRANSFER = 'Trial Embryo Transfer',
  CYST_CHECK = 'Cyst Check',
  HYSTEROSCOPY = 'Hysteroscopy',
  URINALYSIS = 'Urinalysis',
  WOMENS_EXAM = 'Womens Exam'
}

export enum MaleProcedureAppointmentTypes {
  MICRO_TESE = 'Micro TESE (off-site)',
  PESA = 'PESA',
  SPERM_CRYO_FREEZE = 'Sperm Cryo (Freeze)',
  MALE_FDA_SPERM_FREEZE = 'Male FDA and Sperm Freeze'
}

export enum SurgeryAppointmentTypes {
  SURGERY = 'Surgery'
}

export enum InCycleAppointmentTypes {
  EGG_RETRIEVAL = 'Egg Retrieval',
  IUI = 'IUI',
  FROZEN_EMBRYO_TRANSFER = 'Frozen Embryo Transfer',
  FRESH_EMBRYO_TRANSFER = 'Fresh Embryo Transfer',
  EMBRYOLOGY = 'Embryology',
  BASELINE_IVF = 'Baseline - IVF',
  BASELINE_IUI = 'Baseline - IUI',
  BASELINE_FET = 'Baseline - FET',
  BASELINE_TI = 'Baseline - TI',
  MONITORING_IVF = 'Monitoring - IVF',
  MONITORING_IUI = 'Monitoring - IUI',
  MONITORING_FET = 'Monitoring - FET',
  MONITORING_TI = 'Monitoring - TI',
  MONITORING_EXTERNAL = 'Monitoring - External',
  LUTEAL_LABS = 'Luteal Labs',
  OUTSIDE_MONITORING = 'Outside Monitoring',
  DOWN_REGULATION_FET = 'Downregulation - FET',
  DOWN_REGULATION_IVF = 'Downregulation - IVF',
  NURSE_INJECTION = 'Nurse Injection',
  MONITORING_POST_TRIGGER = 'Monitoring - Post Trigger',
  SPERM_PREP_IUI = 'Sperm Prep for IUI',
  SPERM_PREP_IUI_THAW = 'Sperm Prep for IUI (thaw)',
  SPERM_PREP_IVF = 'Sperm Prep for IVF'
}

export enum ThirdPartyScreeningAppointmentTypes {
  GESTATIONAL_CARRIER_CONSULTATION = 'Gestational Carrier Consultation',
  FRESH_DONOR_CONSULTATION = 'Fresh Donor Consultation'
}

export enum FinancialAppointmentTypes {
  FINANCIAL_CONSULTATION = 'Financial Consultation',
  BILLING_CHARGES = 'Billing Charges',
  CC_PROCESSING_FEE = 'CC Processing Fee'
}

export enum AppointmentTypeCategory {
  EGG_RETRIEVAL = 'Egg Retrieval',
  IUI = 'IUI',
  MONITORING = 'Monitoring',
  TRANSFER = 'Transfer',
  DIAGNOSTIC_APPOINTMENT = 'Diagnostic',
  FOLLOWUP = 'Followup',
  INITIAL_CONSULTATION = 'Initial Consultation',
  ULTRASOUND = 'Ultrasound',
  ALL_COUNSELING_EDUCATION = 'All Counseling/Education',
  THIRD_PARTY = '3rd Party Screening',
  URGENT_CONSULT = 'Urgent Consult',
  CONSULTATION = 'Consultation',
  PREGNANCY = 'Pregnancy',
  EMBRYO_TRANSFER = 'Embryo Transfer',
  EMBRYOLOGY = 'Embryology',
  SPERM_PREP = 'Sperm Prep',
  MALE_PROCEDURES = 'Male Procedures',
  SURGERY = 'Surgery',
  FINANCIAL = 'Financial',
  LAB_ORDER = 'Lab Order',
  EXTERNAL_MONITORING = 'External Monitoring'
}

export interface ClincRoomsBusyTimesRequest {
  roomId: string;
  minDate: Dayjs;
  maxDate: Dayjs;
}

export interface StaffBusyTimesRequest {
  staffIds: string[];
  minDate: Dayjs;
  maxDate: Dayjs;
}

export enum AppointmentPurposes {
  IN_CYCLE = 'IN_CYCLE',
  DIAGNOSTIC = 'DIAGNOSTIC',
  CONSULTATION = 'CONSULTATION',
  PREGNANCY = 'PREGNANCY',
  THIRD_PARTY_SCREENING = 'THIRD_PARTY_SCREENING',
  FINANCIAL = 'Financial',
  MALE_PROCEDURES = 'MALE_PROCEDURES',
  SURGERY = 'SURGERY'
}

export enum AllPurposesOption {
  ALL_TYPES = 'ALL_TYPES'
}

export enum DefaultClinicRoomOptions {
  SCAN_ROOM = 'SCAN_ROOM',
  THEATER = 'THEATER'
}

export const VIRTUAL_LOCATION = 'Virtual';

export const ALL_ROOMS_OPTION = 'ALL_ROOMS_OPTION';
export const NO_ROOM_OPTION = 'NO_ROOM';

export type AppointmentPurposeFilterOptions =
  | AllPurposesOption
  | AppointmentPurposes;

export type AppointmentTypes =
  | DiagnosticAppointmentTypes
  | PregnancyAppointmentTypes
  | ConsultationAppointmentTypes
  | InCycleAppointmentTypes
  | ThirdPartyScreeningAppointmentTypes
  | FinancialAppointmentTypes
  | MaleProcedureAppointmentTypes
  | SurgeryAppointmentTypes;

export interface LabTest {
  id: string;
  name: string;
  sampleType: string[];
  tatHours: number;
  category: string;
  labCode?: string;
  units?: string;
  min?: number;
  max?: number;
}

export enum OrderPurpose {
  DIAGNOSTIC = 'Diagnostic',
  IN_CYCLE = 'In Cycle',
  PREGNANCY = 'Pregnancy'
}

export const orderPurposeEntries = Object.entries(OrderPurpose);

interface LabOrderBasics {
  id?: string;
  appointmentId?: string;
  patientId?: string;
  purpose?: OrderPurpose;
  requestingPhysician?: string;
  createdByStaffId?: string;
  isUrgent?: boolean;
  billTo?: LabOrderBillTo;
  performingLabEmail?: string;
  isFasting?: boolean;
  externalId?: string;
  isInitialLabOrderEmailSent?: boolean;
}

interface LabOrderWithLabTest extends LabOrderBasics {
  labTestId: string;
  labTest: LabTest;
  panelId?: string;
  panel?: Panel;
}

interface LabOrderWithPanel extends LabOrderBasics {
  labTestId?: string;
  labTest?: LabTest;
  panelId: string;
  panel: Panel;
}

export type LabOrder = LabOrderWithPanel | LabOrderWithLabTest;

export enum ThirdPartyAppointmentStatusType {
  PRE_ARRIVAL = 'Pre-Arrival',
  CONFIRMED = 'Confirmed',
  CHECKED_IN = 'Checked In',
  COMPLETED = 'Completed',
  Consultation = 'Consultation',
  Diagnostics = 'Diagnostics',
  WAITING_FOR_RESULTS = 'Waiting for Results',
  RESULTS_AVAILABLE = 'Results Available',
  REVIEWED_BY_PROVIDER = 'Reviewed by Provider',
  IN_APPOINTMENT = 'In Appointment',
  DISCHARGED = 'Discharged',
  NO_SHOW = 'No Show'
}

export const ThirdPartyAppointmentStatusTypeEntries = Object.entries(
  ThirdPartyAppointmentStatusType
);

export interface LabOrderInfo {
  isUrgent?: boolean;
  billTo?: LabOrderBillTo;
  performingLabEmail?: string;
  isFasting?: boolean;
  externalId?: string;
}

/**
 * This is a temporary workaround. When adding a new type with key of type Dayjs,
 * the build fails due to a memory issue. This might be related to a circular dependency problem somewhere in the code.
 * This type uses only the necessary fields from Encounter to avoid it.
 */
export type AppointmentEncounterData = Omit<
  Encounter,
  | 'patientId'
  | 'displayId'
  | 'encounterDate'
  | 'claimId'
  | 'encounterNotes'
  | 'actionsTaken'
  | 'results'
  | 'appointmentId'
  | 'diagnoses'
  | 'lockedByStaffId'
  | 'lockedAt'
  | 'unlockedByStaffId'
  | 'unlockedAt'
  | 'createdByStaffId'
  | 'amendments'
>;

export interface Appointment {
  id?: string;
  patientId?: string;
  patient?: Partial<PatientPersonalInfo> & { clinic?: Clinic };
  cycleId?: string;
  currentCycleId?: string;
  isVirtual?: boolean;
  createdByStaff?: string;
  date?: Date;
  startTime?: Date;
  endTime?: Date;
  duration?: Record<TimeUnits, number>;
  cycleStartDate?: any;
  staffIds?: string[];
  appointmentType?: AppointmentTypes;
  appointmentPurpose?: AppointmentPurposes;
  location?: string;
  room?: string;
  patientNotes?: string;
  internalNotes?: string;
  panelIds?: string[];
  labTestIds?: string[];
  labOrders?: LabOrder[];
  status?: AppointmentStatusType;
  instructions?: string;
  createdByPatient?: string;
  labOrderInfo?: LabOrderInfo;
  cancellationReason?: string;
  encounter?: AppointmentEncounterData;
  orderDocumentIds?: {
    lab_order: string;
    procedure_order: string;
  };
  labOrder?: OrderFormServer;
  procedureOrder?: OrderFormServer;
}

const CommonAppointmentStatusType = {
  PRE_ARRIVAL: 'Pre-Arrival',
  CHECKED_IN: 'Checked In',
  COMPLETED: 'Completed',
  NO_SHOW: 'No Show'
};

const CommonResultStatusType = {
  WAITING_FOR_RESULTS: 'Waiting for Results',
  INSTRUCTIONS_REQUIRED: 'Instructions Required',
  PARTIAL_RESULTS: 'Partial Results',
  INSTRUCTIONS_GIVEN_TO_PATIENTS: 'Instructions Given to Patients',
  CONFIRMED: 'Confirmed',
  DIAGNOSTIC: 'Diagnostics'
};

const PostArrivalStatusType = {
  IN_APPOINTMENT: 'In Appointment',
  DISCHARGED: 'Discharged'
};

const RoutineAppointmentStatusType = {
  ...CommonAppointmentStatusType,
  ...PostArrivalStatusType,
  WITH_NURSE: 'With Nurse'
};

const CommonEggAndEmbryoTransfer = {
  CONFIRMED: 'Confirmed',
  PRE_OP: 'Pre-Op',
  PROCEDURE: 'Procedure',
  POST_OP: 'Post-Op',
  DISCHARGED: 'Discharged'
};

export const CycleMonitoringAppointmentStatus = {
  PRE_ARRIVAL: 'Pre-Arrival',
  CONFIRMED: 'Confirmed',
  CHECKED_IN: 'Checked In',
  DIAGNOSTIC: 'Diagnostics',
  WAITING_FOR_RESULTS: 'Waiting for Results',
  PARTIAL_RESULTS: 'Partial Results',
  INSTRUCTIONS_REQUIRED: 'Instructions Required',
  INSTRUCTIONS_GIVEN_TO_PATIENTS: 'Instructions Given to Patients',
  COMPLETE: 'Complete',
  NO_SHOW: 'No Show',
  REVIEWED_BY_DR: 'Reviewed by Dr'
};

type CycleMonitoringAppointmentStatusType =
  (typeof CycleMonitoringAppointmentStatus)[keyof typeof CycleMonitoringAppointmentStatus];
export const CycleMonitoringAppointmentStatusKeys = Object.keys(
  CycleMonitoringAppointmentStatus
);

export const DiagnosticAppointmentAppointmentStatus = {
  PRE_ARRIVAL: 'Pre-Arrival',
  CONFIRMED: 'Confirmed',
  CHECKED_IN: 'Checked In',
  NO_SHOW: 'No Show',
  DISCHARGED: 'Discharged',
  REVIEWED_BY_DR: 'Reviewed by Dr'
};

type DiagnosticAppointmentAppointmentStatusType =
  (typeof DiagnosticAppointmentAppointmentStatus)[keyof typeof DiagnosticAppointmentAppointmentStatus];
export const DiagnosticAppointmentAppointmentStatusKeys = Object.keys(
  DiagnosticAppointmentAppointmentStatus
);

export const FollowupAppointmentStatus = {
  ...RoutineAppointmentStatusType
};

type FollowupAppointmentStatusType =
  (typeof FollowupAppointmentStatus)[keyof typeof FollowupAppointmentStatus];
export const FollowupAppointmentStatusKeys = Object.keys(
  FollowupAppointmentStatus
);

export const InitialConsultationAppointmentStatus = {
  ...RoutineAppointmentStatusType
};

type InitialConsultationAppointmentStatusType =
  (typeof InitialConsultationAppointmentStatus)[keyof typeof InitialConsultationAppointmentStatus];
export const InitialConsultationAppointmentStatusKeys = Object.keys(
  InitialConsultationAppointmentStatus
);

export const AnyUltrasoundAppointmentStatus = {
  ...CommonAppointmentStatusType,
  ULTRASOUND: 'Ultrasound',
  WAITING_FOR_RESULTS: 'Waiting for Results',
  RESULTS_AVAILABLE: 'Results Available',
  REVIEWED_BY_DR: 'Reviewed by Dr',
  DISCHARGED: 'Discharged'
};

type AnyUltrasoundAppointmentStatusType =
  (typeof AnyUltrasoundAppointmentStatus)[keyof typeof AnyUltrasoundAppointmentStatus];
export const AnyUltrasoundAppointmentStatusKeys = Object.keys(
  AnyUltrasoundAppointmentStatus
);

export const AllCounselingEducationAppointmentStatus = {
  ...CommonAppointmentStatusType,
  ...PostArrivalStatusType
};

type AllCounselingEducationAppointmentStatusType =
  (typeof AllCounselingEducationAppointmentStatus)[keyof typeof AllCounselingEducationAppointmentStatus];
export const AllCounselingEducationAppointmentStatusKeys = Object.keys(
  AllCounselingEducationAppointmentStatus
);

export const UrgentConsultAppointmentStatus = {
  ...CommonAppointmentStatusType,
  ...PostArrivalStatusType,
  CONFIRMED: 'Confirmed',
  CONSULT: 'Consult',
  DIAGNOSTICS: 'Diagnostics',
  TREATMENT_PLAN: 'Treatment Plan',
  COUNSELING: 'Counseling'
};

type UrgentConsultAppointmentStatusType =
  (typeof UrgentConsultAppointmentStatus)[keyof typeof UrgentConsultAppointmentStatus];
export const UrgentConsultAppointmentStatusKeys = Object.keys(
  UrgentConsultAppointmentStatus
);

export const EggAndEmbryoTransferAppointmentStatus = {
  ...CommonAppointmentStatusType,
  ...CommonEggAndEmbryoTransfer
};

type EggAndEmbryoTransferAppointmentStatusType =
  (typeof EggAndEmbryoTransferAppointmentStatus)[keyof typeof EggAndEmbryoTransferAppointmentStatus];
export const EggAndEmbryoTransferAppointmentStatusKeys = Object.keys(
  EggAndEmbryoTransferAppointmentStatus
);

export const IUIAppointmentStatus = {
  ...CommonAppointmentStatusType,
  CONFIRMED: 'Confirmed',
  PRE_PROCEDURE_CHECK: 'Pre-Procedure Check',
  PROCEDURE: 'Procedure',
  POST_PROCEDURE_CHECK: 'Post-Procedure Check',
  DISCHARGED: 'Discharged'
};

export const SpermPrepAppointmentStatus = {
  PRE_ARRIVAL: 'Pre-Arrival',
  CONFIRMED: 'Confirmed',
  CHECKED_IN: 'Checked In',
  DIAGNOSTICS: 'Diagnostics',
  WAITING_FOR_RESULTS: 'Waiting for Results',
  PARTIAL_RESULTS: 'Partial Results',
  INSTRUCTIONS_REQUIRED: 'Instructions Required',
  INSTRUCTIONS_GIVEN_TO_PATIENT: 'Instructions Given to Patient',
  COMPLETE: 'Complete',
  NO_SHOW: 'No Show'
};

export const MaleProceduresAppointmentStatus = {
  PRE_ARRIVAL: 'Pre-Arrival',
  CONFIRMED: 'Confirmed',
  CHECKED_IN: 'Checked In',
  PROCEDURE: 'Procedure',
  DISCHARGED: 'Discharged',
  NO_SHOW: 'No Show'
};

export const SurgeryAppointmentStatus = {
  PRE_ARRIVAL: 'Pre-Arrival',
  CONFIRMED: 'Confirmed',
  CHECKED_IN: 'Checked In',
  PRE_OP: 'Pre-Op',
  PROCEDURE: 'Procedure',
  POST_OP: 'Post-Op',
  DISCHARGED: 'Discharged',
  NO_SHOW: 'No Show'
};

export const FinancialAppointmentStatus = {
  PRE_ARRIVAL: 'Pre-Arrival',
  CONFIRMED: 'Confirmed',
  CHECKED_IN: 'Checked In',
  IN_APPOINTMENT: 'In Appointment',
  COMPLETE: 'Complete',
  NO_SHOW: 'No Show'
};

type IUIAppointmentStatusType =
  (typeof IUIAppointmentStatus)[keyof typeof IUIAppointmentStatus];
export const IUIAppointmentStatusKeys = Object.keys(IUIAppointmentStatus);

export const SpermPrepAppointmentStatusKeys = Object.keys(
  SpermPrepAppointmentStatus
);

export const MaleProceduresAppointmentStatusKeys = Object.keys(
  MaleProceduresAppointmentStatus
);

export const SurgeryAppointmentStatusKeys = Object.keys(
  SurgeryAppointmentStatus
);

export const FinancialAppointmentStatusKeys = Object.keys(
  FinancialAppointmentStatus
);
export const ConsultationStatus = {
  PRE_ARRIVAL: 'Pre-Arrival',
  CONFIRMED: 'Confirmed',
  CHECKED_IN: 'Checked In',
  IN_APPOINTMENT: 'In Appointment',
  COMPLETED: 'Completed',
  NO_SHOW: 'No Show'
};

type ConsultationStatusType =
  (typeof ConsultationStatus)[keyof typeof ConsultationStatus];
export const ConsultationStatusKeys = Object.keys(ConsultationStatus);

export const PregnancyStatus = {
  ...CommonAppointmentStatusType,
  ...CommonResultStatusType,
  REVIEWED_BY_DR: 'Reviewed by Dr'
};

type PregnancyStatusType =
  (typeof PregnancyStatus)[keyof typeof PregnancyStatus];
export const PregnancyStatusKeys = Object.keys(PregnancyStatus);

export enum CancelledAppointmentStatusType {
  CANCELLED = 'Cancelled'
}

export type AppointmentStatusType =
  | EggAndEmbryoTransferAppointmentStatusType
  | UrgentConsultAppointmentStatusType
  | AllCounselingEducationAppointmentStatusType
  | AnyUltrasoundAppointmentStatusType
  | InitialConsultationAppointmentStatusType
  | FollowupAppointmentStatusType
  | DiagnosticAppointmentAppointmentStatusType
  | CycleMonitoringAppointmentStatusType
  | ThirdPartyAppointmentStatusType
  | IUIAppointmentStatusType
  | ConsultationStatusType
  | PregnancyStatusType
  | EggAndEmbryoTransferAppointmentStatusType
  | CancelledAppointmentStatusType;

export enum OrderStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
  ON_HOLD = 'on-hold',
  REVOKED = 'revoked',
  COMPLETED = 'completed',
  ENTERED_IN_ERROR = 'entered-in-error',
  UNKNOWN = 'unknown',
  CANCELLED = 'cancelled'
}

export enum OrderType {
  LAB_ORDER = 'lab_order',
  PROCEDURE_ORDER = 'procedure_order'
}

export type AppointmentOrOrderPurpose = AppointmentPurposes | OrderPurpose;

export interface OrderFormParams {
  patientId: string;
  patientName?: string;
  purpose: AppointmentOrOrderPurpose;
  requestingPhysician: string;
  id?: string;
  labTestsIds?: string[];
  labInstructions?: string;
  labOrderIcd10Codes?: Icd10Code[];
  proceduresIds?: string[];
  proceduresInstructions?: string;
  procedureOrderIcd10Codes?: Icd10Code[];
  urgency: Urgency;
  billTo: LabOrderBillTo;
  vendorId?: string;
  isFasting?: YesOrNo;
  externalId?: string;
  bundleNames?: string[];
  status?: OrderStatus;
}

export interface OrderBundle {
  clinicId: string;
  vendorName: string;
  loincList?: string[];
  cptList?: string[];
  bundleName: string;
  urgency?: Urgency;
  purpose?: AppointmentOrOrderPurpose;
  procedureOrderIcd10Codes?: string[];
  labOrderIcd10Codes?: string[];
}

export type CreateOrderResponse = Record<OrderType, string>;

export enum Urgency {
  URGENT = 'Urgent',
  ROUTINE = 'Routine',
  ASAP = 'ASAP',
  STAT = 'Stat'
}

export const urgencyEntries = Object.entries(Urgency);

export enum AppointmentStatus {
  UPCOMING = 'upcoming',
  PREVIOUS = 'previous'
}

export interface LabResult {
  id: string;
  testId: string;
  value: string;
  minRange?: number;
  maxRange?: number;
  patientId: string;
  cycleId?: string;
  date: string;
  comment?: string;
  measurementType?: string;
  labTest?: {
    category: string;
    id: string;
    labCode: string;
    min?: number;
    max?: number;
    name: string;
    sampleTYpe: string[];
    tatHours: number;
    units?: string;
  };
  externalLabImage?: ImageFile;
  groupId?: string;
  isFlagged?: boolean;
  realId?: string;
}

export interface LabResultClient {
  id?: string;
  patientId: string;
  date: Date;
  testId: string;
  value: string;
  measurementType: string;
  comment?: string;
  cycleId?: string;
}

export enum TestNameTypes {
  ESTRADIOL = 'ESTRADIOL',
  FSH = 'FSH',
  PROGESTERONE = 'PROGESTERONE',
  LH = 'LH',
  BETA_HCG = 'Beta HCG'
}
export enum TestMeasurementTypes {
  PG_ML = 'pg/mL',
  NG_ML = 'ng/mL',
  MIU_ML = 'mIU/mL'
}
interface ReportLabResults {
  testId: string;
  testName: TestNameTypes;
  value: string;
  measurementType: string;
}

export interface LabReport {
  patientId: string;
  patientName: string;
  cycleId: string;
  date: Date;
  results: ReportLabResults[];
  comment?: string;
}

export enum LabOrderBillTo {
  PATIENT = 'patient',
  CLINIC = 'clinic'
}

export const billToEntries = Object.entries(LabOrderBillTo);

export interface Panel {
  id: string;
  vendorId?: number;
  parentId?: string;
  code?: string;
  name?: string;
  type?: string;
  codingScheme?: string;
  orderGroupingId?: string;
  comments?: string;
}

export interface ClinicRoom {
  id: string;
  name: string;
  roomEmail?: string;
}

export const requiredAppointmentOrderKeys = [
  'billTo',
  'isUrgent',
  'performingLabEmail'
];

export enum BusyTimeType {
  STAFF = 'staff',
  ROOM = 'room'
}
interface BusyTimeBasics {
  type: BusyTimeType.STAFF | BusyTimeType.ROOM;
  startTime: Dayjs;
  endTime: Dayjs;
  status?: string;
}

interface RoomBusyTime extends BusyTimeBasics {
  type: BusyTimeType.ROOM;
  roomId: string;
  roomName: string;
}

interface StaffBusyTime extends BusyTimeBasics {
  type: BusyTimeType.STAFF;
  staffId: string;
  staffName: string;
}

export type BusyTime = StaffBusyTime | RoomBusyTime;

export interface RequestRange {
  minDate: Dayjs;
  maxDate: Dayjs;
}

export enum AddEditAppointmentTabs {
  SCHEDULE_OR_ORDERS = 'SCHEDULE_OR_ORDERS',
  VITALS = 'VITALS',
  ENCOUNTER_NOTE = 'ENCOUNTER_NOTE',
  CODING = 'CODING'
}

interface OrderItemOptions {
  id?: string;
  loincCode?: string;
  name?: string;
}

export type ProcedureItem = OrderItemOptions;
export type LabItem = OrderItemOptions;

export enum ServiceType {
  LAB_ORDER = 'lab',
  PROCEDURE_ORDER = 'procedure',
  VISIT = 'visit',
  BUNDLE = 'bundle'
}

export interface AppointmentOrder {
  serviceType: ServiceType;
  serviceDescription: string;
  code: string;
  codeType?: EncounterServiceCodeType;
  modifier?: BillingServiceCode;
  bundle?: OrderBundle;
}

export interface AppointmentCodingTemplate {
  templateName: string;
  defaultDuration: number;
  urgency: Urgency;
  fasting: YesOrNo;
  isVirtual: boolean;
  room?: string;
  orders: AppointmentOrder[];
  orderIcd10Codes?: string[];
}
