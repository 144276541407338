import React, { FC } from 'react';
import { styled } from '@mui/system';

import Box, { BoxProps } from 'src/components/layout/Box';

export type FlexProps = Omit<BoxProps, 'display'>;

const StyledBox = styled(Box)`
  display: flex;
`;

const Flex: FC<FlexProps> = ({ children, ...otherProps }) => {
  return <StyledBox {...otherProps}>{children}</StyledBox>;
};

export default Flex;
