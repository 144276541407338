import { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, DefaultValues, useForm } from 'react-hook-form';
import dayjs from 'dayjs';

import Flex from 'src/components/layout/Flex/Flex';
import Box from 'src/components/layout/Box/Box';
import Button from 'src/components/display/Button/Button';
import { spacings } from 'src/components/styles/constants';
import InputField from 'src/components/data-entry/InputField/InputField';
import DatePicker from 'src/components/data-entry/DatePicker/DatePicker';
import Select from 'src/components/data-entry/Select/Select';
import TextArea from 'src/components/data-entry/TextArea/TextArea';
import TimePicker from 'src/components/data-entry/TimePicker/TimePicker';
import InputLabel from 'src/components/data-entry/InputLabel/InputLabel';
import Checkbox from 'src/components/data-entry/Checkbox/Checkbox';
import { DurationPicker } from 'src/components/data-entry/DurationPicker/DurationPicker';
import { getTimeFormat } from 'src/utils/dateAndTIme';
import useAppointments from 'src/hooks/useAppointments';
import { PatientPersonalInfo } from 'src/types/patient';
import {
  Appointment,
  AppointmentPurposes,
  AppointmentTypes,
  NO_ROOM_OPTION
} from 'src/types/appointment';
import { OrderFormProps } from 'src/modules/patients/common/OrderForm';
import { DoctorChips } from '../../patients/common/DoctorChips';
import PatientPartnerSelect from './PatientPartnerSelect';
import {
  appointmentPurposeOptions,
  defaultRoomOptions,
  getAppointmentTypeOptions
} from './utils/options';
import PatientsSearchBar from 'src/layouts/CollapsedSidebarLayout/Header/PatientsSearchBar';
import { SearchFieldVariants } from 'src/components/data-entry/SearchField/SearchField';

interface RoomOptionType {
  label: string;
  value: string;
}

export const ScheduleAppointment: FC<{
  patientId: string;
  isDisabled: boolean;
  patientInfo?: PatientPersonalInfo;
  defaultValues: DefaultValues<Appointment>;
  onSubmit: (appointmentDetails: Appointment) => Promise<void>;
  onFormUpdate: (data: Appointment, isDirty: boolean) => void;
  OrderFormComponent: FC<OrderFormProps>;
  orderFormProps: OrderFormProps;
}> = ({
  patientId,
  isDisabled,
  patientInfo,
  defaultValues,
  onSubmit,
  onFormUpdate,
  OrderFormComponent,
  orderFormProps
}) => {
  const { t } = useTranslation();
  const { getClinicRooms } = useAppointments();

  const {
    data: clinicRooms,
    isLoading: isLoadingClinicRooms,
    isFetching: isFetchingClinicRooms
  } = getClinicRooms();

  const {
    control,
    formState,
    handleSubmit,
    watch,
    setValue,
    trigger,
    getValues,
    clearErrors,
    reset
  } = useForm<Appointment>({
    mode: 'onChange',
    defaultValues
  });

  const { errors, isDirty } = formState;
  const appointmentDetails = watch();
  const { isVirtual, panelIds, appointmentPurpose, startTime, endTime } =
    appointmentDetails;
  const isDocumentRelatedInputsDisabled = !panelIds?.length;

  const roomOptions: RoomOptionType[] = useMemo(() => {
    if (isLoadingClinicRooms) return [];

    const options: RoomOptionType[] = [];
    options.push({ label: t(NO_ROOM_OPTION), value: NO_ROOM_OPTION });

    if (clinicRooms.length === 0) {
      options.push(...defaultRoomOptions);
    } else {
      options.push(
        ...clinicRooms?.map((room) => ({
          label: t(room?.name),
          value: room?.id
        }))
      );
    }

    return options;
  }, [clinicRooms]);

  const appointmentOptions = useMemo(
    () => getAppointmentTypeOptions(appointmentPurpose),
    [appointmentPurpose]
  );

  const handleUpdateFormField = useCallback(
    <T extends keyof Appointment>(fieldName: T, value: Appointment[T]) => {
      if (onFormUpdate) {
        onFormUpdate(
          {
            ...appointmentDetails,
            [fieldName]: value
          },
          isDirty
        );
      }

      setValue(fieldName, value);
    },
    [appointmentDetails, isDirty, setValue]
  );

  useEffect(() => {
    if (patientInfo) {
      reset(defaultValues);
      handleUpdateFormField('patientId', patientInfo.id);
    }
  }, [reset, patientInfo]);

  useEffect(() => {
    if (startTime && endTime) {
      trigger('startTime');
      trigger('endTime');
    }
  }, [startTime, endTime]);

  useEffect(() => {
    if (isDocumentRelatedInputsDisabled) {
      clearErrors('labOrderInfo.performingLabEmail');
    }
  }, [isDocumentRelatedInputsDisabled]);

  return (
    <form noValidate>
      <Flex flexDirection="column" gap={spacings.medium}>
        <Flex gap={spacings.large} flexDirection="column">
          <Flex flex={1} gap={spacings.x2large} justifyContent="space-between">
            <Box flex={1} id="edit-appointment-patient-select-container">
              <Controller
                name="patient"
                control={control}
                rules={{
                  required: t('PATIENT_REQUIRED')
                }}
                render={() => {
                  if (patientId) {
                    return (
                      <PatientPartnerSelect
                        patientId={patientId}
                        value={getValues('patientId')}
                        errors={errors}
                        onChange={(patient) => {
                          handleUpdateFormField('patientId', patient.id);
                          handleUpdateFormField('patient', patient);
                        }}
                      />
                    );
                  } else {
                    return (
                      <PatientsSearchBar
                        placeholder={t('SEARCH_PATIENT_PLACEHOLDER')}
                        variant={SearchFieldVariants.DEFAULT}
                        onSelectPatient={(patient) => {
                          handleUpdateFormField('patientId', patient.id);
                          handleUpdateFormField('patient', patient);
                        }}
                        onSearchTermClear={() => {
                          handleUpdateFormField('patientId', '');
                          handleUpdateFormField('patient', null);
                        }}
                        errors={errors}
                        label={t('PATIENT_LABEL')}
                      />
                    );
                  }
                }}
              />
            </Box>
            <Flex
              flex={1}
              alignItems="center"
              justifyContent="space-between"
              gap={spacings.large}
            >
              <Box
                height="100%"
                flex={0.5}
                id="edit-appointment-date-container"
              >
                <Controller
                  name="date"
                  control={control}
                  rules={{
                    required: t('DATE_OF_APPOINTMENT_REQUIRED'),
                    validate: (value) =>
                      dayjs(value).isBefore(dayjs(), 'day')
                        ? t('DATE_MUST_NOT_BE_IN_PAST')
                        : true
                  }}
                  render={({ field: { ref, onChange, ...field } }) => (
                    <DatePicker
                      {...field}
                      inputRef={ref}
                      label={t('DATE_OF_APPOINTMENT')}
                      error={!!errors.date}
                      helperText={errors.date?.message}
                      fullWidth
                      onChange={(date) => {
                        onChange(date);
                        handleUpdateFormField('date', date);
                      }}
                    />
                  )}
                />
              </Box>
              <Flex
                height="100%"
                flex={1}
                alignItems="center"
                gap={spacings.large}
              >
                <Box height="100%" id="edit-appointment-start-time-container">
                  <Controller
                    name="startTime"
                    control={control}
                    rules={{
                      required: t('TIME_REQUIRED')
                    }}
                    render={({ field: { onChange, ref, ...field } }) => (
                      <TimePicker
                        {...field}
                        format={getTimeFormat({ isShort: true })}
                        label={t('START_TIME').toUpperCase()}
                        inputRef={ref}
                        error={!!errors.startTime}
                        helperText={errors.startTime?.message}
                        onChange={(time) => {
                          onChange(time);
                          handleUpdateFormField('startTime', time);
                        }}
                      />
                    )}
                  />
                </Box>
                <Box height="100%" id="edit-appointment-end-time-container">
                  <Controller
                    name="duration"
                    control={control}
                    rules={{
                      required: t('TIME_REQUIRED')
                    }}
                    render={({ field: { value } }) => {
                      return (
                        <DurationPicker
                          name="duration"
                          value={value}
                          onChange={(duration) => {
                            handleUpdateFormField('duration', duration);
                          }}
                          error={!!errors.duration}
                          helperText={errors.duration?.message}
                          label={t('DURATION').toUpperCase()}
                        />
                      );
                    }}
                  />
                </Box>
              </Flex>
            </Flex>
          </Flex>
          <Flex flex={1} gap={spacings.x2large} justifyContent="space-between">
            <Box flex={1}>
              <InputLabel label={t('APPOINTMENT_PURPOSE_LABEL')} />
              <Controller
                name="appointmentPurpose"
                control={control}
                rules={{
                  required: t('APPOINTMENT_PURPOSE_REQUIRED')
                }}
                render={({ field: { ref, onChange, ...field } }) => (
                  <Select
                    {...field}
                    inputRef={ref}
                    id="add-appointment-purpose-select"
                    error={!!errors.appointmentType}
                    helperText={errors.appointmentType?.message}
                    defaultOption={t('APPOINTMENT_PURPOSE_DEFAULT_OPTION')}
                    options={appointmentPurposeOptions}
                    onChange={(e) => {
                      const value = e.target.value as AppointmentPurposes;
                      const appointmentType =
                        getAppointmentTypeOptions(value)?.[0].value;

                      onChange(value);
                      handleUpdateFormField('appointmentType', appointmentType);
                      handleUpdateFormField('appointmentPurpose', value);
                    }}
                  />
                )}
              />
            </Box>
            <Box flex={1}>
              <Controller
                name="location"
                control={control}
                render={({ field: { onChange, ref, ...field } }) => {
                  return (
                    <Flex flexDirection="column">
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        height="100%"
                      >
                        <InputLabel label={t('LOCATION_LABEL')} />
                        <Box>
                          <Controller
                            name="isVirtual"
                            control={control}
                            render={({
                              field: { ref: _ref, onChange, value, ...field }
                            }) => (
                              <Checkbox
                                label={t('VIRTUAL')}
                                checked={value}
                                {...field}
                                onChange={(_, isChecked) => {
                                  const location = isChecked
                                    ? t('VIRTUAL')
                                    : t('CLINIC');
                                  onChange(isChecked);
                                  handleUpdateFormField('location', location);
                                  handleUpdateFormField('isVirtual', isChecked);
                                }}
                              />
                            )}
                          />
                        </Box>
                      </Flex>
                      <Box>
                        <InputField
                          {...field}
                          disabled={isVirtual}
                          inputRef={ref}
                          error={!!errors.location}
                          helperText={errors.location?.message}
                          onChange={(e) => {
                            onChange(e.target.value);
                            handleUpdateFormField('location', e.target.value);
                          }}
                        />
                      </Box>
                    </Flex>
                  );
                }}
              />
            </Box>
          </Flex>
          <Flex flex={1} gap={spacings.x2large} justifyContent="space-between">
            <Box flex={1}>
              <Controller
                name="appointmentType"
                control={control}
                rules={{
                  required: t('APPOINTMENT_TYPE_REQUIRED')
                }}
                render={({ field: { onChange, ref, ...field } }) => (
                  <Select
                    {...field}
                    id="add-appointment-type-select"
                    inputRef={ref}
                    label={t('APPOINTMENT_TYPE_LABEL')}
                    error={!!errors.appointmentType}
                    helperText={errors.appointmentType?.message}
                    defaultOption={t('APPOINTMENT_TYPE_DEFAULT_OPTION')}
                    options={appointmentOptions}
                    onChange={(e) => {
                      onChange(e.target.value);
                      handleUpdateFormField(
                        'appointmentType',
                        e.target.value as AppointmentTypes
                      );
                    }}
                  />
                )}
              />
            </Box>
            <Box flex={1}>
              <Controller
                name="room"
                control={control}
                rules={{
                  required: t('APPOINTMENT_ROOM_REQUIRED')
                }}
                render={({ field: { onChange, ref, ...field } }) => (
                  <Select
                    {...field}
                    id="add-appointment-room-select"
                    disabled={isVirtual}
                    inputRef={ref}
                    label={t('APPOINTMENT_ROOM_LABEL')}
                    error={!!errors.room}
                    helperText={errors.room?.message}
                    defaultOption={t('APPOINTMENT_ROOM_DEFAULT_OPTION')}
                    options={roomOptions}
                    isLoading={isLoadingClinicRooms || isFetchingClinicRooms}
                    onChange={(e) => {
                      onChange(e.target.value);
                      handleUpdateFormField('room', e.target.value);
                    }}
                  />
                )}
              />
            </Box>
          </Flex>
          <Flex flex={1} gap={spacings.x2large} justifyContent="space-between">
            <Box flex={1}>
              <Controller
                name="staffIds"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DoctorChips
                    id="add-edit-appointment-doctor-chips"
                    showSelectedValue
                    label={t('CHOOSE_PHYSICIAN_LABEL')}
                    value={value}
                    onAddChip={(newSelectedDoctorId) => {
                      if (value.includes(newSelectedDoctorId)) {
                        const staffIds = value.filter(
                          (doctor) => doctor !== newSelectedDoctorId
                        );

                        onChange(staffIds);
                        handleUpdateFormField('staffIds', staffIds);
                      } else {
                        onChange([...value, newSelectedDoctorId]);
                        handleUpdateFormField('staffIds', [
                          ...value,
                          newSelectedDoctorId
                        ]);
                      }
                    }}
                  />
                )}
              />
            </Box>
            <Flex flexDirection="column" gap={spacings.large} flex={1}>
              <Box flex={1}>
                <Controller
                  name="patientNotes"
                  control={control}
                  render={({ field: { onChange, ref, ...field } }) => (
                    <TextArea
                      {...field}
                      ref={ref}
                      label={t('NOTES_FOR_PATIENT_LABEL')}
                      placeholder={t('NOTES_FOR_PATIENT_PLACEHOLDER')}
                      error={!!errors.patientNotes}
                      helperText={errors.patientNotes?.message}
                      minRows={3}
                      maxRows={3}
                      fullWidth
                      onChange={(e) => {
                        onChange(e.target.value);
                        handleUpdateFormField('patientNotes', e.target.value);
                      }}
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name="internalNotes"
                  control={control}
                  render={({ field: { ref, onChange, ...field } }) => (
                    <TextArea
                      {...field}
                      ref={ref}
                      label={t('INTERNAL_NOTES').toUpperCase()}
                      placeholder={t('INTERNAL_NOTES')}
                      error={!!errors.internalNotes}
                      helperText={errors.internalNotes?.message}
                      minRows={3}
                      maxRows={3}
                      fullWidth
                      onChange={(e) => {
                        onChange(e.target.value);
                        handleUpdateFormField('internalNotes', e.target.value);
                      }}
                    />
                  )}
                />
              </Box>
            </Flex>
          </Flex>
        </Flex>
        {OrderFormComponent && <OrderFormComponent {...orderFormProps} />}
      </Flex>
      <Flex
        gap={spacings.large}
        justifyContent="flex-end"
        marginTop={spacings.large}
        alignItems="center"
      >
        <Box marginTop={spacings.medium}>
          <Button
            type="submit"
            disabled={isDisabled}
            onClick={handleSubmit(onSubmit)}
          >
            {t('CONFIRM')}
          </Button>
        </Box>
      </Flex>
    </form>
  );
};
