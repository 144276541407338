import React, { FC, ReactNode } from 'react';
import {
  StandardTextFieldProps,
  TextField,
  css,
  InputBaseProps
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/system';

import { Colors } from 'src/components/styles/colors';
import { fonts } from 'src/components/styles/fonts';
import { iconSizes, shadows, spacings } from 'src/components/styles/constants';
import Loader from 'src/components/display/Loader';
import { makeShouldForwardProps } from 'src/components/utils';

export enum InputFieldVariant {
  OUTLINED = 'outlined',
  FILLED = 'filled',
  COMPACT = 'compact'
}

export interface InputFieldProps
  extends Omit<StandardTextFieldProps, 'color' | 'size' | 'variant'> {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  width?: string;
  variant?: InputFieldVariant;
  onEnterPress?: () => void;
  noBorder?: boolean;
  isLoading?: boolean;
  labelProps?: {
    rowGap?: string | number;
  };
}

const shouldForwardProp = makeShouldForwardProps([
  'variant',
  'onEnterPress',
  'noBorder'
]);
const StyledInputField = styled(
  ({ variant: _variant, ...props }: InputFieldProps) => (
    <TextField {...props} />
  ),
  { shouldForwardProp }
)<InputFieldProps>`
  width: ${({ width }) => width || '100%'};

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  ${({ variant, InputProps, noBorder }) => {
    switch (variant) {
      case 'filled':
        return css`
          height: 100%;
          .MuiInputBase-root {
            padding: ${spacings.medium};
            background-color: ${Colors.whiteSand};
            border-radius: 0;
            height: 100%;
            color: ${Colors.emperor};
          }
          .MuiInputBase-input {
            padding: 0;
            height: 100%;
          }
        `;
      case 'outlined':
      default:
        return css`
          /* override original styling */

          .MuiInputBase-root {
            /* general */
            font: ${fonts.input};
            ${!noBorder &&
            css`
              border: 1px solid ${Colors.gray};
            `}
            border-radius: 10px;
            padding: ${variant === InputFieldVariant.COMPACT
              ? spacings.small
              : spacings.medium};

            /* default */
            color: ${Colors.silverChalice};
            background-color: ${InputProps.readOnly
              ? Colors.vistaWhite
              : Colors.white};
          }

          .MuiInputBase-input {
            padding: unset;
            height: unset;
            color: ${Colors.emperor};

            ::placeholder {
              color: ${Colors.gray};
              opacity: 1;
              width: 100%;
            }
          }

          .MuiFormLabel-root {
            color: ${Colors.emperor};
            font: ${fonts.label};
            padding: ${spacings.none};
            margin-bottom: ${spacings.large};
            position: relative;
            transform-origin: unset;
            transform: unset;
            transition: unset;

            &.Mui-focused {
              color: ${Colors.gray};
            }

            /* error */

            &.Mui-error {
              color: ${Colors.redRibbon};
            }

            /* disabled */

            &.Mui-disabled {
              color: ${Colors.gray2};
            }
          }

          /* hover + active */

          &.focused > .MuiInputBase-root,
          .MuiInputBase-root.Mui-focused,
          &.hover > .MuiInputBase-root,
          &:hover > .MuiInputBase-root {
            border-color: ${Colors.gray};
            box-shadow: ${shadows.input};
            &.Mui-disabled {
              border-color: ${Colors.gray};
              box-shadow: none;
            }
          }

          /* error */

          .MuiInputBase-root.Mui-error {
            border-color: ${Colors.redRibbon};
          }

          /* disabled */

          .MuiInputBase-root.Mui-disabled {
            .MuiInputAdornment-root {
              color: ${Colors.gray};
            }
          }

          /* with icon */

          .MuiInputAdornment-root {
            color: ${Colors.gray};

            .MuiSvgIcon-root {
              width: 20px;
            }
          }

          /* with helper text */

          .MuiFormHelperText-root {
            margin: 3px ${spacings.none} ${spacings.none} ${spacings.none};
            color: ${Colors.gray};
            font: ${fonts.caption};

            &.Mui-error {
              color: ${Colors.redRibbon};
            }
          }

          /* Disable autofill color */

          input:-webkit-autofill,
          input:-webkit-autofill:hover {
            -webkit-box-shadow: 0 0 0 30px ${Colors.white} inset !important;
            box-shadow: 0 0 0 30px ${Colors.white} inset !important;
          }

          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px ${Colors.white} inset !important;
            box-shadow: 0 0 0 30px ${Colors.white} inset !important;
          }
        `;
    }
  }}
`;

const InputField: FC<InputFieldProps> = ({
  children,
  width,
  startIcon,
  endIcon,
  InputProps,
  onEnterPress,
  helperText,
  variant = InputFieldVariant.OUTLINED,
  isLoading = false,
  disabled,
  ...otherProps
}) => {
  const inputProps: InputBaseProps = {
    ...InputProps,

    startAdornment: isLoading ? (
      <Loader size={iconSizes.small} />
    ) : startIcon ? (
      <InputAdornment position="start">{startIcon}</InputAdornment>
    ) : null,
    endAdornment: endIcon ? (
      <InputAdornment position="end">{endIcon}</InputAdornment>
    ) : null
  };

  return (
    <StyledInputField
      disabled={isLoading || disabled}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        ...inputProps
      }}
      width={width}
      helperText={helperText}
      variant={variant}
      onEnterPress={onEnterPress}
      {...otherProps}
    >
      {children}
    </StyledInputField>
  );
};

export default InputField;
