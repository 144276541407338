import { FC, useEffect } from 'react';
import { BoxProps, css, styled } from '@mui/material';

import Flex from 'src/components/layout/Flex/Flex';
import {
  buttonsMenuWidth,
  shadows,
  spacings,
  zIndices
} from 'src/components/styles/constants';
import { Colors } from 'src/components/styles/colors';
import Box from 'src/components/layout/Box/Box';
import { useSidebar } from 'src/contexts/AppContexts/AppContexts';

const StyledBarContainer = styled(Box)`
  box-sizing: border-box;
  background-color: ${Colors.dawnPink};
  box-shadow: ${shadows.default};
  padding: ${spacings.small};
  height: 100dvh;
  ${({ theme }) => ({
    [theme.breakpoints.up('lg')]: css`
      position: 'fixed';
      width: ${buttonsMenuWidth}px;
      background: ${Colors.dawnPink};
    `,
    [theme.breakpoints.down('lg')]: css`
      background: ${Colors.transparent};
    `
  })}

  left: 0;
  top: 0;

  z-index: ${zIndices.high};

  .patient-menu-container {
    padding-top: 100px;
  }
`;

const ButtonsSideBar: FC<BoxProps> = ({ children, sx }) => {
  const { setIsSidebarOpen } = useSidebar();

  useEffect(() => {
    setIsSidebarOpen(true);

    return () => setIsSidebarOpen(false);
  }, []);

  return (
    <StyledBarContainer
      sx={{
        boxShadow: { xs: 'none', lg: shadows.default },
        ...sx
      }}
      width="auto"
    >
      <Box className="patient-menu-container">
        <Flex
          flexDirection="column"
          gap={spacings.small}
          justifyContent="center"
          alignItems="flex-end"
          height="100%"
        >
          {children}
        </Flex>
      </Box>
    </StyledBarContainer>
  );
};

export default ButtonsSideBar;
